import React, { Component } from "react";
import moment from 'moment';
import _ from 'lodash';
import * as yup from 'yup';

import { 
    Row,
    Col,
    Form,
    InputGroup,
    Button,
    Dropdown,
    ListGroup,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Carousel, { consts } from 'react-elastic-carousel';

import Utils from '../../utils/utils'
import { AccountSettingType, ActiveBinNumberRequestFilter } from '../../utils/enums'

import ViewLayout from "./layout/ViewLayout.jsx";

import RSuiteDatePicker from '../../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteCheckPicker from "../../components/OLD/Checkbox/RSuiteCheckPicker.jsx";
import CircleButton from "../../components/Buttons/CircleButton";
import DropdownFilter from '../../components/OLD/Dropdown/DropdownFilter.jsx';
import GoogleMapInline from '../../components/googleMap/GoogleMapInline';
import RemoveDialog from '../../components/dialog/RemoveDialog';
import Tooltip from '../../components/Tooltip/Tooltip';
import IconButtonSearch from '../../components/input/IconButtonSearch';

import { DrawerLayout } from '../../components/drawer/DrawerLayout'
import MapCollectBinForm from "./drawer/MapCollectBinForm";

import { ReactComponent as CalendarIcon } from "../../../_metronic/assets/img/icons/feather_calendar.svg";

import unsuccessfulPin from "../../../_metronic/assets/img/icons/unsuccessful-pin.svg";
import completedPin from "../../../_metronic/assets/img/icons/completed-pin.svg";
import acknowledgedPin from "../../../_metronic/assets/img/icons/acknowledged-pin.svg";
import unassignedPin from "../../../_metronic/assets/img/icons/unassigned-pin.svg";
import assignedPin from "../../../_metronic/assets/img/icons/assigned-pin.svg";
import startedPin from "../../../_metronic/assets/img/icons/started-pin.svg";
import inProgressPin from "../../../_metronic/assets/img/icons/in-progress-pin.svg";
import dispatchedPin from "../../../_metronic/assets/img/icons/dispatched-pin.svg";

import { ReactComponent as ActiveBinIcon } from "../../../_metronic/assets/img/icons/active-bin-pin.svg";
import { ReactComponent as UnsuccessfulIcon } from "../../../_metronic/assets/img/icons/unsuccessful-pin.svg";
import { ReactComponent as CompletedIcon } from "../../../_metronic/assets/img/icons/completed-pin.svg";
import { ReactComponent as AcknowledgedIcon } from "../../../_metronic/assets/img/icons/acknowledged-pin.svg";
import { ReactComponent as UnassignedIcon } from "../../../_metronic/assets/img/icons/unassigned-pin.svg";
import { ReactComponent as AssignedIcon } from "../../../_metronic/assets/img/icons/assigned-pin.svg";
import { ReactComponent as StartedIcon } from "../../../_metronic/assets/img/icons/started-pin.svg";
import { ReactComponent as InProgressIcon } from "../../../_metronic/assets/img/icons/in-progress-pin.svg";
import { ReactComponent as DispatchedIcon } from "../../../_metronic/assets/img/icons/dispatched-pin.svg";

import { readApi } from '../../api/CRUD/DispatchCRUD'

import { connect } from 'react-redux'
import { clear } from "../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPut } from '../../../setup/redux/dispatch/actions'
import mapSlice from './slice';


const formFieldsCollectBin = {
    jobTypeList: {
      id: 'jobTypeList',
      label: 'job Template List',
      placeholder: ' ',
      required: 'You must select a driver',
    },
}
const formSchemaCollectBin = yup.object().shape({
    jobTypeList: yup.array().of(
      yup.object().shape({
        isChecked: yup.bool().oneOf([true, false]),
      })
    ).min(1, formFieldsCollectBin.jobTypeList.required),
});


export const StyledActiveBinIcon = styled(ActiveBinIcon)`
  g {
    stroke: #ababab;
  }
`;


class Map extends Component {
    constructor(props) {
        super(props);
        
        this.page = 'map_page';

        this.refFormDrawerCollectBin = React.createRef();
        this.formDrawerCollectBin = null;

        this.center = { lat: 1.353915, lng: 103.822901 };
        this.zoom = 13;

		this.state = {
            isLoading: false,

            lat: this.center.lat,
            lng: this.center.lng,
            zoom: this.zoom,

            search: '',
            legendItems: [],
            legends: [],
            isLegendMultiSelect: false,
            isLoadingMapMarkers: false,

            centerMap: this.center,
            zoomMap: this.zoom,
            isLoadingMap: true,
            isLoadingMapInit: true,

            statuses: [],
            statusCountIsLoading: false,

            driverIsLoading: false,
            isSelectAllDriver: false,
            driverItems: [],
            driverIds: [],

            selectedDate: new Date(),

            showLayouts: false,
            showUnassigned: true,
            showAssigned: true,
            showDispatched: true,
            showAcknowledged: true,
            showStarted: true,
            showInProgress: true,
            showCompleted: true,
            showUnsuccessful: true,
            showCurrentActiveBin: false,
            includeCollected: false,

            jobMarkers: [],
            binMarkers: [],

            isCollectedDialog: false,
            collectedRow: null,

            isLoadingJobTypeDropdown: false,
            selectedJobTypeDropdown: [],
            arrJobTypeDropdown: [],

            isLoadingFormDrawerCollectBin: false,
            isVisibleFormDrawerCollectBin: false,
            isBlockingFormDrawerCollectBin: false,
            rowInitialValuesCollectBin: {
                jobTypeList: [],
            },
            rowValuesCollectBin: null,
		}
    }


    componentDidMount(){
        this.initDrawers();
        this.callInitApis();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
	}


    /* DRAWERS */
    initDrawers = () => {
        Utils.initDrawers(() => {
            this.formDrawerCollectBin = Utils.getInstanceDrawer('formCollectBin');
            Utils.onShowDrawer(this.formDrawerCollectBin, null, () => {
                this.setState({
                    isVisibleFormDrawerCollectBin: true,
                });
            });
            Utils.onHideDrawer(this.formDrawerCollectBin, this.refFormDrawerCollectBin, () => {
                this.setState({
                    isVisibleFormDrawerCollectBin: false,
                });
            });
        });
    }
    
    formDrawerCollectBinSection = () => {
        return <DrawerLayout
            name={'formCollectBin'}
            ref={this.refFormDrawerCollectBin}
            drawer={this.formDrawerCollectBin}
            validationSchema={formSchemaCollectBin}
            initialValues={this.state.rowInitialValuesCollectBin}
            values={this.state.rowValuesCollectBin}
            isVisible={this.state.isVisibleFormDrawerCollectBin}
            isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawerCollectBin, this.state.isBlockingFormDrawerCollectBin)}
            renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawerCollectBin, this.state.isBlockingFormDrawerCollectBin)}
        
            title={(data) => {
                if(data){
                    return <div className={'d-flex align-items-center'}>
                        <div className={'d-block'}>
                            <div className={'pe-2'}>
                                <span className={'pe-2'}>Set default job type for collect bin</span>
                            </div>
                        </div>
                    </div>;
                }
            }}
            toolbar={null}
            
            onOpenClose={(isOpen, dataParams) => {
                if(isOpen){
                    this.setState({
                        rowValuesCollectBin: this.state.rowinitialValues,
                    }, () => {
                        this.callReadJobTemplateApi((jobTypeList) => {
                            let rowValuesCollectBin = this.prepareFormCollectBin(dataParams, jobTypeList);
                
                            this.setState({
                                rowValuesCollectBin: rowValuesCollectBin,
                            });
                        });
                    });
                }
            }}
            onSubmit={(form, dataParams) => {
                
            }}
        >
            {(formOptions, dataParams) => {
                return <MapCollectBinForm
                    drawer={this.formDrawerCollectBin}
                    fields={formFieldsCollectBin}
                    initialValues={this.state.rowInitialValuesCollectBin}
                    values={this.state.rowValuesCollectBin}
                    formOptions={formOptions}
                    dataParams={dataParams}
                    onSave={(values) => {
                        let data = this.prepareDataCollectBin(values, dataParams);
                        
                        this.callPutJobTemplateApi(data, () => {
                            Utils.showDrawer(this.formDrawerCollectBin, false);
                        });
                    }}
                />
            }}
        </DrawerLayout>
    }
    prepareFormCollectBin = (dataParams, jobTypeList) => {
        if(jobTypeList){
            let rowValues = {
                jobTypeList: (jobTypeList && jobTypeList.length > 0) ? jobTypeList : [],
            };
        
            return rowValues;
        } else {
            return this.state.rowinitialValues;
        }
    }
    prepareDataCollectBin = (form, dataParams) => {
        let jobTemplateIds = '';

        if(form && form.jobTypeList && form.jobTypeList.length > 0){
            jobTemplateIds = form.jobTypeList.filter(x => x.isChecked).map(y => y.value).join(',');
        }

        let data = {
            type: AccountSettingType.DefaultMapJobType,
            value: jobTemplateIds,
        };
    
        return data;
    }
    /* END DRAWERS */


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.page + '-active_bin_map_position_stats', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                        
                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];

                        if(item.filter.toString() == ActiveBinNumberRequestFilter.Seven.toString()){
                            arr.push({
                                index: 7,
                                value: ActiveBinNumberRequestFilter.Seven.toString(),
                                title: item.count,
                                info: '0-7 days',
                                color: 'green',
                            });

                        } else if(item.filter.toString() == ActiveBinNumberRequestFilter.Fourteen.toString()){
                            arr.push({
                                index: 14,
                                value: ActiveBinNumberRequestFilter.Fourteen.toString(),
                                title: item.count,
                                info: '8-20 days',
                                color: 'yellow',
                            });

                        } else if(item.filter.toString() == ActiveBinNumberRequestFilter.TwentyOne.toString()){
                            arr.push({
                                index: 21,
                                value: ActiveBinNumberRequestFilter.TwentyOne.toString(),
                                title: item.count,
                                info: '21-30 days',
                                color: 'orange',
                            });

                        } else if(item.filter.toString() == ActiveBinNumberRequestFilter.ThirtyPlus.toString()){
                            arr.push({
                                index: 30,
                                value: ActiveBinNumberRequestFilter.ThirtyPlus.toString(),
                                title: item.count,
                                info: '31-60 days',
                                color: 'red',
                            });
                        } else if(item.filter.toString() == ActiveBinNumberRequestFilter.SixtyPlus.toString()){
                            arr.push({
                                index: 60,
                                value: ActiveBinNumberRequestFilter.SixtyPlus.toString(),
                                title: item.count,
                                info: '60+ days',
                                color: 'darkred',
                            });
                        }
                    }
                }
        

                this.setState({
                    legendItems: arr,
                    statusCountIsLoading: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.page + '-driver_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.driverId;
                        let title = ((item.vehicleName && item.vehicleName != '') ? item.vehicleName : '- ') + ' / ' + ((item.driverName && item.driverName != '') ? item.driverName : '- ');
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.page + '-active_job_map_position', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                let counter = 0;

                if(data && data.length > 0) {
                    let uniquecustomerSites = _.uniqBy(data, x => x.customerSiteId);
                    if(uniquecustomerSites && uniquecustomerSites.length > 0){
                        for (var j = 0; j < uniquecustomerSites.length; j++) {
                            let jobCustomerSitesItems = data.filter(x => x.customerSiteId == uniquecustomerSites[j].customerSiteId);
                            
                            let uniqueStatuses = _.uniqBy(jobCustomerSitesItems, x => x.jobStatusId);
                            if(uniqueStatuses && uniqueStatuses.length > 0){
                                for (var i = 0; i < uniqueStatuses.length; i++) {
                                    let jobItems = jobCustomerSitesItems.filter(x => x.jobStatusId == uniqueStatuses[i].jobStatusId);
                                    
                                    let selectedJob = 0;
                                    let item = jobItems[selectedJob];
                                    if(item && item.latitude && item.longitude){
                                        let coordinate = {
                                            lat: item.latitude,
                                            lng: item.longitude,
                                        };

                                        let value = 'cs_' + item.customerSiteId + '_s_' + item.jobStatusId;
                                        let title = item.customerSiteName;

                                        let arrItem = {
                                            type: 1,
                                            value: value,
                                            title: title,
                                            item: item,
                                            label: (jobItems.length > 1) ? '' + jobItems.length : null,
                                            items: jobItems,
                                            position: coordinate,
                                            icon: this.getIconByStatus(item, i),
                                            isOpen: false,
                                            selectedJob: selectedJob,
                                            infoWindow: this.infoWindowJob,
                                        };

                                        arr = Utils.addToArray(arr, value, arrItem);
                                    } else {
                                        counter = counter + 1;
                                    }
                                }
                            }
                        }
                    }
                }

                if(counter > 0){
                    Utils.toast('There is ' + counter + ' site coordinates are missing', 'warning', null, 4000);
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.page + '-bin_number_active_bin_map_position', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let globalItem = data[i];
                        
                        if(globalItem && globalItem.latitude && globalItem.longitude){
                            let coordinate = {
                                lat: globalItem.latitude,
                                lng: globalItem.longitude,
                            };
    
                            let value = globalItem.customerSiteId;
                            let title = globalItem.customerSiteName;
    
                            let arrItem = {
                                type: 2,
                                value: value,
                                title: title,
                                item: globalItem,
                                label: (globalItem.binCount > 1) ? '' + globalItem.binCount : '',
                                items: (globalItem.binHistories && globalItem.binHistories.length > 0) ? globalItem.binHistories : [],
                                position: coordinate,
                                icon: null,
                                color: this.getColorByLegend(globalItem),
                                isOpen: false,
                                selectedJob: 0,
                                infoWindow: this.infoWindowBin,
                            };

                            arr = Utils.addToArray(arr, value, arrItem);
                        }
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.page + '-bin_number_active_bin_map_position_details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let selectedItem = null;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let globalItem = data[i];
                        
                        if(globalItem && globalItem.latitude && globalItem.longitude){
                            let coordinate = {
                                lat: globalItem.latitude,
                                lng: globalItem.longitude,
                            };
    
                            let value = globalItem.customerSiteId;
                            let title = globalItem.customerSiteName;
    
                            selectedItem = {
                                type: 2,
                                value: value,
                                title: title,
                                item: globalItem,
                                label: (globalItem.binCount > 1) ? '' + globalItem.binCount : '',
                                items: (globalItem.binHistories && globalItem.binHistories.length > 0) ? globalItem.binHistories : [],
                                position: coordinate,
                                icon: null,
                                color: this.getColorByLegend(globalItem),
                                isOpen: true,
                                selectedJob: 0,
                                infoWindow: this.infoWindowBin,
                            };
                        }
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(selectedItem);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.page + '-bin_number_mark_bin_as_collected', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback();
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.page + '-drawer_job_template', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = []; //variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.jobTemplateId;
                        let title = item.jobTemplateName;
                        
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                            isChecked: (this.state.selectedJobTypeDropdown && this.state.selectedJobTypeDropdown.length > 0) ? (this.state.selectedJobTypeDropdown.findIndex(x => parseInt(x) == value) != -1) : false,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoadingFormDrawerCollectBin: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.page + '-drawer_account_setting_type', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let selectedJobTypeDropdown = [];

                if(data && data.value && data.value != '') {
                    selectedJobTypeDropdown = data.value.split(',');
                }

                this.setState({
                    isLoadingJobTypeDropdown: false,
                    selectedJobTypeDropdown: selectedJobTypeDropdown,
                    arrJobTypeDropdown: [],

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(selectedJobTypeDropdown);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.page + '-drawer_account_setting_type_put', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback();
                    }
                });
            }
        );
    }

    callReadStatusCountApi = (selectedDate = null, statusIds = '', callback = null) => {
        this.setState({
            statusCountIsLoading: true,
        });
        
        let data = {
            searchQuery: this.state.search,
            // fromDate: selectedDate,
            // toDate: selectedDate,
            // jobStatusFilter: statusIds,
        };
     
        this.props.dispatchApiCallGet(data, this.page + '-active_bin_map_position_stats', 'bin-center/active-bin-on-site-stats', null, callback, () => {});
    }

    callReadDriversApi = (items = [], selectedDate = null, statusIds = '', callback = null) => {
        this.setState({
            driverIsLoading: true,
        });

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: this.state.search,
            fromDate: selectedDate,
            toDate: selectedDate,
            jobStatusFilter: statusIds,
        };
     
        this.props.dispatchApiCallGet(data, this.page + '-driver_list', 'Job/group-by-driver', { items: items }, callback, () => {});
    }

    callActiveJobMapPositionApi = (driverIds = '', selectedDate = null, statusIds = '', callback = null) => {
        let data = {
            driverIds: driverIds,
            fromDate: selectedDate,
            toDate: selectedDate,
            jobStatusFilter: statusIds,
            searchQuery: this.state.search,
        };
     
        this.props.dispatchApiCallGet(data, this.page + '-active_job_map_position', 'job/active-job-map-position', null, callback, () => {});
    }

    callActiveBinMapPositionApi = (driverIds = '', selectedDate = null, callback = null) => {
        let data = {
            includeCollected: this.state.includeCollected,
            searchQuery: this.state.search,
        };

        let args = this.getLegendArgs();
        if(args){
            data['filter'] = args;
        }

        this.props.dispatchApiCallGet(data, this.page + '-bin_number_active_bin_map_position', 'binnumber/active-bin-map-position', null, callback, () => {});
    }

    callActiveBinMapPositionDetailsApi = (customerSiteId = null, callback = null) => {
        this.setState({
            isLoading: true,
        });

        let data = {
            includeCollected: this.state.includeCollected,
            includeDetails: true,
            customerSiteId: customerSiteId,
            searchQuery: this.state.search,
        };

        let args = this.getLegendArgs();
        if(args){
            data['filter'] = args;
        }

        this.props.dispatchApiCallGet(data, this.page + '-bin_number_active_bin_map_position_details', 'binnumber/active-bin-map-position', null, callback, () => {});
    }
    
    callMarkBinAsCollectedApi = (customerSiteBinHistoryId = null, callback = null) => {
        let data = {
            customerSiteBinHistoryId: customerSiteBinHistoryId,
        };

        this.props.dispatchApiCallPut(data, this.page + '-bin_number_mark_bin_as_collected', 'binnumber/mark-bin-as-collected', null, callback, () => {});
    }

    callReadJobTemplateApi = (callback = null) => {
        this.setState({
            isLoadingFormDrawerCollectBin: true,
        });

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            binInOnly: true,
        };

        this.props.dispatchApiCallGet(data, this.page + '-drawer_job_template', 'JobTemplate', null, callback, () => {});
    }
    
    callReadJobTemplateDropdownApi = (selectedJobTemplate = [], callback = null) => {
        if(selectedJobTemplate && selectedJobTemplate.length > 0){
            this.setState({
                isLoadingFormDrawerCollectBin: true,
            });
    
            let data = {
                currentPage: 1,
                pageSize: Utils.getMaxPageSize(),
                searchQuery: '',
                binInOnly: true,
            };
    
            data['jobTemplateIds'] = selectedJobTemplate.join(',');
            this.props.dispatchApiCallGet(data, this.page + '-drawer_job_template', 'JobTemplate', null, callback, () => {});
        } else {
            if(callback){
                callback();
            }
        }
    }

    callReadAccountSettingsTypeApi = (callback = null) => {
        this.setState({
            isLoadingJobTypeDropdown: true,
            arrJobTypeDropdown: [],
            selectedJobTypeDropdown: [],
        });

        this.props.dispatchApiCallGet(null, this.page + '-drawer_account_setting_type', 'AccountSetting/type/' + AccountSettingType.DefaultMapJobType, null, callback, () => {});
    }
    
    callPutJobTemplateApi = (data, callback = null) => {
        this.setState({
            isLoading: true,
        });

        this.props.dispatchApiCallPut(data, this.page + '-drawer_account_setting_type_put', 'AccountSetting', null, callback, () => {});
    }

    callSearchApis = () => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'job/active-job-map-position',
                params: () => {
                    let driverIds = (this.state.driverIds && this.state.driverIds.length > 0) ? this.state.driverIds.join(',') : '';
                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                    let statusIds = this.getSelectedStatus();
            
                    let data = {
                        driverIds: driverIds,
                        fromDate: selectedDate,
                        toDate: selectedDate,
                        jobStatusFilter: statusIds,
                        searchQuery: this.state.search,
                    };
                    
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        let counter = 0;
        
                        if(data && data.length > 0) {
                            let uniquecustomerSites = _.uniqBy(data, x => x.customerSiteId);
                            if(uniquecustomerSites && uniquecustomerSites.length > 0){
                                for (var j = 0; j < uniquecustomerSites.length; j++) {
                                    let jobCustomerSitesItems = data.filter(x => x.customerSiteId == uniquecustomerSites[j].customerSiteId);
                                    
                                    let uniqueStatuses = _.uniqBy(jobCustomerSitesItems, x => x.jobStatusId);
                                    if(uniqueStatuses && uniqueStatuses.length > 0){
                                        for (var i = 0; i < uniqueStatuses.length; i++) {
                                            let jobItems = jobCustomerSitesItems.filter(x => x.jobStatusId == uniqueStatuses[i].jobStatusId);
                                            
                                            let selectedJob = 0;
                                            let item = jobItems[selectedJob];
                                            if(item && item.latitude && item.longitude){
                                                let coordinate = {
                                                    lat: item.latitude,
                                                    lng: item.longitude,
                                                };
        
                                                let value = 'cs_' + item.customerSiteId + '_s_' + item.jobStatusId;
                                                let title = item.customerSiteName;
        
                                                let arrItem = {
                                                    type: 1,
                                                    value: value,
                                                    title: title,
                                                    item: item,
                                                    label: (jobItems.length > 1) ? '' + jobItems.length : null,
                                                    items: jobItems,
                                                    position: coordinate,
                                                    icon: this.getIconByStatus(item, i),
                                                    isOpen: false,
                                                    selectedJob: selectedJob,
                                                    infoWindow: this.infoWindowJob,
                                                };
        
                                                arr = Utils.addToArray(arr, value, arrItem);
                                            } else {
                                                counter = counter + 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
        
                        if(counter > 0){
                            Utils.toast('There is ' + counter + ' site coordinates are missing', 'warning', null, 4000);
                        }
        
                        this.setState({
                            jobMarkers: arr,
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'binnumber/active-bin-map-position',
                params: () => {
                    let data = {
                        includeCollected: this.state.includeCollected,
                        searchQuery: this.state.search,
                    };
            
                    let args = this.getLegendArgs();
                    if(args){
                        data['filter'] = args;
                    }
                    
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];

                        if(data && data.length > 0) {
                            for (var i = 0; i < data.length; i++) {
                                let globalItem = data[i];
                                
                                if(globalItem && globalItem.latitude && globalItem.longitude){
                                    let coordinate = {
                                        lat: globalItem.latitude,
                                        lng: globalItem.longitude,
                                    };
            
                                    let value = globalItem.customerSiteId;
                                    let title = globalItem.customerSiteName;
            
                                    let arrItem = {
                                        type: 2,
                                        value: value,
                                        title: title,
                                        item: globalItem,
                                        label: (globalItem.binCount > 1) ? '' + globalItem.binCount : '',
                                        items: (globalItem.binHistories && globalItem.binHistories.length > 0) ? globalItem.binHistories : [],
                                        position: coordinate,
                                        icon: null,
                                        color: this.getColorByLegend(globalItem),
                                        isOpen: false,
                                        selectedJob: 0,
                                        infoWindow: this.infoWindowBin,
                                    };

                                    arr = Utils.addToArray(arr, value, arrItem);
                                }
                            }
                        }
        
                        this.setState({
                            binMarkers: arr,
                        });
                    });
                }
            }
        ], (result) => {
            this.setState({
                isLoading: true,
                // isLoadingMapMarkers: true,
                isLoadingMap: true,
                jobMarkers: [],
                binMarkers: [],
            });
        }, (results) => {
            this.setState({
                isLoading: false,
                // isLoadingMapMarkers: false,
                isLoadingMap: false,
            });
        });
    }

    callInitApis = () => {
        let settings = Utils.getAccountSettings();
        
        let latObj = (settings && settings.length > 0) ? settings.find(x => x.type === AccountSettingType.DefaultMapPositionLatitude) : null;
        let lngObj = (settings && settings.length > 0) ? settings.find(x => x.type === AccountSettingType.DefaultMapPositionLongitude) : null;
        let zoomObj = (settings && settings.length > 0) ? settings.find(x => x.type === AccountSettingType.DefaultMapPositionZoom) : null;
        
        let lat = (latObj && latObj.value && latObj.value != '') ? latObj.value : null;
        let lng = (lngObj && lngObj.value && lngObj.value != '') ? lngObj.value : null;
        let zoom = (zoomObj && zoomObj.value && zoomObj.value != '') ? zoomObj.value : this.zoom;

        if(lat === null && lng === null){
            this.setState({
                lat: 0.12463108622583872,
                lng: 10.621379999999983,
                zoom: 2,
            });
        } else {
            this.setState({
                lat: lat,
                lng: lng,
                zoom: zoom,
            });
        }


        Utils.callAllApi([
            // {
            //     api: readApi,
            //     path: 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLatitude,
            //     params: null,
            //     callback: (result) => {
            //         Utils.parseResult(result, (data) => {
            //             let val = (data && data.value && data.value != '') ? data.value : 1.353915;
        
            //             this.setState({
            //                 lat: val,
            //             });
            //         });
            //     }
            // },
            // {
            //     api: readApi,
            //     path: 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLongitude,
            //     params: null,
            //     callback: (result) => {
            //         Utils.parseResult(result, (data) => {
            //             let val = (data && data.value && data.value != '') ? data.value : 103.822901;
        
            //             this.setState({
            //                 lng: val,
            //             });
            //         });
            //     }
            // },
            // {
            //     api: readApi,
            //     path: 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionZoom,
            //     params: null,
            //     callback: (result) => {
            //         Utils.parseResult(result, (data) => {
            //             let val = (data && data.value && data.value != '') ? data.value : '';
        
            //             this.setState({
            //                 zoom: val,
            //             });
            //         });
            //     }
            // },

            {
                api: readApi,
                path: 'bin-center/active-bin-on-site-stats',
                params: () => {
                    let data = {
                        searchQuery: this.state.search,
                    };
                    
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        
                        if(data && data.length > 0) {
                            for (var i = 0; i < data.length; i++) {
                                let item = data[i];

                                if(item.filter.toString() == ActiveBinNumberRequestFilter.Seven.toString()){
                                    arr.push({
                                        index: 7,
                                        value: ActiveBinNumberRequestFilter.Seven.toString(),
                                        title: item.count,
                                        info: '0-7 days',
                                        color: 'green',
                                    });

                                } else if(item.filter.toString() == ActiveBinNumberRequestFilter.Fourteen.toString()){
                                    arr.push({
                                        index: 14,
                                        value: ActiveBinNumberRequestFilter.Fourteen.toString(),
                                        title: item.count,
                                        info: '8-20 days',
                                        color: 'yellow',
                                    });

                                } else if(item.filter.toString() == ActiveBinNumberRequestFilter.TwentyOne.toString()){
                                    arr.push({
                                        index: 21,
                                        value: ActiveBinNumberRequestFilter.TwentyOne.toString(),
                                        title: item.count,
                                        info: '21-30 days',
                                        color: 'orange',
                                    });

                                } else if(item.filter.toString() == ActiveBinNumberRequestFilter.ThirtyPlus.toString()){
                                    arr.push({
                                        index: 30,
                                        value: ActiveBinNumberRequestFilter.ThirtyPlus.toString(),
                                        title: item.count,
                                        info: '31-60 days',
                                        color: 'red',
                                    });
                                } else if(item.filter.toString() == ActiveBinNumberRequestFilter.SixtyPlus.toString()){
                                    arr.push({
                                        index: 60,
                                        value: ActiveBinNumberRequestFilter.SixtyPlus.toString(),
                                        title: item.count,
                                        info: '60+ days',
                                        color: 'darkred',
                                    });
                                }
                            }
                        }
        
                        this.setState({
                            legendItems: arr,
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'job/status/stat',
                params: () => {
                    let data = {
                        searchQuery: '',
                    };
                    
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        if(data && data.data && data.data.length > 0){
                            arr = data.data.map((item, i) => {
                                return {
                                    value: item.jobStatusId,
                                    title: item.jobStatusName,
                                    item: item,
                                }
                            });
                        }
        
                        this.setState({
                            statuses: arr,
                        });
                    });
                }
            },

            {
                api: readApi,
                path: 'job/group-by-driver',
                params: () => {
                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                    let statusIds = this.getSelectedStatus();

                    let data = {
                        currentPage: 1,
                        pageSize: Utils.getMaxPageSize(),
                        searchQuery: this.state.search,
                        fromDate: selectedDate,
                        toDate: selectedDate,
                        jobStatusFilter: statusIds,
                    };
                    
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = this.state.driverItems;

                        if(data && data.data && data.data.length > 0) {
                            for (var i = 0; i < data.data.length; i++) {
                                let item = data.data[i];
                                let value = item.driverId;
                                let title = ((item.vehicleName && item.vehicleName != '') ? item.vehicleName : '- ') + ' / ' + ((item.driverName && item.driverName != '') ? item.driverName : '- ');
                                let arrItem = {
                                    value: value,
                                    title: title,
                                    item: item,
                                };

                                arr = Utils.addToArray(arr, value, arrItem);
                            }
                        }
        
                        this.setState({
                            driverItems: arr,
                        }, () => {
                            this.selectAllDrivers();
                        });
                    });
                }
            },
            
        ], (result) => {
            this.setState({
                isLoading: true,
                isLoadingMap: true,
                isLoadingMapInit: true,
            });
        }, (results) => {
            let centerMap = (this.state.lat != '' && this.state.lng != '') ? { lat: parseFloat(this.state.lat), lng: parseFloat(this.state.lng) } : this.center;
            let zoomMap = (this.state.zoom != '' && this.state.zoom != '') ? parseInt(this.state.zoom) : this.zoom;
            
            this.setState({
                isLoading: false,
                isLoadingMap: false,
                isLoadingMapInit: false,
                
                centerMap: centerMap,
                zoomMap: zoomMap,
            }, () => {
                this.onChangeStatusAction();
            });
        });
    }
    /* END API */
    

    /* FUNCTION */
    onToggleHandler = (isOpen, e, metadata) => {
        if (!this.state.isLoadingMap && metadata.source != 'select') {
            this.setState({
                showLayouts: isOpen,
            });
        }
    }

    getIconByStatus = (item, i) => {
        if(item && item.statusName){
            let start = 10;
            let step = 5;

            let data = {
                url: this.getIcons(item, i),
            }

            if(window && window.google && window.google.maps){
                data['origin'] = new window.google.maps.Point(0, 0);
    
                if(i > 0){
                    data['anchor'] = new window.google.maps.Point((start - (step * (i - 1))), 44);
                }
            }

            return data;
        } else {
            return null;
        }
    }

    getIcons = (item, i) => {
        if(item && item.statusName){
            switch(item.statusName){
                case 'Unassigned':
                    return unassignedPin;

                case 'Assigned':
                    return assignedPin;

                case 'Dispatched':
                    return dispatchedPin;

                case 'Acknowledged':
                    return acknowledgedPin;

                case 'Started':
                    return startedPin;

                case 'In Progress':
                    return inProgressPin;

                case 'Completed':
                    return completedPin;

                case 'Failed':
                    return unsuccessfulPin;

                case 'Rejected':
                    return unsuccessfulPin;

                case 'Cancelled':
                    return unsuccessfulPin;

                default:
                    return null;
            }
        } else {
            return null;
        }
    }

    getSelectedStatus = (state = false) => {
        let selectedStatuses = [];

        if(this.state.showUnassigned){
            let statusUnassigned = Utils.findStatus(this.state.statuses, "title", "unassigned");
            if(statusUnassigned && statusUnassigned.value){
                selectedStatuses.push(statusUnassigned.value);
            }
        }

        if(state || (this.state.driverIds && this.state.driverIds.length > 0)){
            if(this.state.showAssigned){
                let statusAssigned = Utils.findStatus(this.state.statuses, "title", "assigned");
                if(statusAssigned && statusAssigned.value){
                    selectedStatuses.push(statusAssigned.value);
                }
            }

            if(this.state.showDispatched){
                let statusDispatched = Utils.findStatus(this.state.statuses, "title", "dispatched");
                if(statusDispatched && statusDispatched.value){
                    selectedStatuses.push(statusDispatched.value);
                }
            }

            if(this.state.showAcknowledged){
                let statusAcknowledged = Utils.findStatus(this.state.statuses, "title", "acknowledged");
                if(statusAcknowledged && statusAcknowledged.value){
                    selectedStatuses.push(statusAcknowledged.value);
                }
            }

            if(this.state.showStarted){
                let statusStarted = Utils.findStatus(this.state.statuses, "title", "Started");
                if(statusStarted && statusStarted.value){
                    selectedStatuses.push(statusStarted.value);
                }
            }

            if(this.state.showInProgress){
                let statusInProgress = Utils.findStatus(this.state.statuses, "title", "In Progress");
                if(statusInProgress && statusInProgress.value){
                    selectedStatuses.push(statusInProgress.value);
                }
            }

            if(this.state.showCompleted){
                let statusCompleted = Utils.findStatus(this.state.statuses, "title", "Completed");
                if(statusCompleted && statusCompleted.value){
                    selectedStatuses.push(statusCompleted.value);
                }
            }

            if(this.state.showUnsuccessful){
                let statusCancelled = Utils.findStatus(this.state.statuses, "title", "Cancelled");
                if(statusCancelled && statusCancelled.value){
                    selectedStatuses.push(statusCancelled.value);
                }

                let statusFailed = Utils.findStatus(this.state.statuses, "title", "Failed");
                if(statusFailed && statusFailed.value){
                    selectedStatuses.push(statusFailed.value);
                }

                let statusRejected = Utils.findStatus(this.state.statuses, "title", "Rejected");
                if(statusRejected && statusRejected.value){
                    selectedStatuses.push(statusRejected.value);
                }
            }
        }

        return selectedStatuses.join(',');
    }

    selectAllDrivers = () => {
        let driverIds = [];
        if(this.state.driverItems && this.state.driverItems.length > 0){
            driverIds = this.state.driverItems.map((item, i) => {
                return item.value;
            });
        }

        this.setState({
            isSelectAllDriver: true,
            driverIds: driverIds,
        }, () => {
            this.props.action.mapSlice.callLatestLocationApi(this.state.driverIds, (state: Boolean, data: any) => {});
        });
    }

    onChangeDriverAction = () => {
        this.setState({
            isLoading: true,
            isLoadingMap: true,
            jobMarkers: [],
        }, () => {
            let driverIds = (this.state.driverIds && this.state.driverIds.length > 0) ? this.state.driverIds.join(',') : '';
            let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
            let statusIds = this.getSelectedStatus();

            // if((this.state.driverIds && this.state.driverIds.length > 0)){
                this.callActiveJobMapPositionApi(driverIds, selectedDate, statusIds, (jobMarkers) => {
                    this.setState({
                        isLoading: false,
                        isLoadingMap: false,
                        jobMarkers: jobMarkers,
                    });
                });
            // } else {
            //     this.setState({
            //         isLoading: false,
                    // isLoadingMap: false,
            //         jobMarkers: [],
            //     });
            // }
            
            this.props.action.mapSlice.callLatestLocationApi(this.state.driverIds, (state: Boolean, data: any) => {});
        });
    }
    
    onChangeDateAction = () => {
        this.setState({
            isLoading: true,
            isLoadingMap: true,
            jobMarkers: [],
        }, () => {
            let driverIds = (this.state.driverIds && this.state.driverIds.length > 0) ? this.state.driverIds.join(',') : '';
            let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
            let statusIds = this.getSelectedStatus();
            
            // if((this.state.driverIds && this.state.driverIds.length > 0)){
                this.callActiveJobMapPositionApi(driverIds, selectedDate, statusIds, (jobMarkers) => {
                    this.setState({
                        isLoading: false,
                        isLoadingMap: false,
                        jobMarkers: jobMarkers,
                    });
                });
            // } else {
            //     this.setState({
            //         isLoading: false,
                    // isLoadingMap: false,
            //         jobMarkers: [],
            //     });
            // }
        });
    }

    onChangeStatusAction = () => {
        this.setState({
            isLoading: true,
            isLoadingMap: true,
            jobMarkers: [],
        }, () => {
            let driverIds = (this.state.driverIds && this.state.driverIds.length > 0) ? this.state.driverIds.join(',') : '';
            let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
            let statusIds = this.getSelectedStatus();
        
            if(statusIds && statusIds != ''){
                this.callActiveJobMapPositionApi(driverIds, selectedDate, statusIds, (jobMarkers) => {
                    this.setState({
                        isLoading: false,
                        isLoadingMap: false,
                        jobMarkers: jobMarkers,
                    });
                });
            } else {
                this.setState({
                    isLoading: false,
                    isLoadingMap: false,
                    jobMarkers: [],
                });
            }
        });
    }

    onChangeActiveBinAction = () => {
        this.setState({
            isLoading: true,
            isLoadingMap: true,
            binMarkers: [],
        }, () => {
            let driverIds = (this.state.driverIds && this.state.driverIds.length > 0) ? this.state.driverIds.join(',') : '';
            let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
            
            if(this.state.showCurrentActiveBin){
                this.callActiveBinMapPositionApi(driverIds, selectedDate, (binMarkers) => {
                    this.setState({
                        isLoading: false,
                        isLoadingMap: false,
                        binMarkers: binMarkers,
                    });
                });
            } else {
                this.setState({
                    isLoading: false,
                    isLoadingMap: false,
                    binMarkers: [],
                });
            }
        });
    }

    // onSearcActiveJobsAction = () => {
    //     this.setState({
    //         isLoading: true,
                // isLoadingMap: true,
    //         jobMarkers: [],
    //     }, () => {
    //         let driverIds = (this.state.driverIds && this.state.driverIds.length > 0) ? this.state.driverIds.join(',') : '';
    //         let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
    //         let statusIds = this.getSelectedStatus();

    //         // if((this.state.driverIds && this.state.driverIds.length > 0)){
    //             this.callActiveJobMapPositionApi(driverIds, selectedDate, statusIds, (jobMarkers) => {
    //                 this.setState({
    //                     isLoading: false,
    //                     jobMarkers: jobMarkers,
    //                 });
    //             });
    //         // } else {
    //         //     this.setState({
    //         //         isLoading: false,
                // isLoadingMap: false,
    //         //         jobMarkers: [],
    //         //     });
    //         // }
    //     });
    // }
    // onSearchActiveBinAction = () => {
    //     this.setState({
    //         isLoading: true,
                // isLoadingMap: true,
    //         binMarkers: [],
    //     }, () => {
    //         let driverIds = (this.state.driverIds && this.state.driverIds.length > 0) ? this.state.driverIds.join(',') : '';
    //         let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
            
    //         if(this.state.showCurrentActiveBin){
    //             this.callActiveBinMapPositionApi(driverIds, selectedDate, (binMarkers) => {
    //                 this.setState({
    //                     isLoading: false,
    //                     binMarkers: binMarkers,
    //                 });
    //             });
    //         } else {
    //             this.setState({
    //                 isLoading: false,
                // isLoadingMap: false,
    //                 binMarkers: [],
    //             });
    //         }
    //     });
    // }

    getLegends = (x) => {
        return <>
            <div className={'active-period-title'}>Active period</div>
            <div className={'active-period-legend'}>
                {(this.state.legendItems && this.state.legendItems.length > 0) && this.state.legendItems.map((item, i) => {
                    return <Tooltip key={i} title={item.info} placement={'bottom'}>
                        <div className={'active-period-legend-' + item.color + ' ' + this.checkActiveLegend(item.value)} onClick={(e) => this.onClickLegend(item.value)}>{item.title}</div>
                    </Tooltip>
                })}
            </div>
        </>
    }
    checkActiveLegend = (x = null) => {
        if(x){
            let legends = this.state.legends;
            if(legends && legends.length > 0){
                let index = legends.findIndex((y) => y == x);
                return (index === -1) ? '' : 'active';
            } else {
                return 'active';
            }
        } else {
            return '';
        }
    }
    onClickLegend = (x = null) => {
        if(x){
            let legends = this.state.legends;
    
            if(this.state.isLegendMultiSelect){
                let index = legends.findIndex((y) => y == x);
                if(index === -1) {
                    legends.push(x);
                } else {
                    legends.splice(index, 1);
                }
            } else {
                let index = legends.findIndex((y) => y == x);
                if(index === -1) {
                    legends = [x];
                } else {
                    legends = [];
                }
            }
    
            this.setState({
                legends: legends,
            }, () => {
                this.onChangeActiveBinAction();
            });
        }
    }
    getColorByLegend = (item) => {
        //if the same site got couple of bins with different color, the oldest color will be shows as the pin color
        if(item){
            if(item.filter == ActiveBinNumberRequestFilter.Fourteen){
                return '#ffc107'; //yellow

            } else if(item.filter == ActiveBinNumberRequestFilter.TwentyOne){
                return '#fd7e14'; //orange

            } else if(item.filter == ActiveBinNumberRequestFilter.ThirtyPlus){
                return '#dc3545'; //red

            } else if(item.filter == ActiveBinNumberRequestFilter.SixtyPlus){
                return '#7d2029'; //Dark red

            } else {
                return '#088F8F'; //green
            }
        }
    }
    getInfoByLegend = (x) => {
        let index = this.state.legendItems.findIndex((y) => y.value == x);
        
        if(index != -1){
            return this.state.legendItems[index].info;
        } else {
            return '';
        }
    }
    getLegendArgs = () => {
        return (this.state.legends && this.state.legends.length > 0) ? this.state.legends.join(',') : null;
    }
    /* END FUNCTION */


	topSection = () => {
		return <Row className='align-items-center'>
            <Col xs={true} sm={true} md={'auto'} lg={'auto'} xl={'auto'} xxl={1} className={'order-0 order-md-0 order-lg-0 order-xl-0 pb-5 pb-xl-2 pb-xxl-0'}>
                <h1 className={'title m-0'}>Map</h1>
            </Col>
            <Col xs={12} sm={12} md={true} lg={true} xl={3} xxl={3} className={'order-2 order-md-1 order-lg-2 pb-5 pb-xl-0'}>
                <Row className='align-items-center'>
                    <Col xs={12} md={'auto'}>Vehicle / Driver</Col>
                    <Col xs={12} md={true}>
                        <Form.Group>
                            <Form.Control
                                as = {RSuiteCheckPicker}

                                id={'mapDriverVehicleFieldID'}
                                placeholder={''}
                                items={this.state.driverItems}
                                value={this.state.driverIds}
                                isLoading={this.state.driverIsLoading}
                                shouldClose={!this.state.isLoadingMap}
                                isSelectedAll={this.state.isSelectAllDriver}
                                allCount={() => {
                                    let count = null;

                                    let items = this.state.driverItems;
                                    if(!this.state.driverIsLoading && items && items.length > 0){
                                        for(let i = 0; i < items.length; i++){
                                            if(items[i] && items[i].item && items[i].item.jobs && items[i].item.jobs.length > 0){
                                                count = count + items[i].item.jobs.length;
                                            } else {
                                                count = count + 0;
                                            }
                                        }
                                    }

                                    return count;
                                }}
                                searchable={true}
                                cleanable={true}
                                countable={true}
                                showSelectAll={true}
                                isDefault={true}

                                onSelectAll={async (checked, isIndeterminate) => {
                                    this.setState({
                                        isSelectAllDriver: checked,
                                        driverIds: (checked) ? this.state.driverItems.map(x => x.value) : [],
                                    }, () => {
                                        this.onChangeDriverAction();
                                    });
                                }}
                                onClick={() => {
                                    this.setState({
                                        driverIsLoading: true,
                                    }, () => {
                                        let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                        let statusIds = this.getSelectedStatus(true);

                                        let binMarkers = this.state.binMarkers;
                                        if(binMarkers && binMarkers.length > 0){
                                            for(let m = 0; m < binMarkers.length; m++){
                                                binMarkers[m].isOpen = false;
                                            }
                                        }

                                        this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                            this.setState({
                                                driverItems: items,
                                                driverIsLoading: false,
                                                binMarkers: binMarkers,
                                            });
                                        });
                                    });
                                }}
                                renderValue={(value, checkedItems, selectedElement) => {
                                    let title = '';
                                    let count = 0;
                                    if(checkedItems && checkedItems.length > 0){
                                        title = checkedItems.map(x => {
                                            if(x && x.item && x.item.jobs && x.item.jobs.length > 0){
                                                count = count + x.item.jobs.length;
                                            }
                                            return x.title;
                                        }).join(', ');
                                    }

                                    return <span key={-1} className="rs-picker-toggle-value" style={{ flex: '100%' }}>
                                        <span className="rs-picker-value-list">
                                            <span className="rs-picker-value-item">{(!this.state.driverIsLoading) ? title : 'Please wait ...'}</span>
                                        </span>
                                        {(!this.state.driverIsLoading) && <span className="rs-picker-value-count" title={count}>{count}</span>}
                                    </span>
                                }}
                                renderMenuItem={(label, item) => {
                                    return <div className="position-relative d-flex align-items-center">
                                        <div style={{ flex: '0 1 88%' }}>{label}</div>
                                        <div style={{ flex: '0 1 12%' }}>
                                            <span 
                                                className={"ms-3 badge rounded-pill bg-primary"}
                                                style={{
                                                    padding: '3px 8px',
                                                    fontSize: '11px',
                                                }}
                                            >{(item && item.item && item.item.jobs && item.item.jobs.length > 0) ? item.item.jobs.length : 0}</span>
                                        </div>
                                    </div>
                                }}
                                onChange={(values, items) => {
                                    this.setState({
                                        isSelectAllDriver: ((items.length > 0) ? (values.length === items.length) : false),
                                        driverIds: values,
                                    }, () => {
                                        this.onChangeDriverAction();
                                    });
                                }}
                                onClear={async () => {
                                    this.setState({
                                        isSelectAllDriver: false,
                                        driverIds: [],
                                    }, () => {
                                        this.onChangeDriverAction();
                                    });
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={4} className={'order-3 order-md-3 order-lg-3 order-xl-2 pb-5 pb-xl-0'}>
                <Row className='align-items-center ps-0 ps-xl-10 button-arrow'>
                    <Col xs={12} sm={'auto'} className={'order-0 order-sm-0 pb-5 pb-sm-0'}>Date</Col>
                    <Col xs={'auto'} className={'order-1 order-sm-1 pb-5 pb-sm-0'}>
                        <Button
                            className={'secondary'}
                            variant="outlined"
                            color="primary"
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={() => {
                                let newDate = moment(this.state.selectedDate, Utils.getAPIDateFormat()).subtract(1, "days").toDate();

                                this.setState({
                                    selectedDate: newDate,
                                }, () => {
                                    this.onChangeDateAction();

                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className="fas fa-arrow-left"></i>
                        </Button>
                        <Button
                            className={'secondary'}
                            variant="outlined"
                            color="primary"
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={() => {
                                let newDate = moment(this.state.selectedDate, Utils.getAPIDateFormat()).add(1, "days").toDate();
                                
                                this.setState({
                                    selectedDate: newDate,
                                }, () => {
                                    this.onChangeDateAction();

                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className="fas fa-arrow-right"></i>
                        </Button>
                    </Col>
                    <Col xs={12} sm={true} className={'order-3 order-sm-2 pb-5 pb-sm-0'}>
                        <Form.Group>
                            <InputGroup>
                                <Form.Control
                                    as = {RSuiteDatePicker}
                                    
                                    id={'mapDateFieldID'}
                                    placeholder={''}
                                    value={this.state.selectedDate}
                                    icon={<></>}
                                    placement={'bottom'}
                                    // readOnly={(this.state.driverIds.length == 0)}
                                    cleanable={false}
                                    oneTap={true}
                                    isDefault={true}
                                    onChange={async (value) => {
                                        this.setState({
                                            selectedDate: value
                                        }, () => {
                                            this.onChangeDateAction();

                                            let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                            let statusIds = this.getSelectedStatus(true);
        
                                            this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                                this.setState({
                                                    driverItems: items,
                                                    driverIsLoading: false,
                                                });
                                            });
                                            this.callReadStatusCountApi(selectedDate, statusIds);
                                        });
                                    }}
                                />
                                <InputGroup.Text className={'bg-white'}>
                                    <CalendarIcon />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={'auto'} className={'order-2 order-sm-3 pb-5 pb-sm-0'}>
                        <Button
                            className={"primary"}
                            variant={"outlined"}
                            color={"primary"}
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={() => {
                                this.setState({
                                    selectedDate: new Date(),
                                }, () => {
                                    this.onChangeDateAction();

                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            TODAY
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} sm={12} md={true} lg={true} xl={10} xxl={true} className={'order-3 order-md-3 order-lg-3 order-xl-1 order-xxl-3 pb-5 pb-xl-2 pb-xxl-0'}>
                <IconButtonSearch 
                    value={this.state.search}
                    isOpen={(this.state.search !== '') ? true : false}
                    onPressEnter={async (value) => {
                        this.setState({
                            search: value,
                            showCurrentActiveBin: true,
                        }, () => {
                            this.callSearchApis();
                            // this.onSearcActiveJobsAction();
                            // this.onSearchActiveBinAction();

                            let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                            let statusIds = this.getSelectedStatus(true);

                            this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                this.setState({
                                    driverItems: items,
                                    driverIsLoading: false,
                                });
                            });
                            this.callReadStatusCountApi(selectedDate, statusIds);
                        });
                    }}
                    onClear={async () => {
                        this.setState({
                            search: '',
                        }, () => {
                            this.callSearchApis();
                            // this.onSearcActiveJobsAction();
                            // this.onSearchActiveBinAction();
                            
                            let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                            let statusIds = this.getSelectedStatus(true);

                            this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                this.setState({
                                    driverItems: items,
                                    driverIsLoading: false,
                                });
                            });
                            this.callReadStatusCountApi(selectedDate, statusIds);
                        });
                    }}
                />
            </Col>
            <Col xs={true} sm={true} md={'auto'} lg={'auto'} xl={true} xxl={true} className={'order-1 order-md-2 order-lg-2 order-xl-3 text-end pb-5 pb-xl-0'}>
                <Dropdown 
                    className={'dropdown-outline-secondary'}
                    autoClose="inside"
                    show={this.state.showLayouts}
                    onToggle={this.onToggleHandler}
                >
                    <Dropdown.Toggle variant={'outline'}>
                        <span className={'pe-5'}>Show layers</span>
                        <i className="fas fa-layer-group text-primary"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                    showUnassigned: !this.state.showUnassigned,
                                }, () => {
                                    this.onChangeStatusAction();

                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.showUnassigned ? ' checked ' : ' unchecked ')}>{this.state.showUnassigned ? 'check_box' : 'check_box_outline_blank'}</i>
                            <UnassignedIcon style={{ width: '14px' }} />
                            <span className={'ps-2'}>Unassigned</span>
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                    showAssigned: !this.state.showAssigned,
                                }, () => {
                                    this.onChangeStatusAction();
                                    
                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.showAssigned ? ' checked ' : ' unchecked ')}>{this.state.showAssigned ? 'check_box' : 'check_box_outline_blank'}</i>
                            <AssignedIcon style={{ width: '14px' }} />
                            <span className={'ps-2'}>Assigned</span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                    showDispatched: !this.state.showDispatched,
                                }, () => {
                                    this.onChangeStatusAction();
                                    
                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.showDispatched ? ' checked ' : ' unchecked ')}>{this.state.showDispatched ? 'check_box' : 'check_box_outline_blank'}</i>
                            <DispatchedIcon style={{ width: '14px' }} />
                            <span className={'ps-2'}>Dispatched</span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                    showAcknowledged: !this.state.showAcknowledged,
                                }, () => {
                                    this.onChangeStatusAction();
                                    
                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.showAcknowledged ? ' checked ' : ' unchecked ')}>{this.state.showAcknowledged ? 'check_box' : 'check_box_outline_blank'}</i>
                            <AcknowledgedIcon style={{ width: '14px' }} />
                            <span className={'ps-2'}>Acknowledged</span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                    showStarted: !this.state.showStarted,
                                }, () => {
                                    this.onChangeStatusAction();
                                    
                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.showStarted ? ' checked ' : ' unchecked ')}>{this.state.showStarted ? 'check_box' : 'check_box_outline_blank'}</i>
                            <StartedIcon style={{ width: '14px' }} />
                            <span className={'ps-2'}>Started</span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                    showInProgress: !this.state.showInProgress,
                                }, () => {
                                    this.onChangeStatusAction();
                                    
                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.showInProgress ? ' checked ' : ' unchecked ')}>{this.state.showInProgress ? 'check_box' : 'check_box_outline_blank'}</i>
                            <InProgressIcon style={{ width: '14px' }} />
                            <span className={'ps-2'}>In Progress</span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                    showCompleted: !this.state.showCompleted,
                                }, () => {
                                    this.onChangeStatusAction();
                                    
                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.showCompleted ? ' checked ' : ' unchecked ')}>{this.state.showCompleted ? 'check_box' : 'check_box_outline_blank'}</i>
                            <CompletedIcon style={{ width: '14px' }} />
                            <span className={'ps-2'}>Completed</span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            // disabled={(this.state.driverIds.length == 0)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.setState({
                                    showUnsuccessful: !this.state.showUnsuccessful,
                                }, () => {
                                    this.onChangeStatusAction();
                                    
                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.showUnsuccessful ? ' checked ' : ' unchecked ')}>{this.state.showUnsuccessful ? 'check_box' : 'check_box_outline_blank'}</i>
                            <UnsuccessfulIcon style={{ width: '14px' }} />
                            <span className={'ps-2'}>Unsuccessful</span>
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                let showCurrentActiveBin = !this.state.showCurrentActiveBin;
                                this.setState({
                                    showCurrentActiveBin: showCurrentActiveBin,
                                    // includeCollected: (showCurrentActiveBin) ? this.state.includeCollected : false,
                                }, () => {
                                    this.onChangeActiveBinAction();
                                    
                                    let selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                    let statusIds = this.getSelectedStatus(true);

                                    this.callReadDriversApi(this.state.driverItems, selectedDate, statusIds, async (items) => {
                                        this.setState({
                                            driverItems: items,
                                            driverIsLoading: false,
                                        });
                                    });
                                    this.callReadStatusCountApi(selectedDate, statusIds);
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.showCurrentActiveBin ? ' checked ' : ' unchecked ')}>{this.state.showCurrentActiveBin ? 'check_box' : 'check_box_outline_blank'}</i>
                            <StyledActiveBinIcon />
                            <span className={'ps-2'}>Show current active bin</span>
                        </Dropdown.Item>
                        {this.state.showCurrentActiveBin && <Dropdown.ItemText className={'active-period'}>{this.getLegends()}</Dropdown.ItemText>}
                        
                        {/* <Dropdown.Item 
                            className={'d-flex align-items-center'}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                let includeCollected = !this.state.includeCollected;
                                this.setState({
                                    includeCollected: includeCollected,
                                    showCurrentActiveBin: (!includeCollected) ? this.state.showCurrentActiveBin : true,
                                }, () => {
                                    this.onChangeActiveBinAction();
                                });
                            }}
                        >
                            <i className={'material-icons check-box-icon pe-2' + (this.state.includeCollected ? ' checked ' : ' unchecked ')}>{this.state.includeCollected ? 'check_box' : 'check_box_outline_blank'}</i>
                            <ActiveBinIcon />
                            <span className={'ps-2'}>Show collected bin</span>
                        </Dropdown.Item> */}

                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
	}

    contentSection = () => {
		return <>
            {!this.state.isLoadingMapInit && <GoogleMapInline
                center={this.state.centerMap}
                zoom={this.state.zoomMap}
                isLoading={this.state.isLoadingMap}

                markers={
                    (moment(this.state.selectedDate).startOf('day').isSame(moment().startOf('day')))
                    ?
                    [...this.state.jobMarkers, ...this.state.binMarkers, ...this.props.mapSlice.items]
                    :
                    [...this.state.jobMarkers, ...this.state.binMarkers]
                }
                detailsApi={(item, callback = null) => {
                    if(item.type == 1){
                        if(callback){
                            callback();
                        }
                    } else {
                        let binMarkers = this.state.binMarkers;

                        if(binMarkers && binMarkers.length > 0){
                            for(let m = 0; m < binMarkers.length; m++){
                                binMarkers[m].isOpen = false;
                            }
                        }
                        
                        this.setState({
                            binMarkers: binMarkers,
                        }, () => {
                            this.callActiveBinMapPositionDetailsApi(item.value, (item) => {
                                if(item){
                                    let binMarkers = this.state.binMarkers;
                                    
                                    let binMarkerIndex = binMarkers.findIndex(x => x.value == item.value);
                                    if(binMarkerIndex != -1){
                                        binMarkers[binMarkerIndex] = item;
                                    }
    
                                    this.setState({
                                        binMarkers: binMarkers,
                                    }, () => {
                                        if(callback){
                                            callback();
                                        }
                                    });
                                } else {
                                    if(callback){
                                        callback();
                                    }
                                }
                            });
                        });
                    }
                }}
            />}
        </>
	}

    
    infoWindowJob = (item, refresh) => {
        let vehicleDriver = ((item && item.item && item.item.vehicleName) ? item.item.vehicleName : '') + ' - ' + ((item && item.item && item.item.driverName) ? item.item.driverName : '');

        return <Row>
            <Col xs={12}>
                <Row className={'align-items-center'}>
                    <Col xs={true}>
                        <Row>
                            <Col xs={12}><h5 className={'mb-1'}>{item.item.customerName}</h5></Col>
                            <Col xs={12}><h6 className={'fw-normal'}>{item.title}</h6></Col>
                        </Row>
                    </Col>
                    {(item && item.items && item.items.length > 1) && <Col xs={12} sm={6}>
                        <Form.Group as={Row} className={'align-items-center'}>
                            <Col xs={12} md={true} className={'pb-2 pb-md-0'}>
                                <Form.Control
                                    as = {Carousel}

                                    className={'border-0'}
                                    itemsToShow={3}
                                    outerSpacing={0}
                                    itemPadding={[0, 0]}
                                    initialActiveIndex={item.selectedJob}
                                    breakPoints={[
                                        { width: 1, itemsToShow: 1 },
                                        { width: 150, itemsToShow: 2 },
                                        { width: 200, itemsToShow: 3 },
                                    ]}
                                    pagination={false}
                                    showArrows={(item && item.items && item.items.length > 3) ? true : false}
                                    renderArrow={(x) => {
                                        return <CircleButton 
                                            variant="outline-primary"
                                            onClick={x.onClick}
                                            disabled={x.isEdge}
                                        >
                                            <span className={'fa ' + ((x.type === consts.PREV) ? 'fa-arrow-left' : 'fa-arrow-right') + ' fs-0'}></span>
                                        </CircleButton>
                                    }}
                                >
                                    {item.items.map((jobItem, i) => {
                                        return <CircleButton key={i}
                                            variant={(i === item.selectedJob) ? 'primary' : 'outline-primary'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                refresh(item, i, jobItem);
                                            }}
                                        >
                                            {(i+1)}
                                        </CircleButton>
                                    })}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Col>}
                </Row>
            </Col>

            <Col xs={12} className={'border-bottom pt-3'}></Col>

            <Col xs={12}>
                <Row>
                    <Col xs={12} md={8} className={'pt-3'}>

                        <Row className={'pb-2'}>
                            <Col xs={12} md={4}>
                                <h6>
                                    <small>Job type</small>
                                    <br />
                                    <b>{(item && item.item && item.item.jobTemplateName) ? item.item.jobTemplateName : ' - '}</b>
                                </h6>
                            </Col>
                            <Col xs={12} md={4}>
                                <h6>
                                    <small>Vehicle / Driver</small>
                                    <br />
                                    <Tooltip title={vehicleDriver}><b className={'d-inline-block text-truncate'} style={{ width: '130px' }}>{vehicleDriver}</b></Tooltip>
                                </h6>
                            </Col>
                            <Col xs={12} md={4}>
                                <h6>
                                    <small>Job no.</small>
                                    <br />
                                    {
                                        (item && item.item)
                                        ?
                                        <a href={'/jobs-form?id=' + item.item.jobId}
                                            target={'_blank'}
                                            rel="noreferrer"
                                            className={'link-primary d-flex'}
                                        >
                                            <b>{item.item.jobNumber}</b>
                                        </a>
                                        :
                                        ' - '
                                    }
                                </h6>
                            </Col>
                        </Row>

                        <Row className={'pb-2'}>
                            <Col xs={12} md={4}>
                                <h6>
                                    <small>Bin OUT</small>
                                    <br />
                                    <b>{(item && item.item && item.item.binTypeOut) ? item.item.binTypeOut : ' - '}</b>
                                </h6>
                            </Col>
                            <Col xs={12} md={4}>
                                <h6>
                                    <small>For Waste</small>
                                    <br />
                                    <b>{(item && item.item && item.item.wasteTypeOut) ? item.item.wasteTypeOut : ' - '}</b>
                                </h6>
                            </Col>
                            <Col xs={12} md={4}>
                                <h6>
                                    <small>Bin #</small>
                                    <br />
                                    <b>{(item && item.item && item.item.binNumberOut) ? item.item.binNumberOut : ' - '}</b>
                                </h6>
                            </Col>
                        </Row>

                        <Row className={'pb-2'}>
                            <Col xs={12} md={4}>
                                <h6>
                                    <small>Bin IN</small>
                                    <br />
                                    <b>{(item && item.item && item.item.binTypeIn) ? item.item.binTypeIn : ' - '}</b>
                                </h6>
                            </Col>
                            <Col xs={12} md={4}>
                                <h6>
                                    <small>With Waste</small>
                                    <br />
                                    <b>{(item && item.item && item.item.wasteTypeIn) ? item.item.wasteTypeIn : ' - '}</b>
                                </h6>
                            </Col>
                            <Col xs={12} md={4}>
                                <h6>
                                    <small>Bin #</small>
                                    <br />
                                    <b>{(item && item.item && item.item.binNumberIn) ? item.item.binNumberIn : ' - '}</b>
                                </h6>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} md={4} className={'border-start pt-3'}
                        style={{
                            maxHeight: '160px',
                            overflow: 'auto',
                        }}
                    >
                        {
                            (item && item.item && item.item.histories && item.item.histories.length > 0) 
                            ?
                            <Row>
                                {item.item.histories.map((hist, h) => {
                                    return <Col key={h} xs={12} className={'pb-2'}>
                                        <h6>
                                            <b>{hist.currentStatus}</b>
                                            <br />
                                            <small>{Utils.toUcase(hist.updatedDisplay)}</small>
                                        </h6>
                                    </Col>
                                })}
                            </Row>
                            :
                            <Row>
                                <Col xs={12} className={'pb-2'}>
                                    <h6>
                                        <b>No result!</b>
                                    </h6>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    }
    
    infoWindowBin = (item, refresh) => {
        return <Row className={'pb-5'}>
            <Col xs={12}><h3 className={'mb-1'}>{item.item.customerName}</h3></Col>
            <Col xs={12}><h5 className={'fw-normal'}>{item.title}</h5></Col>

            {(item && item.items && item.items.length > 0) && <Col xs={12} className={'pt-2'}>
                <div
                    style={{
                        maxHeight: '135px',
                        overflow: 'auto',
                    }}
                >
                    <table className={'no-responsive-table w-100'}>
                        <thead
                            style={{
                                position: 'sticky',
                                background: 'white',
                                top: '0',
                                zIndex: '10',
                            }}
                        >
                            <tr className={'border-bottom'}>
                                <th style={{ height: 'auto', color: '#ABABAB' }} className={'pt-2 pb-2'}>Bin</th>
                                <th style={{ height: 'auto', color: '#ABABAB' }} className={'pt-2 pb-2'}>For Waste</th>
                                <th style={{ height: 'auto', color: '#ABABAB' }} className={'pt-2 pb-2'}>Bin #</th>
                                <th style={{ height: 'auto', color: '#ABABAB' }} className={'pt-2 pb-2'}>Job #</th>
                                <th style={{ height: 'auto', color: '#ABABAB' }} className={'pt-2 pb-2'}>Last job update</th>
                                <th style={{ height: 'auto', color: '#ABABAB' }} className={'pt-2 pb-2'}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {item.items.map((job, j) => {
                                return <tr key={j} className={'border-bottom pt-2 pb-2'}>
                                    <td style={{ height: 'auto', fontWeight: 'bold' }} className={'pt-2 pb-2'}>{(job.binTypeOut && job.binTypeOut != '') ? job.binTypeOut : job.binTypeIn}</td>
                                    <td style={{ height: 'auto', fontWeight: 'bold' }} className={'pt-2 pb-2'}>{(job.wasteTypeOut && job.wasteTypeOut != '') ? job.wasteTypeOut : job.wasteTypeIn}</td>
                                    <td style={{ height: 'auto', fontWeight: 'bold' }} className={'pt-2 pb-2'}>{(job.binNumberOut && job.binNumberOut != '') ? job.binNumberOut : job.binNumberIn}</td>
                                    <td style={{ height: 'auto', fontWeight: 'bold' }} className={'pt-2 pb-2'}>
                                        <a href={'/jobs-form?id=' + ((job.jobIdOut && job.jobIdOut != '' && job.jobIdOut > 0) ? job.jobIdOut : job.jobIdIn)}
                                            target={'_blank'}
                                            rel="noreferrer"
                                            className={'link-primary d-flex'}
                                        >
                                            <b>{(job.jobNumberOut && job.jobNumberOut != '') ? job.jobNumberOut : job.jobNumberIn}</b>
                                        </a>
                                    </td>
                                    {/* <td style={{ height: 'auto', fontWeight: 'bold' }} className={'pt-2 pb-2'}>{moment.utc(job.lastJobDate).local().format("DD/MM/YYYY hh:mm A")}</td> */}
                                    <td style={{ height: 'auto', fontWeight: 'bold' }} className={'pt-2 pb-2'}>
                                        <Tooltip title={this.getInfoByLegend(job.filter)} placement={'top'}>
                                            <i className="fas fa-circle pe-1" style={{ color: this.getColorByLegend(job) }}></i>
                                        </Tooltip>
                                        {moment(job.lastJobDate).format("DD/MM/YYYY hh:mm A")}
                                    </td>
                                    <td style={{ height: 'auto', fontWeight: 'bold' }} className={'pt-2 pb-2'}>
                                        <DropdownFilter 
                                            className={'h-auto'} 
                                            text={<b className={'text-capitalize'}>Active</b>} 
                                            isClickAway={true} 
                                            rightIcon={<>
                                                <i className={'material-icons ps-2'}>play_circle_filled</i>
                                            </>} 
                                            color={"default"} 
                                            variant={"text"}
                                            onOpen={() => {
                                                this.callReadAccountSettingsTypeApi((selectedJobTemplate) => {
                                                    this.setState({
                                                        isLoadingJobTypeDropdown: true,
                                                        arrJobTypeDropdown: [],
                                                    }, () => {
                                                        this.callReadJobTemplateDropdownApi(this.state.selectedJobTypeDropdown, (jobTypeList) => {
                                                            this.setState({
                                                                arrJobTypeDropdown: jobTypeList,
                                                                isLoadingJobTypeDropdown: false,
                                                            });
                                                        });
                                                    });
                                                });
                                            }}
                                        >
                                            {
                                                this.state.isLoadingJobTypeDropdown
                                                ?
                                                <ListGroup.Item>Loading...</ListGroup.Item>
                                                :
                                                <ListGroup>
                                                    <ListGroup.Item
                                                        action
                                                        onClick={() => {
                                                            Utils.clickOutside()
                                                            this.setState({
                                                                isCollectedDialog: true,
                                                                collectedRow: {
                                                                    item: item,
                                                                    j: j,
                                                                    job: job,
                                                                    refresh: refresh,
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        Mark this bin as collected
                                                    </ListGroup.Item>
                                                    <ListGroup.Item
                                                        className={'d-flex align-items-center'}
                                                        action
                                                        onClick={() => {
                                                            Utils.clickOutside()
                                                            let dataParamsCollectBin = {
                                                                selectedJobTypeDropdown: this.state.selectedJobTypeDropdown,
                                                            };
                                                            Utils.setDrawerData(this.formDrawerCollectBin, dataParamsCollectBin);
                                                            Utils.showDrawer(this.formDrawerCollectBin);
                                                        }}
                                                    >
                                                        Create job to collect bin
                                                        <i className={'material-icons ps-3'} style={{ fontSize: '18px' }}>settings</i>
                                                    </ListGroup.Item>

                                                    <ListGroup.Item className={'p-0 border-0'}>
                                                        <ListGroup variant={'flush'}>
                                                            {
                                                                (this.state.arrJobTypeDropdown && this.state.arrJobTypeDropdown.length > 0)
                                                                &&
                                                                this.state.arrJobTypeDropdown.map((jTemp, k) => {
                                                                    let params = {
                                                                        jobTemplateId: jTemp.value,
                                                                        customerId: job.customerId,
                                                                        customerSiteId: job.customerSiteId,

                                                                        driverId: job.driverId,
                                                                        driverName: job.driverName,
                                                                        vehicleId: job.vehicleId,
                                                                        vehicleName: job.vehicleName,

                                                                        customerSiteBinHistoryId: job.customerSiteBinHistoryId,

                                                                        binTypeIdIn: job.binTypeIdIn,
                                                                        binTypeIn: job.binTypeIn,
                                                                        wasteTypeIdIn: job.wasteTypeIdIn,
                                                                        wasteTypeIn: job.wasteTypeIn,
                                                                        binNumberIn: job.binNumberIn,

                                                                        binTypeIdOut: job.binTypeIdOut,
                                                                        binTypeOut: job.binTypeOut,
                                                                        wasteTypeIdOut: job.wasteTypeIdOut,
                                                                        wasteTypeOut: job.wasteTypeOut,
                                                                        binNumberOut: job.binNumberOut,

                                                                    };
                                                                    
                                                                    return <ListGroup.Item
                                                                        key={k}
                                                                        className={'border-0'}
                                                                        action
                                                                        eventKey={k}
                                                                        href={'/jobs-form?params=' + encodeURIComponent(JSON.stringify(params))}
                                                                        target={'_blank'}
                                                                        rel="noreferrer"
                                                                    >
                                                                        {jTemp.title}
                                                                    </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            }
                                        </DropdownFilter>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </Col>}
        </Row>
    }

    
    /* DIALOGS */
    setCollectedDialog = () => {
        return <RemoveDialog 
            className={'z-index-999999'}
            title={'Change to collected'}
            body={'Are you sure you want to change to collected?'}
            cancelText={'No'}
            removeText={'Yes'}
            show={this.state.isCollectedDialog}
            isLoading={this.state.newDialogItemLoading}
            onCancel={() => {
                this.setState({
                    isCollectedDialog: false,
                    collectedRow: null,
                });
            }}
            onRemove={() => {
                let item = this.state.collectedRow;
                
                this.callMarkBinAsCollectedApi(item.job.customerSiteBinHistoryId, () => {
                    item.job.isCollected = true;
                    item.refresh(item.item, item.j, item.job);
                    
                    this.setState({
                        isCollectedDialog: false,
                        collectedRow: null,
                    }, () => {
                        this.onChangeActiveBinAction();
                    });
                });
            }}
        />
    }
    /* END DIALOGS */


    render() {
        return <div className={'map-page'}>
            <ViewLayout 
                isLoading={this.state.isLoading}
                topSection={this.topSection()}
                contentSection={this.contentSection()}
            />
            {this.setCollectedDialog()}
            {this.formDrawerCollectBinSection()}
        </div>
    }
}


const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
    clear: (payload) => dispatch(clear(payload)),
    action: {
      mapSlice: {
        callLatestLocationApi: (payload, callback) => dispatch(mapSlice.callLatestLocationApi(payload, callback)),
      },
    }
  });
  
  export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Map);