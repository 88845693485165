import React from "react";
import styled from 'styled-components';
import parse from "html-react-parser";

import { 
    Row,
    Col,
    Modal,
} from "react-bootstrap-v5";

import CloseIcon from '@material-ui/icons/Close';

import AuditTrailDetailsPopup from '../../Dialogs/AuditTrailDetailsPopup';
import AuditTrailJobRouteDialog from '../../Dialogs/AuditTrailJobRouteDialog';

import Utils from "../../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallPost, dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


const StyledTable = styled.table`
    width: 100% !important;

    thead {
        tr {
            th {
                background-color: transparent !important;
                color: #484848 !important;
                border-bottom: 1px solid #DFDFDF !important;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    tbody {
        tr {
            td {
                border-bottom: 0px !important;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
`;


class AuditTrailSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_audit_trail_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        
        let {
            values,
        } = props.formOptions;


        let historiesArr = (values && values.customFormParams && values.customFormParams.jobDetails && values.customFormParams.jobDetails.histories && values.customFormParams.jobDetails.histories.length > 0) ? values.customFormParams.jobDetails.histories : [];
        let rows = [];
        if (historiesArr && historiesArr.length > 0) {
            let histories = historiesArr.sort((a, b) => new Date(b.updated) - new Date(a.updated));
            rows = histories.map((item, i) => {
                return {
                    jobHistoryId: item.jobHistoryId,
                    jobId: item.jobId,
                    item: item,
                    time: Utils.toUcase(item.updatedDisplay),
                    color: (item.currentStatus.toLowerCase() === "completed") ? "bg-primary" : ((item.currentStatus.toLowerCase() === "deleted" || item.currentStatus.toLowerCase() === "cancelled" || item.currentStatus.toLowerCase() === "failed" || item.currentStatus.toLowerCase() === "rejected") ? "bg-danger" : "bg-light text-dark"),
                    contentTitle: <div>{parse(item.currentStatus)}</div>,
                    contentDesc: <div>{parse(item.description)}</div>,
                    icon: (item.currentStatus.toLowerCase() === "deleted" || item.currentStatus.toLowerCase() === "cancelled" || item.currentStatus.toLowerCase() === "failed" || item.currentStatus.toLowerCase() === "rejected") ? <CloseIcon /> : null,
                };
            });
        }

        this.state = {
            id: id,
            row: row,
            
            rows: rows,
            
            isAuditTrailDetailsDialog: false,
            itemAuditTrailDetailsDialog: null,

            isAuditTrailJobRouteDialog: false,
            itemAuditTrailJobRouteDialog: null,
        };
    }


    body = () => {
        // let {
        //     handleChange,
        //     setFieldValue,
        //     validateForm,
        //     values,
        //     errors,
        // } = this.props.formOptions;
        
        return <Row className={'pb-10'}>
            <Col xs={12} className={'mt-10 mb-10'}><hr /></Col>

            <Col xs={12} className={'mb-10'}>
                <h3>Audit Trail</h3>
            </Col>

            <Col xs={12}
                style={{
                    position: 'relative',
                    overflow: 'auto',
                }}
            >
                {this.setDataTable()}
            </Col>
        </Row>
    }
    
    setDataTable = () => {
        if(this.state.rows && this.state.rows.length > 0){
            return <StyledTable className={'no-responsive-table'}>
                <thead>
                    <tr>
                        <th width={'10%'}>Status</th>
                        <th width={'20%'}>User</th>
                        <th width={'44%'}>Description</th>
                        <th width={'15%'}>Timestamp</th>
                        <th width={'10%'}></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.rows.map((row, i) => {
                        return <tr key={i}>
                            <td>
                                {
                                    (
                                        (row.item.description.includes("status to <b>Started</b>")) ||
                                        (row.item.description.includes("status to <b>In Progress</b>")) ||
                                        (row.item.description.includes("status to <b>Completed</b>"))
                                    )
                                    ?
                                    <a href="/" className="link-primary-underline" 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();

                                            this.setState({
                                                isAuditTrailJobRouteDialog: true,
                                                itemAuditTrailJobRouteDialog: row,
                                            });
                                        }
                                    }>{row.item.currentStatus}</a>
                                    :
                                    row.item.currentStatus
                                }
                            </td>
                            <td>{row.item.updatedBy}</td>
                            <td>{row.contentDesc}</td>
                            <td>{row.time}</td>
                            <td>
                                <a href="/" className="link-default-underline" 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();

                                        this.setState({
                                            isAuditTrailDetailsDialog: true,
                                            itemAuditTrailDetailsDialog: row,
                                        });
                                    }
                                }>View Changes</a> 
                            </td>
                        </tr>
                    })}
                </tbody>
            </StyledTable>
        }
    }

    setAuditTrailDetailsDialog = () => {
      return <Modal
        show={this.state.isAuditTrailDetailsDialog}
        onHide={() => {
          this.setState({
            isAuditTrailDetailsDialog: false,
            itemAuditTrailDetailsDialog: null,
          });
        }}
        centered
        backdrop="static"
        dialogClassName="mw-90"
        keyboard={false}
      >
        <AuditTrailDetailsPopup
          item={this.state.itemAuditTrailDetailsDialog}
          onHide={() => {
            this.setState({
              isAuditTrailDetailsDialog: false,
              itemAuditTrailDetailsDialog: null,
            });
          }}
        />
      </Modal>
    }
   
    setAuditTrailJobRouteDialog = () => {
      return <Modal
        show={this.state.isAuditTrailJobRouteDialog}
        onHide={() => {
          this.setState({
            isAuditTrailJobRouteDialog: false,
            itemAuditTrailJobRouteDialog: null,
          });
        }}
        centered
        backdrop="static"
        dialogClassName="mw-90"
        keyboard={false}
      >
        <AuditTrailJobRouteDialog
          item={this.state.itemAuditTrailJobRouteDialog}
          onHide={() => {
            this.setState({
              isAuditTrailJobRouteDialog: false,
              itemAuditTrailJobRouteDialog: null,
            });
          }}
        />
      </Modal>
    }


    render() {
        return <>
            {this.body()}
            {this.setAuditTrailDetailsDialog()}
            {this.setAuditTrailJobRouteDialog()}
        </>
    }
}

const mapDispatchToProps = {
    dispatchApiCallPost,
    dispatchApiCallGet,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AuditTrailSection);
