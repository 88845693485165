import React, { Component } from 'react';

import moment from 'moment';
// import merge from 'deepmerge';
import { diff } from "nested-object-diff";

import Utils from '../../../utils/utils';
import { StepType, JobStepType, JobPaymentType, ServiceType, JobStepBinActivity, ReportUOM } from '../../../utils/enums';

import { 
    Box,
    Grid,
    Divider,
    Tooltip,
} from '@material-ui/core';

import CustomPopover from '../../../components/Tooltip/Popover';


class HistoryDetails extends Component {
  
    constructor(props){
        super(props);

        let index = (props.index) ? props.index : 0;
        let last = (props.last) ? props.last : 0;
        let currentVersion = (props.currentVersion) ? props.currentVersion : null;
        let previousVersion = (props.previousVersion) ? props.previousVersion : null;

        currentVersion = this.concatWasteTypse(currentVersion);
        previousVersion = this.concatWasteTypse(previousVersion);

        currentVersion = this.splitPricings(currentVersion);
        previousVersion = this.splitPricings(previousVersion);

        currentVersion = this.addCustomFields(currentVersion);
        previousVersion = this.addCustomFields(previousVersion);

        currentVersion = this.addPhotos(currentVersion);
        previousVersion = this.addPhotos(previousVersion);

        let item = { ...previousVersion, ...currentVersion };
        var differences = diff(previousVersion, currentVersion);

        this.state = {
            index: index,
            last: last,
            previousVersion: previousVersion,
            item: item,
            diff: differences,
        }
    }

    concatWasteTypse = (items = null) => {
        if(items && items.steps && items.steps.length > 0){
            for(let i = 0; i < items.steps.length; i++){
                if(items.steps[i] && items.steps[i].wasteTypes && items.steps[i].wasteTypes.length > 0){
                    let wts = [];
                    let wasteTypes = items.steps[i].wasteTypes.sort((a,b) => a.order - b.order);
                    for(let j = 0; j < wasteTypes.length; j++){
                        if(wasteTypes[j] && wasteTypes[j].wasteType && wasteTypes[j].wasteType.wasteTypeName !== ''){
                            wts.push(wasteTypes[j].wasteType.wasteTypeName);
                        };
                    }
                    items.steps[i]['wts'] = wts.join(', ');
                }
            }
        }
        return items;
    }

    splitPricings = (items = null) => {
        if(items && items.pricings && items.pricings.length > 0){
            let standardServices = items.pricings.filter(x => Utils.isStandardService(x));
            let additionalServices = items.pricings.filter(x => x.serviceType === ServiceType.AdditionalService);

            items.standardServices = standardServices;
            items.additionalServices = additionalServices;
        }
        return items;
    }
    
    addCustomFields = (items = null) => {
        if(items && items.steps && items.steps.length > 0){
            for(let i = 0; i < items.steps.length; i++){
                if(items.steps[i] && items.steps[i].jobStepWeighChitCustomFields && items.steps[i].jobStepWeighChitCustomFields.length > 0){
                    items['jobStepWeighChitCustomFields'] = items.steps[i].jobStepWeighChitCustomFields;
                }
            }
        }
        return items;
    }
    
    addPhotos = (items = null) => {
        if(items && items.attempts && items.attempts.length > 0){
            for(let i = 0; i < items.attempts.length; i++){
                if(items.attempts[i] && items.attempts[i].jobPhotos && items.attempts[i].jobPhotos.length > 0){
                    items['jobPhotos'] = items.attempts[i].jobPhotos.slice(0, 4);
                }
            }
        }
        return items;
    }

    findDiff = (field = null, arr = null) => {
        let obj = null;
        let diff = (arr) ? arr : this.state.diff;
        
        if(field && diff && diff.length > 0){
            let item = diff.filter(x => x.path === field);
            obj = (item && item.length > 0) ? item[0] : null;
        }

        return obj;
    }
    classNameDiff = (item = null, prefix = '') => {
        let cls = '';

        if(item){
            if(item.type === 'A'){
                cls = 'highlight' + prefix + '-green';
            } else if(item.type === 'E'){
                cls = 'highlight' + prefix;
            } else if(item.type === 'D'){
                cls = 'highlight' + prefix + '-red';
            } else if(item.type === 'M'){
                cls = 'highlight' + prefix;
            }
        }

        return cls;
    }
    classNameDiffRow = (item = null) => {
        return this.classNameDiff(item, '-row');
    }
    getDiffValue = (field = null, obj = null, callback = null) => {
        let value = '';

        if(field && obj){
            try {
                let fieldValue = field.split('.').reduce((o, i) => o[i], obj);
                value = (callback) ? callback(fieldValue) : fieldValue;
            } catch(e){}
        }

        return value;
    }
    getPreviousValue = (item = null, callback = null) => {
        let value = '';

        let fieldValue = (item && item.lhs && item.lhs !== '') ? item.lhs : null;
        value = (callback) ? callback(fieldValue) : fieldValue;

        return (value && value !== '') ? 'Previous value: ' + value : 'No previous value!';
    }
    getHighlightedItem = (item = null, field = null, callback = null, obj = null) => {
        let items = (obj) ? obj : this.state.item;
        
        let cls = this.classNameDiff(item);
        let value = this.getDiffValue(field, items, callback);
        let previousValue = this.getPreviousValue(item, callback);

        if(item){
            return <Tooltip placement="top" title={previousValue}>
                <span className={cls}>{value}</span>
            </Tooltip>
        } else {
            return <>{value}</>
        }
    }
    highlight = (field = null, callback = null, obj = null) => {
        let item = this.findDiff(field, obj);
        return this.getHighlightedItem(item, field, callback, obj);
    }
    highlightRow = (field = null, obj = null) => {
        let item = this.findDiff(field, obj);
        let cls = this.classNameDiffRow(item);
        return cls;
    }
    
    getBinINOUT = () => {
        let item = null;
        let jobType = Utils.getJobTypeInfoByText(this.state.item.jobTypeName);
        let stepsStruct = Utils.getStepStruct(jobType);
        if(stepsStruct && stepsStruct.length > 0){
          let step = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_OUT);
          if(step !== -1){
            if(this.state.item.steps && this.state.item.steps[step]){
              let itemObj = this.state.item.steps[step];

              let binTypeName = (itemObj && itemObj.binTypeName) ? itemObj.binTypeName : '';
              let wasteTypeName = (itemObj && itemObj.wasteTypeName) ? itemObj.wasteTypeName : '';
              let wasteTypes = (itemObj && itemObj.wasteTypes) ? itemObj.wasteTypes : null;
              let binNumber = (itemObj && itemObj.binNumber) ? itemObj.binNumber : '';
              let binWeight = (itemObj && itemObj.binWeight) ? itemObj.binWeight : '';
              let binOutWeight = (itemObj && itemObj.binOutWeight) ? itemObj.binOutWeight : '';

              item = {
                index: step,
                binTypeName: binTypeName,
                wasteTypeName: wasteTypeName,
                wasteTypes: wasteTypes,
                binNumber: binNumber,
                binWeight: binWeight,
                binOutWeight: binOutWeight,
              }
            }
          }
        }
    
        if(jobType === JobStepType.OnTheSpot) {
          item = null;
        }
    
        return item;
    }
    getBinIN = () => {
        let item = null;
        let jobType = Utils.getJobTypeInfoByText(this.state.item.jobTypeName);
        let stepsStruct = Utils.getStepStruct(jobType);
        if(stepsStruct && stepsStruct.length > 0){
          let step = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_IN || x.stepTypeSave === StepType.SHIFT_IN);
          if(step !== -1){
            if(this.state.item.steps && this.state.item.steps[step]){
              let itemObj = this.state.item.steps[step];

              let binTypeName = (itemObj && itemObj.binTypeName) ? itemObj.binTypeName : '';
              let wasteTypeName = (itemObj && itemObj.wasteTypeName) ? itemObj.wasteTypeName : '';
              let wasteTypes = (itemObj && itemObj.wasteTypes) ? itemObj.wasteTypes : null;
              let binNumber = (itemObj && itemObj.binNumber) ? itemObj.binNumber : '';
              let binWeight = (itemObj && itemObj.binWeight) ? itemObj.binWeight : '';
              let binOutWeight = (itemObj && itemObj.binOutWeight) ? itemObj.binOutWeight : '';

              item = {
                index: step,
                binTypeName: binTypeName,
                wasteTypeName: wasteTypeName,
                wasteTypes: wasteTypes,
                binNumber: binNumber,
                binWeight: binWeight,
                binOutWeight: binOutWeight,
              }
            }
          }
        }
    
        if(jobType === JobStepType.OnTheSpot) {
          item = null;
        }
    
        return item;
    }
    getBinINBinTypeName = () => {
        if(this.state.item.jobTypeName === 'Put' || this.state.item.jobTypeName === 'Out'){
            // let item = this.getBinOUT();
            // let index = (item && item.index >= 0) ? item.index : -1;

            // return this.highlight('steps.' + index + '.binTypeName');
            return '';
        } else {
            let item = this.getBinIN();
            let index = (item && item.index >= 0) ? item.index : -1;

            return this.highlight('steps.' + index + '.binTypeName');
        }
    }
    getBinINWasteTypeName = () => {
        if(this.state.item.jobTypeName === 'Put' || this.state.item.jobTypeName === 'Out'){
            // let item = this.getBinOUT();
            // let index = (item && item.index >= 0) ? item.index : -1;

            // if(item && item.wasteTypes && item.wasteTypes.length > 0){
            //     return this.highlight('steps.' + index + '.wts');
            // } else {
            //     return this.highlight('steps.' + index + '.wasteTypeName');
            // }
            return '';
        } else {
            let item = this.getBinIN();
            let index = (item && item.index >= 0) ? item.index : -1;
            
            if(item && item.wasteTypes && item.wasteTypes.length > 0){
                return this.highlight('steps.' + index + '.wts');
            } else {
                return this.highlight('steps.' + index + '.wasteTypeName');
            }
        }
    }
    getBinINBinNumber = () => {
        if(this.state.item.jobTypeName === 'Put' || this.state.item.jobTypeName === 'Out'){
            // let item = this.getBinOUT();
            // let index = (item && item.index >= 0) ? item.index : -1;

            // return this.highlight('steps.' + index + '.binNumber');
            return '';
        } else {
            let item = this.getBinIN();
            let index = (item && item.index >= 0) ? item.index : -1;

            return this.highlight('steps.' + index + '.binNumber');
        }
    }
    getBinINBinNumberQR = () => {
        let item = this.getBinIN();
        let index = (item && item.index >= 0) ? item.index : -1;
        
        return <span className={'ms-2'}>
            {this.highlight('steps.' + index + '.isUsingQr', (val) => {
                return (val === true) ? '[QR]' : '';
            })}
        </span>;
    }
    getBinINBinNumberQRTextScan = () => {
        let item = this.getBinIN();
        let index = (item && item.index >= 0) ? item.index : -1;
        
        return <span className={'ms-2'}>
            {this.highlight('steps.' + index + '.isUsingTextDetection', (val) => {
                return (val === true) ? '[TR]' : '';
            })}
        </span>;
    }
    getBinINBinWeight = () => {
        if(this.state.item.jobTypeName === 'Put'){
            // let item = this.getBinOUT();
            // let index = (item && item.index >= 0) ? item.index : -1;

            // return this.highlight('steps.' + index + '.binWeight');
            return '';
        } else {
            let item = this.getBinIN();
            let index = (item && item.index >= 0) ? item.index : -1;

            return this.highlight('steps.' + index + '.binWeight', (val) => {
                return (Utils.getUOM() == ReportUOM.kg) ? Utils.formatNumber(val, '0,0') : Utils.formatNumber(val, '0,0.000')
            });
        }
    }
    getBinINBinWeightDisposed = () => {
        if(this.state.item.jobTypeName === 'Out'){
            let item = this.getBinINOUT();
            let index = (item && item.index >= 0) ? item.index : -1;

            return this.highlight('steps.' + index + '.binWeight', (val) => {
                return (Utils.getUOM() == ReportUOM.kg) ? Utils.formatNumber(val, '0,0') : Utils.formatNumber(val, '0,0.000')
            });
        }
    }
    getBinINBinOUTWeight = () => {
        if(this.state.item.jobTypeName === 'Out'){
            let item = this.getBinINOUT();
            let index = (item && item.index >= 0) ? item.index : -1;

            return this.highlight('steps.' + index + '.binOutWeight', (val) => {
                return (Utils.getUOM() == ReportUOM.kg) ? Utils.formatNumber(val, '0,0') : Utils.formatNumber(val, '0,0.000')
            });
        }
    }

    getBinOUT = () => {
        let item = null;
        
        let jobType = Utils.getJobTypeInfoByText(this.state.item.jobTypeName);
        let stepsStruct = Utils.getStepStruct(jobType);
        if(stepsStruct && stepsStruct.length > 0){
            let step = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_OUT || x.stepTypeSave === StepType.SHIFT_OUT);
            if(step !== -1){
                if(this.state.item.steps && this.state.item.steps[step]){
                    let itemObj = this.state.item.steps[step];
                    
                    let binTypeName = (itemObj && itemObj.binTypeName) ? itemObj.binTypeName : '';
                    let wasteTypeName = (itemObj && itemObj.wasteTypeName) ? itemObj.wasteTypeName : '';
                    let wasteTypes = (itemObj && itemObj.wasteTypes) ? itemObj.wasteTypes : null;
                    let binNumber = (itemObj && itemObj.binNumber) ? itemObj.binNumber : '';

                    item = {
                        index: step,
                        binTypeName: binTypeName,
                        wasteTypeName: wasteTypeName,
                        wasteTypes: wasteTypes,
                        binNumber: binNumber,
                    }
                }
            }
        }
    
        return item;
    }
    getBinOUTBinTypeName = () => {
        let item = this.getBinOUT();
        let index = (item && item.index >= 0) ? item.index : -1;
        
        return this.highlight('steps.' + index + '.binTypeName');
    }
    getBinOUTWasteTypeName = () => {
        let item = this.getBinOUT();
        let index = (item && item.index >= 0) ? item.index : -1;
        
        if(item && item.wasteTypes && item.wasteTypes.length > 0){
            return this.highlight('steps.' + index + '.wts');
        } else {
            return this.highlight('steps.' + index + '.wasteTypeName');
        }
    }
    getBinOUTBinNumber = () => {
        let item = this.getBinOUT();
        let index = (item && item.index >= 0) ? item.index : -1;
        
        return this.highlight('steps.' + index + '.binNumber');
    }
    getBinOUTBinNumberQR = () => {
        let item = this.getBinOUT();
        let index = (item && item.index >= 0) ? item.index : -1;
        
        return <span className={'ms-2'}>
            {this.highlight('steps.' + index + '.isUsingQr', (val) => {
                return (val === true) ? '[QR]' : '';
            })}
        </span>;
    }
    getBinOUTBinNumberQRTextScan = () => {
        let item = this.getBinOUT();
        let index = (item && item.index >= 0) ? item.index : -1;
        
        return <span className={'ms-2'}>
            {this.highlight('steps.' + index + '.isUsingTextDetection', (val) => {
                return (val === true) ? '[TR]' : '';
            })}
        </span>;
    }

    getAmountToCollect = () => {
        let step = -1;
        let jobType = Utils.getJobTypeInfoByText(this.state.item.jobTypeName);
        let stepsStruct = Utils.getStepStruct(jobType);
        if(stepsStruct && stepsStruct.length > 0){
            if(this.state.item.jobTypeName === 'Put'){
                step = stepsStruct.findIndex(x => x.binActivity === JobStepBinActivity.PutBin);
            } else {
                step = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_IN || x.stepTypeSave === StepType.SHIFT_IN);
            }
        }

        return this.highlight('steps.' + step + '.amountToCollect', (val) => {
            return Utils.formatNumber(val);
        });
    }

    getPaymentType = () => {
        let step = -1;
        let jobType = Utils.getJobTypeInfoByText(this.state.item.jobTypeName);
        let stepsStruct = Utils.getStepStruct(jobType);
        if(stepsStruct && stepsStruct.length > 0){
            if(this.state.item.jobTypeName === 'Put'){
                step = stepsStruct.findIndex(x => x.binActivity === JobStepBinActivity.PutBin);
            } else {
                step = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_IN || x.stepTypeSave === StepType.SHIFT_IN);
            }
        }
        
        return this.highlight('steps.' + step + '.jobPaymentType', (value) => {
            let jobPaymentTypeText = '';

            if(value === JobPaymentType.Other){
                jobPaymentTypeText = "Others";
            } else if(value === JobPaymentType.Transfer){
                jobPaymentTypeText = "Transfer";
            } else if(value === JobPaymentType.Cheque){
                jobPaymentTypeText = "Cheque";
            } else {
                jobPaymentTypeText = "Cash";
            }

            return jobPaymentTypeText;
        });
    }

    getFullAddress = () => {
        let step = -1;
        // let jobType = Utils.getJobTypeInfoByText(this.state.item.jobTypeName);
        // let stepsStruct = Utils.getStepStruct(jobType);

        // if(this.state.item.jobTypeName === 'Put' || this.state.item.jobTypeName === 'Out'){
        //     if(stepsStruct && stepsStruct.length > 0){
        //         step = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_OUT);
        //     }
        // } else {
        //     if(stepsStruct && stepsStruct.length > 0){
        //         step = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_IN || x.stepTypeSave === StepType.SHIFT_IN);
        //     }
        // }

        if(this.state.item && this.state.item.steps && this.state.item.steps.length > 0){
            step = this.state.item.steps.findIndex(x => x.customerSiteId > 0);
        }

        return this.highlight('steps.' + step + '.fullAddress');
    }

    getWeighChitStepIndex = () => {
        if(this.state.item){
            if(this.state.item.jobTypeName == 'Exchange' || this.state.item.jobTypeName == 'On The Spot'){
                return 1;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }


    getStandardService = (items = null) => { 
        return ((items) ? items : ((this.state.item && this.state.item.standardServices && this.state.item.standardServices.length > 0) ? this.state.item.standardServices : []));
    }
    getAdditionalService = (items = null) => { 
        return ((items) ? items : ((this.state.item && this.state.item.additionalServices && this.state.item.additionalServices.length > 0) ? this.state.item.additionalServices : []));
    }
    
    getCustomFields = () => { 
        return (this.state.item && this.state.item.jobStepWeighChitCustomFields && this.state.item.jobStepWeighChitCustomFields.length > 0) ? this.state.item.jobStepWeighChitCustomFields : [];
    }
    
    getPhotos = () => { 
        return (this.state.item && this.state.item.jobPhotos && this.state.item.jobPhotos.length > 0) ? this.state.item.jobPhotos : [];
    }

    setPhotos = () => {
        return <>
            <Box pb={1}>
                <Grid container>
                    <Box clone>
                        <Grid item xs={12}>
                            <b className={'text-color-secondary'}>PHOTOS</b>
                        </Grid>
                    </Box>
                </Grid>
            </Box>

            {
            (this.getPhotos().length > 0)
            ?
            <Box pb={1}>
                {this.getPhotos().map((item, i) => {
                    // let oldPhoto = this.getPhotos().find(x => x.photoUrl == item.photoUrl);
                    // let cls = this.highlightRow('jobPhotos') + ' ' + this.highlightRow('jobPhotos.' + i.toString()) + ' ' + this.highlightRow('jobPhotos.' + i + '.photoUrl');

                    // return <div key={i} className={'d-inline-block ps-2 pe-2' + cls}>
                    return <div key={i} className={'d-inline-block ps-2 pe-2'}>
                        <img src={item.photoUrl} alt={'Job'} style={{ objectFit: 'cover', width: '150px', height: '150px' }} className={'img-thumbnail'}></img>
                        {/* {
                            (oldPhoto && cls.includes('highlight-row'))
                            ?
                            <CustomPopover title={<img src={oldPhoto.photoUrl} alt={'Job'} style={{ objectFit: 'cover', width: '150px', height: '150px' }} className={'img-thumbnail'}></img>}>
                                <img src={item.photoUrl} alt={'Job'} style={{ objectFit: 'cover', width: '150px', height: '150px' }} className={'img-thumbnail'}></img>
                            </CustomPopover>
                            :
                            <img src={item.photoUrl} alt={'Job'} style={{ objectFit: 'cover', width: '150px', height: '150px' }} className={'img-thumbnail'}></img>
                        } */}
                    </div>
                })}
            </Box>
            :
            <Box pb={1}>No photos</Box>
            }
        </>
    }

    isOutJobType = () => {
        let jobType = Utils.getJobTypeInfoByText(this.state.item.jobTypeName);
        return (jobType == JobStepType.Out) ? true : false;
    }


    setForm = () => {
        return <Grid container>

            <Box clone>
                <Grid item xs={12}>
                
                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Customer Name
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('customerName')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Customer Site
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.getFullAddress()}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Job Type
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('jobTypeName')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Scheduled Date
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('jobDate', (value) => (value) ? moment(value).format(Utils.getDefaultDateFormat()) : '' )}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Scheduled Time
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('jobTimeSpecific', (value) => (value) ? moment(value).format(Utils.getDefaultTimeFormat()) : '' )}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Completed Date
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('jobAttemptCompletedDate', (value) => (value) ? moment(value).format(Utils.getDefaultDateFormat()) : '' )}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Completed Time
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('jobAttemptCompletedDate', (value) => (value) ? moment(value).format(Utils.getDefaultTimeFormat()) : '' )}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Status
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('status.jobStatusName')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                    
                    {
                        (this.state.item.status.jobStatusName == 'Completed')
                        ?
                        <Box pb={1}>
                            <Grid container>
                                <Box clone>
                                    <Grid item xs={12} md={3}>
                                    Billing Status
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={12} md={9}>
                                    {this.highlight('billingStatus')}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>                     
                        : <div></div>
                    }
                    
                    {
                        (this.state.item.billingStatus != 'Unbilled' && this.state.item.invoiceNumber)
                        ?
                        <Box pb={1}>
                            <Grid container>
                                <Box clone>
                                    <Grid item xs={12} md={3}>
                                    Invoice Number
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={12} md={9}>
                                    {this.highlight('invoiceNumber')}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>                     
                        : <div></div>
                    }

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Remarks
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('remarks')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Driver
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('driverName')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Vehicle
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('vehicleName')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Job Number
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('jobNumber')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Bin Out
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.getBinOUTBinTypeName()}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Bin ID
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.getBinOUTBinNumber()} {this.getBinOUTBinNumberQR()}{this.getBinOUTBinNumberQRTextScan()}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Waste Out
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.getBinOUTWasteTypeName()}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Bin In
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.getBinINBinTypeName()}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Bin ID
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.getBinINBinNumber()} {this.getBinINBinNumberQR()}{this.getBinINBinNumberQRTextScan()}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Waste In 
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.getBinINWasteTypeName()}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    {
                        this.isOutJobType() 
                        ?
                        <>
                            <Box pb={1}>
                                <Grid container>
                                    <Box clone>
                                        <Grid item xs={12} md={3}>
                                        Bin Weight
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={9}>
                                        {this.getBinINBinOUTWeight()}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box pb={1}>
                                <Grid container>
                                    <Box clone>
                                        <Grid item xs={12} md={3}>
                                        Bin Weight Disposed
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={9}>
                                        {this.getBinINBinWeightDisposed()}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </>
                        :
                        <Box pb={1}>
                            <Grid container>
                                <Box clone>
                                    <Grid item xs={12} md={3}>
                                    Bin Weight
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={12} md={9}>
                                    {this.getBinINBinWeight()}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                    }

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Amount To Collect ({this.getPaymentType()})
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {Utils.getCurrency()}{this.getAmountToCollect()}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                </Grid>
            </Box>


            <Box clone pt={5}>
                <Grid item xs={12}>
                    {this.setPhotos()}
                </Grid>
            </Box>


            <Box clone pt={5}>
                <Grid item xs={12}>
                
                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12}>
                                    <b className={'text-color-secondary'}>WEIGH CHIT</b>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Ticket number
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('steps.' + this.getWeighChitStepIndex() + '.weightChitTicketNumber')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Gross weight
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('steps.' + this.getWeighChitStepIndex() + '.binWeight', (val) => {
                                    return (Utils.getUOM() == ReportUOM.kg) ? Utils.formatNumber(val, '0,0') : Utils.formatNumber(val, '0,0.000')
                                })}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Tare weight
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('steps.' + this.getWeighChitStepIndex() + '.tareBinWeight', (val) => {
                                    return (Utils.getUOM() == ReportUOM.kg) ? Utils.formatNumber(val, '0,0') : Utils.formatNumber(val, '0,0.000')
                                })}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Nett weight
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('steps.' + this.getWeighChitStepIndex() + '.netBinWeight', (val) => {
                                    return (Utils.getUOM() == ReportUOM.kg) ? Utils.formatNumber(val, '0,0') : Utils.formatNumber(val, '0,0.000')
                                })}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Non-billable weight
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('steps.' + this.getWeighChitStepIndex() + '.nonBillableBinWeight', (val) => {
                                    return (Utils.getUOM() == ReportUOM.kg) ? Utils.formatNumber(val, '0,0') : Utils.formatNumber(val, '0,0.000')
                                })}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Billable weight
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('steps.' + this.getWeighChitStepIndex() + '.billableBinWeight', (val) => {
                                    return (Utils.getUOM() == ReportUOM.kg) ? Utils.formatNumber(val, '0,0') : Utils.formatNumber(val, '0,0.000')
                                })}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Remarks
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('steps.' + this.getWeighChitStepIndex() + '.weightChitRemarks')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Weigh by
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('steps.' + this.getWeighChitStepIndex() + '.binWeighBy')}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    <Box pb={1}>
                        <Grid container>
                            <Box clone>
                                <Grid item xs={12} md={3}>
                                Weigh Timestamp
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={9}>
                                {this.highlight('steps.' + this.getWeighChitStepIndex() + '.weightChitDateTime', (value) => (value) ? moment(value).format("DD-MMM-YYYY hh:mm A") : '' )}
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>

                    {(this.getCustomFields().length > 0) && this.getCustomFields().map((item, i) => {
                        return <Box key={i} pb={1} className={this.highlightRow('jobStepWeighChitCustomFields') + ' ' + this.highlightRow('jobStepWeighChitCustomFields.' + i.toString())}>
                            <Grid container>
                                <Box clone>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Box clone>
                                                <Grid item xs={12} md={3}>
                                                {this.highlight('jobStepWeighChitCustomFields.' + i + '.accountCustomField.label')}
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={9}>
                                                {this.highlight('jobStepWeighChitCustomFields.' + i + '.value')}
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                    })}

                </Grid>
            </Box>


            <Box clone pt={10}>
                <Grid item xs={12}>

                    <Box pb={1}>
                        <Box pb={1}>
                            <Box pb={1}>
                                <b className={'text-color-secondary'}>STANDARD SERVICE</b>
                            </Box>
        
                            <Grid container>
                                <Box clone>
                                    <Grid item xs={12} md={'auto'}>
                                        <Box minWidth={50}>No.</Box>
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={12} md={2}>
                                        Service Name
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={12} md={true}>
                                        <Grid container>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Bin Type
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Waste Type
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Billing Category
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Qty
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Rate
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Price
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>

                        <Divider />
                        
                        {
                            (this.getStandardService().length > 0)
                            ?
                            this.getStandardService().map((item, i) => {
                                return <Box key={i} pb={1} className={this.highlightRow('standardServices.' + i.toString())}>
                                    <Grid container>
                                        <Box clone>
                                            <Grid item xs={12} md={'auto'}>
                                                <Box minWidth={50}>{(i+1)}</Box>
                                            </Grid>
                                        </Box>
                                        <Box clone>
                                            <Grid item xs={12} md={2}>
                                            {this.highlight('standardServices.' + i + '.serviceTag')}
                                            </Grid>
                                        </Box>
                                        <Box clone>
                                            <Grid item xs={12} md={true}>
                                                <Grid container>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {this.highlight('standardServices.' + i + '.binType.binTypeName')}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {this.highlight('standardServices.' + i + '.wasteType.wasteTypeName')}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {this.highlight('standardServices.' + i + '.chargeCategory.chargeCategoryName')}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {this.highlight('standardServices.' + i + '.quantity', (val) => {
                                                            return Utils.formatNumber(val, '0,0.000')
                                                        })}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {this.highlight('standardServices.' + i + '.price', (val) => {
                                                            return Utils.formatNumber(val);
                                                        })}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                            {Utils.formatNumber(Utils.parseFloat(item.quantity) * Utils.parseFloat(item.price))}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Box>
                            })
                            :
                            <Box pb={1}>
                                <Grid container>
                                    <Box clone textAlign={'center'}>
                                        <Grid item xs={12}>
                                        No Result!
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        }
                        
                    </Box>

                </Grid>
            </Box>

            <Box clone pt={10}>
                <Grid item xs={12}>
                    <Box pb={1}>
                        <b className={'text-color-secondary'}>ADDITIONAL SERVICE</b>
                    </Box>
        
                    <Box pb={1}>
                        <Box pb={1}>
                            <Grid container>
                                <Box clone>
                                    <Grid item xs={12} md={'auto'}>
                                        <Box minWidth={50}>No.</Box>
                                    </Grid>
                                </Box>
                                {/* <Box clone>
                                    <Grid item xs={12} md={2}>
                                        Bundle Service Name
                                    </Grid>
                                </Box> */}
                                <Box clone>
                                    <Grid item xs={12} md={true}>
                                        <Grid container>
                                            <Box clone>
                                                <Grid item xs={12} md={4}>
                                                Additional Services
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Product/Services
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Qty
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Rate
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Price
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
        
                        <Divider />

                        {
                            (this.getAdditionalService().length > 0)
                            ?
                            this.getAdditionalService().map((item, i) => {
                                return <Box key={i} pb={1} className={this.highlightRow('additionalServices.' + i.toString())}>
                                    <Grid container>
                                        <Box clone>
                                            <Grid item xs={12} md={'auto'}>
                                                <Box minWidth={50}>{(i+1)}</Box>
                                            </Grid>
                                        </Box>
                                        {/* <Box clone>
                                            <Grid item xs={12} md={2}>
                                            {this.highlight('standardServices.' + i + '.bundleServiceTag')}
                                            </Grid>
                                        </Box> */}
                                        <Box clone>
                                            <Grid item xs={12} md={true}>
                                                <Grid container>
                                                    <Box clone>
                                                        <Grid item xs={12} md={4}>
                                                        {this.highlight('additionalServices.' + i + '.serviceTag')}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {this.highlight('additionalServices.' + i + '.chargeCategory.chargeCategoryName')}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {this.highlight('additionalServices.' + i + '.quantity', (val) => {
                                                            return Utils.formatNumber(val, '0,0.000')
                                                        })}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {this.highlight('additionalServices.' + i + '.price', (val) => {
                                                            return Utils.formatNumber(val);
                                                        })}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                            {Utils.formatNumber((Utils.parseFloat(item.quantity) * Utils.parseFloat(item.price)))}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Box>
                            })
                            :
                            <Box pb={1}>
                                <Grid container>
                                    <Box clone textAlign={'center'}>
                                        <Grid item xs={12}>
                                        No Result!
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        }
        
                    </Box>

                </Grid>
            </Box>

            
        </Grid>
    }


    render() {
        return <Box p={1} style={{ backgroundColor: 'ghostwhite' }}>
            <Box pb={2}>
                <small>The difference between the current and previous item in the history list</small>
            </Box>

            {this.setForm()}
        </Box>
    }
}

export default HistoryDetails;
