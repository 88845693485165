import React from "react";
// import _ from "lodash";

import { 
    Row,
    Col,
    // Dropdown,
    // ButtonGroup,
    // Spinner,
    // Button,
} from "react-bootstrap-v5";

import Utils from "../../../../utils/utils";
// import { CustomerSiteAvailability } from '../../../../utils/enums';

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


class HeaderSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_header_section';

        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        
        this.state = {
            id: id,
            row: row,
        };
    }


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        // let timeAvailability = CustomerSiteAvailability.All;
        // let isWeekend = Utils.isWeekend(Utils.weekDayNumb(this.props.formOptions));
        // if(isWeekend){
        //     timeAvailability = values.customFormParams.weekendAvailability;
        // } else {
        //     timeAvailability = values.customFormParams.weekdaysAvailability;
        // }

        return <Row className={'align-items-center'}>
            {
                values.isEdit
                ?
                <Col xs={true} sm={true} className={'pb-2 pb-sm-0'}>
                    <h3 className={'mt-1 mb-0'}>{values.customFormParams.jobNumber}</h3>
                </Col>
                :
                <Col xs={true}></Col>
            }

            {/* <Col xs={true}>
                <Button
                    variant={'outline-secondary'}
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                    }}
                >
                    Single Job
                </Button>
                <Button
                    className={'ms-2'}
                    variant={'light'}
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                    }}
                >
                    Route Jobs
                </Button>
            </Col>
            <Col xs={'auto'}>
                <h3 className={'mt-1 mb-0'}>WP-034-220989</h3>
            </Col> */}

            {/* <Col xs={'auto'}>
                <a href={'/'}
                    className={'link-secondary d-flex align-items-center'}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                    }}
                >
                    <span className={'material-icons pe-2'}>help_outline</span>
                    <span>Help</span>
                </a>
            </Col> */}
            
            {/* {values.isActive && <Col xs={true} sm={true} className={'header-save-button'}>
                <Dropdown 
                    as={ButtonGroup}
                    onSelect={(eventKey, e) => {
                        validateForm().then((err) => {
                            if(_.isEmpty(err)){
                                if(this.props.onSave){
                                    this.props.onSave(eventKey, values);
                                }
                            } else {
                                let msg = 'You must fill in the required fields in form and in the billing side panel';
                                let keys = Object.keys(err);
                                if(keys && keys.length > 0){
                                    msg = err[keys[0]]
                                }
                                Utils.toast(msg, 'error');
                            }
                        })
                    }}
                >
                    <Button 
                        variant={'primary'}
                        disabled={this.props.isLoading || (timeAvailability == CustomerSiteAvailability.NotAvailable)}
                        onClick={(e) => {
                            validateForm().then((err) => {
                                if(_.isEmpty(err)){
                                    if(this.props.onSave){
                                        this.props.onSave(0, values);
                                    }
                                } else {
                                    let msg = 'You must fill in the required fields in form and in the billing side panel';
                                    let keys = Object.keys(err);
                                    if(keys && keys.length > 0){
                                        msg = err[keys[0]]
                                    }
                                    Utils.toast(msg, 'error');
                                }
                            })
                        }}
                    >
                        SAVE & CLOSE
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                    <Dropdown.Toggle
                        variant={'primary'}
                        disabled={this.props.isLoading || (timeAvailability == CustomerSiteAvailability.NotAvailable)}
                    />
                    <Dropdown.Menu variant={'primary'}>
                        <Dropdown.Item eventKey={1} active={false}>SAVE & NEW</Dropdown.Item>
                        <Dropdown.Item eventKey={2} active={false}>SAVE ONLY</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>} */}

            {/* <Col xs={true}></Col> */}
            <Col xs={'auto'}>
                <a href={'/'}
                    className={'link-secondary d-flex align-items-center'}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        this.props.history.push('/jobs');
                    }}
                >
                    <span className={'material-icons'}>close</span>
                </a>
            </Col>
        </Row>
    }
    

    render() {
        return this.body()
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(HeaderSection);
