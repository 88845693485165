import React, { Component } from 'react';

import { 
  Row,
  Col,
  Modal,
  Button,
  CloseButton,
  Spinner,
} from "react-bootstrap-v5";

import { connect } from 'react-redux'
import driverTrackingSlice from '../redux/driverTrackingSlice'

import AuditTrailJobFormGoogleMap from '../../../components/OLD/GoogleMap/AuditTrailJobFormGoogleMap';

import Utils from "../../../utils/utils";


class AuditTrailJobRouteDialog extends Component {
  
  constructor(props){
    super(props);

    let item = (props && props.item) ? props.item : null;
    
    this.state = {
      item: item,
    }
  }

  componentDidMount(){
    this.props.action.driverTrackingSlice.callJobTrackingApi(this.state.item?.jobId, (state: Boolean, data: any) => {});
  }


  header = () => {
    return <Modal.Header>
      <Row className={'align-items-center gx-0 w-100'}>
        <Col xs={true}>
          <Modal.Title>
            <div>{this.state.item?.time}</div>
            <small>{this.state.item.contentDesc}</small>
          </Modal.Title>
        </Col>
        <Col xs={'auto'}>
          <CloseButton
            className={'rounded-2'}
            onClick={() => {
              if(this.props.onHide){
                this.props.onHide();
              }
            }}
          />
        </Col>
      </Row>
    </Modal.Header>
  }

  body = () => {
    return <Modal.Body>
      {
        !this.props.driverTrackingSlice.isLoading
        ?
        <div style={{
            width: '100%',
            height: '400px',
          }}
        >
          <AuditTrailJobFormGoogleMap item={this.state.item?.item} driverLocation={this.props.driverTrackingSlice.items} />
        </div>
        :
        <div className={'text-center'}>
          <Spinner animation="border" />
        </div>
      }
    </Modal.Body>
  }

  footer = () => {
    return <Modal.Footer>
        <Row className={'w-100'}>
            <Col xs={12} className='text-end'>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                      if(this.props.onHide){
                        this.props.onHide();
                      }
                    }}
                >
                  CANCEL
                </Button>
            </Col>
        </Row>
    </Modal.Footer>
  }

  
  render() {
    return <>
      {this.header()}
      {this.body()}
      {this.footer()}
    </>
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  action: {
    driverTrackingSlice: {
      callJobTrackingApi: (payload, callback) => dispatch(driverTrackingSlice.callJobTrackingApi(payload, callback)),
    },
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AuditTrailJobRouteDialog);
