import React from "react";
import { City, Country  }  from 'country-state-city';
import currencies from 'iso-currencies';
import _ from 'lodash';
 
import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
    Modal,
} from "react-bootstrap-v5";
import { getCountryCallingCode } from 'react-phone-number-input';

import { DrawerContentLayout } from '../../../components/drawer/DrawerLayout'
import Utils from "../../../utils/utils";
import { ReportUOM, YesNo } from "../../../utils/enums";

import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
import CenterAndZoomGoogleMap from '../../../components/OLD/GoogleMap/CenterAndZoomGoogleMap';

import targetIcon from "../../../../_metronic/assets/img/icons/target.svg";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


class GeneralForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        this.timezoneItems = Utils.timeZoneList();
        this.allCities = City.getAllCities();
        this.allCities = _.uniqBy(this.allCities, (x) => x.name);
        
        let currencyList = currencies.list();

        let currencyItems = [];
        if(currencyList){
            const objectArray = Object.entries(currencyList);
            objectArray.forEach(([key, value]) => {
                let symbol = (value && value.symbol && value.symbol != '') ? value.symbol : key;

                let item = {
                    value: key,
                    title: symbol + ' (' + key + ' - '+ value.name + ')',
                    symbol: (value && value.symbol && value.symbol != '') ? value.symbol : '',
                    item: value
                };

                currencyItems = Utils.addToArrayIfNotExist(currencyItems, key, item);
            });
        }
        

        this.state = {
            id: id,
            row: row,

            currencyItems: currencyItems,
            
            isMapDialog: false,
            itemMapSettingsZoomDialog: null,
            itemMapSettingsLatDialog: null,
            itemMapSettingsLngDialog: null,
        };
    }


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row>
            
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.defaultUoM.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.defaultUoM}

                        id={this.props.fields.defaultUoM.id}
                        placeholder={this.props.fields.defaultUoM.placeholder}
                        items={[
                            {
                                value: ReportUOM.tons,
                                title: 'Tons',
                            },
                            {
                                value: ReportUOM.kg,
                                title: 'Kilograms (kg)',
                            }
                        ]}
                        value={values.defaultUoM}
                        selectedName={(values.defaultUoM === ReportUOM.kg) ? 'Kilograms (kg)' : 'Tons'}
                        isLoading={false}
                        searchable={false}
                        cleanable={true}
                        isDefault={true}
                        onSelect={async (value, item) => {
                            await setFieldValue('defaultUoM', value);
                        }}
                        onClear={async () => {
                            await setFieldValue('defaultUoM', '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.defaultUoM}</Form.Control.Feedback>
                    {(values.defaultUoM != Utils.getUOM()) && <b className={'text-warning'}>Warning! Your past jobs weigh input will keep its value as previous weigh metrics.</b>}
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.defaultBillingUoM.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.defaultBillingUoM}

                        id={this.props.fields.defaultBillingUoM.id}
                        placeholder={this.props.fields.defaultBillingUoM.placeholder}
                        items={[
                            {
                                value: ReportUOM.tons,
                                title: 'Tons',
                            },
                            {
                                value: ReportUOM.kg,
                                title: 'Kilograms (kg)',
                            }
                        ]}
                        value={values.defaultBillingUoM}
                        selectedName={(values.defaultBillingUoM === ReportUOM.kg) ? 'Kilograms (kg)' : 'Tons'}
                        isLoading={false}
                        searchable={false}
                        cleanable={true}
                        isDefault={true}
                        disabled={true}
                        onSelect={async (value, item) => {
                            await setFieldValue('defaultBillingUoM', value);
                        }}
                        onClear={async () => {
                            await setFieldValue('defaultBillingUoM', '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.defaultBillingUoM}</Form.Control.Feedback>
                    {(values.defaultBillingUoM != Utils.getBillingUOM()) && <b className={'text-warning'}>Warning! Your past jobs weigh input will keep its value as previous weigh metrics.</b>}
                </Form.Group>
            </Col>
            
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.mapSettingsId.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.mapSettingsId}

                        id={this.props.fields.mapSettingsId.id}
                        placeholder={this.props.fields.mapSettingsId.placeholder}
                        items={this.allCities}
                        value={(values.mapSettingsName && values.mapSettingsName != '') ? values.mapSettingsName : null}
                        selectedName={values.mapSettingsName}
                        isLoading={false}
                        searchable={true}
                        cleanable={true}
                        virtualized={true}
                        isDefault={true}
                        labelKey={'name'}
                        valueKey={'name'}
                        onSelect={async (value, item) => {
                            await setFieldValue('mapSettingsId', item.countryCode);
                            await setFieldValue('mapSettingsName', item.name);
                            await setFieldValue('mapSettingsLat', item.latitude);
                            await setFieldValue('mapSettingsLng', item.longitude);

                            let country = Country.getCountryByCode(item.countryCode)
                            if(country){
                                let gmtOffset = 0;
                                if(country.timezones && country.timezones.length > 0 && country.timezones[0] && country.timezones[0].gmtOffset){
                                    gmtOffset = country.timezones[0].gmtOffset / 60;
                                    let gmtItem = this.timezoneItems.find(x => x.value == gmtOffset);
                                    if(Utils.isNumber(gmtItem.value)){
                                        await setFieldValue('timezoneUtcOffset', gmtItem.value);
                                    }
                                }
                                let key = country.currency

                                let obj = currencies.information(key);
                                let symbol = (obj && obj.symbol && obj.symbol != '') ? obj.symbol : '';

                                await setFieldValue('currencyCodeValue', key);
                                await setFieldValue('currencyCode', symbol)
                            };
                        }}
                        onClear={async () => {
                            await setFieldValue('mapSettingsId', '');
                            await setFieldValue('mapSettingsName', '');
                            await setFieldValue('mapSettingsLat', '');
                            await setFieldValue('mapSettingsLng', '');
                            
                            await setFieldValue('currencyCodeValue', '');
                            await setFieldValue('currencyCode', '');
                            await setFieldValue('timezoneUtcOffset', '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.mapSettingsId}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            {(values.mapSettingsId && values.mapSettingsId != '') && <Col xs={12}>
                <a href={'/'}
                    className={'link-main'}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        let mapSettingsLat = (values.mapSettingsLat && values.mapSettingsLat !== "") ? parseFloat(values.mapSettingsLat) : null;
                        let mapSettingsLng = (values.mapSettingsLng && values.mapSettingsLng !== "") ? parseFloat(values.mapSettingsLng) : null;
                        let mapSettingsZoom = (values.mapSettingsZoom && values.mapSettingsZoom != "") ? parseInt(values.mapSettingsZoom) : null;
                        
                        this.setState({
                            isMapDialog: true,
                            itemMapSettingsLatDialog: mapSettingsLat,
                            itemMapSettingsLngDialog: mapSettingsLng,
                            itemMapSettingsZoomDialog: mapSettingsZoom,
                        });
                    }}
                >Show map for more precise adjustment</a>
            </Col>}

            <Col xs={12}>
                <Form.Group className={'mt-5 mb-5'}>
                    <Form.Label>{this.props.fields.timezoneUtcOffset.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.timezoneUtcOffset}

                        id={this.props.fields.timezoneUtcOffset.id}
                        placeholder={this.props.fields.timezoneUtcOffset.placeholder}
                        items={this.timezoneItems}
                        value={values.timezoneUtcOffset}
                        selectedName={Utils.getTimeZoneNameByOffset(this.timezoneItems, values.timezoneUtcOffset)}
                        isLoading={false}
                        searchable={false}
                        cleanable={true}
                        isDefault={true}
                        onSelect={async (value, item) => {
                            await setFieldValue('timezoneUtcOffset', value);
                        }}
                        onClear={async () => {
                            await setFieldValue('timezoneUtcOffset', '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.timezoneUtcOffset}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12} className={'mb-5'}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.countryCode.label}</Form.Label>
                    <Form.Control
                        value={(values.mapSettingsName && values.mapSettingsName != '') ? '+' + getCountryCallingCode(Utils.getCountryCode(values.mapSettingsName)) : ''}
                        readOnly={true}
                    />
                    <Form.Control.Feedback type="invalid">{errors.countryCode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            {/* {(values.mapSettingsId && values.mapSettingsId != '') && <Col xs={12} style={{ minHeight: '280px' }}>
                <small>You can drag or zoom the map for more precise adjustment</small>
                <CenterAndZoomGoogleMap
                    targetIcon={{ url: targetIcon }}
                    defaultCenter={(values.mapSettingsLat !== "" && values.mapSettingsLng !== "") ? { lat: parseFloat(values.mapSettingsLat), lng: parseFloat(values.mapSettingsLng) } : null}
                    defaultZoom={(values.mapSettingsZoom != "") ? parseInt(values.mapSettingsZoom) : null}
                    onZoomChange={async (zoom, center) => {
                        await setFieldValue('mapSettingsZoom', zoom);
                        await setFieldValue('mapSettingsLat', center.lat());
                        await setFieldValue('mapSettingsLng', center.lng());
                    }}
                    onCenterChange={async (center, zoom) => {
                        await setFieldValue('mapSettingsLat', center.lat());
                        await setFieldValue('mapSettingsLng', center.lng());
                        await setFieldValue('mapSettingsZoom', zoom);
                    }}
                />
                <small>Center: {values.mapSettingsLat + ', ' + values.mapSettingsLng}</small><br />
                <small>Zoom: {values.mapSettingsZoom}</small>
            </Col>} */}

            <Col xs={12} className={'mb-5'}>
                <Form.Group>
                    <Form.Label>{this.props.fields.currencyCode.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.currencyCode}

                        id={this.props.fields.currencyCode.id}
                        placeholder={this.props.fields.currencyCode.placeholder}
                        items={this.state.currencyItems}
                        value={values.currencyCodeValue}
                        searchKey={'label'}
                        isLoading={false}
                        searchable={true}
                        cleanable={false}
                        virtualized={true}
                        isDefault={true}
                        onSelect={async (value, item) => {
                            await setFieldValue('currencyCodeValue', value);
                            await setFieldValue('currencyCode', item.symbol);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.currencyCode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12} className={'mb-5'}>
                <Form.Group>
                    <Form.Label>{this.props.fields.allowDriverMultipleJobs.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}

                        id={this.props.fields.allowDriverMultipleJobs.id}
                        placeholder={this.props.fields.allowDriverMultipleJobs.placeholder}
                        items={[
                            {
                                value: YesNo.NoValue,
                                title: YesNo.NoTitle,
                                check: false,
                            },
                            {
                                value: YesNo.YesValue,
                                title: YesNo.YesTitle,
                                check: true,
                            }
                        ]}
                        value={values.allowDriverMultipleJobs ? YesNo.YesValue : YesNo.NoValue}
                        selectedName={values.allowDriverMultipleJobs ? YesNo.YesTitle : YesNo.NoTitle}
                        readOnly={false}
                        isLoading={false}
                        searchable={false}
                        cleanable={false}
                        isDefault={true}
                        onSelect={async (value, itemObj) => {
                            await setFieldValue('allowDriverMultipleJobs', itemObj.check);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.allowDriverMultipleJobs}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            
            <Col xs={12} className={'mb-5'}>
                <Form.Group>
                    <Form.Label>{this.props.fields.showServiceItemNameInMobileApp.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}

                        id={this.props.fields.showServiceItemNameInMobileApp.id}
                        placeholder={this.props.fields.showServiceItemNameInMobileApp.placeholder}
                        items={[
                            {
                                value: YesNo.NoValue,
                                title: YesNo.NoTitle,
                                check: false,
                            },
                            {
                                value: YesNo.YesValue,
                                title: YesNo.YesTitle,
                                check: true,
                            }
                        ]}
                        value={values.showServiceItemNameInMobileApp ? YesNo.YesValue : YesNo.NoValue}
                        selectedName={values.showServiceItemNameInMobileApp ? YesNo.YesTitle : YesNo.NoTitle}
                        readOnly={false}
                        isLoading={false}
                        searchable={false}
                        cleanable={false}
                        isDefault={true}
                        onSelect={async (value, itemObj) => {
                            await setFieldValue('showServiceItemNameInMobileApp', itemObj.check);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.showServiceItemNameInMobileApp}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12} className={'mb-5'}>
                <Form.Group>
                    <Form.Label>{this.props.fields.allowDriverAddAdditionalService.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}

                        id={this.props.fields.allowDriverAddAdditionalService.id}
                        placeholder={this.props.fields.allowDriverAddAdditionalService.placeholder}
                        items={[
                            {
                                value: YesNo.NoValue,
                                title: YesNo.NoTitle,
                                check: false,
                            },
                            {
                                value: YesNo.YesValue,
                                title: YesNo.YesTitle,
                                check: true,
                            }
                        ]}
                        value={values.allowDriverAddAdditionalService ? YesNo.YesValue : YesNo.NoValue}
                        selectedName={values.allowDriverAddAdditionalService ? YesNo.YesTitle : YesNo.NoTitle}
                        readOnly={false}
                        isLoading={false}
                        searchable={false}
                        cleanable={false}
                        isDefault={true}
                        onSelect={async (value, itemObj) => {
                            await setFieldValue('allowDriverAddAdditionalService', itemObj.check);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.allowDriverAddAdditionalService}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{this.props.fields.showPricingValueInMobileApp.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}

                        id={this.props.fields.showPricingValueInMobileApp.id}
                        placeholder={this.props.fields.showPricingValueInMobileApp.placeholder}
                        items={[
                            {
                                value: YesNo.NoValue,
                                title: YesNo.NoTitle,
                                check: false,
                            },
                            {
                                value: YesNo.YesValue,
                                title: YesNo.YesTitle,
                                check: true,
                            }
                        ]}
                        value={values.showPricingValueInMobileApp ? YesNo.YesValue : YesNo.NoValue}
                        selectedName={values.showPricingValueInMobileApp ? YesNo.YesTitle : YesNo.NoTitle}
                        readOnly={false}
                        isLoading={false}
                        searchable={false}
                        cleanable={false}
                        isDefault={true}
                        onSelect={async (value, itemObj) => {
                            await setFieldValue('showPricingValueInMobileApp', itemObj.check);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.showPricingValueInMobileApp}</Form.Control.Feedback>
                </Form.Group>
            </Col>

        </Row>
    }
    
    footer = () => {
        let {
            validateForm,
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    SAVE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>

        </Row>
    }

    setMapDialog = () => {
        return <Modal
            show={this.state.isMapDialog}
            fullscreen={true}
            centered={true}
            backdrop={'static'}
            keyboard={false}
            onHide={() => {
                this.setState({
                    isMapDialog: false,
                    itemMapSettingsZoomDialog: null,
                    itemMapSettingsLatDialog: null,
                    itemMapSettingsLngDialog: null,
                });
            }}
        >
            <Modal.Header>
                <Row className={'w-100'}>
                    <Col xs={6} className={''}>
                        <Row>
                            <Col xs={12}>
                                <small>Zoom: {this.state.itemMapSettingsZoomDialog}</small>
                            </Col>
                            <Col xs={12}>
                                <small>Center: {this.state.itemMapSettingsLatDialog + ', ' + this.state.itemMapSettingsLngDialog}</small>
                            </Col>
                            <Col xs={12} className={'pt-2'}>
                                <small><b>You can drag the pin or zoom the map for more precise adjustment</b></small>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} className={'text-end p-0'}>
                        <Button 
                            className={'me-2'}
                            variant={'primary'}
                            onClick={async () => {
                                let {
                                    setFieldValue,
                                } = this.props.formOptions;
                                
                                let mapSettingsLat = this.state.itemMapSettingsLatDialog;
                                let mapSettingsLng = this.state.itemMapSettingsLngDialog;
                                let mapSettingsZoom = this.state.itemMapSettingsZoomDialog;

                                await setFieldValue('mapSettingsLat', mapSettingsLat);
                                await setFieldValue('mapSettingsLng', mapSettingsLng);
                                await setFieldValue('mapSettingsZoom', mapSettingsZoom);

                                this.setState({
                                    isMapDialog: false,
                                    itemMapSettingsZoomDialog: null,
                                    itemMapSettingsLatDialog: null,
                                    itemMapSettingsLngDialog: null,
                                });
                            }}
                        >
                            Ok
                        </Button>
                        <Button 
                            className={''}
                            variant={'secondary'}
                            onClick={() => {
                                this.setState({
                                    isMapDialog: false,
                                    itemMapSettingsZoomDialog: null,
                                    itemMapSettingsLatDialog: null,
                                    itemMapSettingsLngDialog: null,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body className={'p-0'}>
                <CenterAndZoomGoogleMap
                    targetIcon={{ url: targetIcon }}
                    defaultCenter={(this.state.itemMapSettingsLatDialog != "" && this.state.itemMapSettingsLngDialog != "") ? { lat: parseFloat(this.state.itemMapSettingsLatDialog), lng: parseFloat(this.state.itemMapSettingsLngDialog) } : null}
                    defaultZoom={(this.state.itemMapSettingsZoomDialog != "") ? parseInt(this.state.itemMapSettingsZoomDialog) : null}
                    onZoomChange={async (zoom, center) => {
                        this.setState({
                            itemMapSettingsZoomDialog: zoom,
                            itemMapSettingsLatDialog: center.lat(),
                            itemMapSettingsLngDialog: center.lng(),
                        });
                    }}
                    onCenterChange={async (center, zoom) => {
                        this.setState({
                            itemMapSettingsLatDialog: center.lat(),
                            itemMapSettingsLngDialog: center.lng(),
                            itemMapSettingsZoomDialog: zoom,
                        });
                    }}
                />
            </Modal.Body>
        </Modal>
    }


    render() {
        return <>
            <DrawerContentLayout
                ref={this.bodyRef}
                isBlocking={this.props.isBlocking}
                body={this.body()}
                footer={this.footer()}
            />
            {this.setMapDialog()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GeneralForm);
