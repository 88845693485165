import React, { Component } from 'react';
import queryString from 'query-string';

import Utils from '../../utils/utils';

import { 
    Box,
    Grid,
    Icon
} from '@material-ui/core';

import { connect } from 'react-redux'
import publicIframe from "./redux/publicIframe";

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarSlot } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


const workerUrl = 'https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js';

const PDFViewer = ({ fileUrl }) => {
    const defaultLayout = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => {
            return [];
        },
        renderToolbar: (Toolbar) => (
            <Toolbar>
                {(slots: ToolbarSlot) => (
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Grid item>
                            <Grid xs={12}>
                                <Box style={{ textAlign: 'center', fontSize: '30px' }}>
                                    <div style={{ display: 'inline-flex', width: '100%', alignItems: 'center', gap: '12px', textAlign: 'center' }}>
                                        {slots.ZoomOut && slots.ZoomOut()}
                                        {slots.ZoomIn && slots.ZoomIn()}
                                        {slots.Print && slots.Print()}
                                        {slots.Download && slots.Download()}
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Toolbar>
        ),
    });

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: '0',
                left: '0',
                zIndex: '99999',
            }}
        >
            <Worker workerUrl={workerUrl}>
                <Viewer fileUrl={fileUrl} plugins={[defaultLayout]} />
            </Worker>
        </div>
    );
};


class PublicIframe extends Component {
	
	constructor(props){
		super(props);

        let q = queryString.parse(window.location.search);
        let uuid = q.uuid ? q.uuid : "";
        let url = q.url ? q.url : "job/preview/" + uuid;
        let method = q.method ? q.method : "get";

		this.state = {
            uuid: uuid,
            url: url,
            method: method,
		}
	}


    componentDidMount() {
		this.props.action.publicIframe.callPreviewApi({ data: null, path: this.state.url, method: this.state.method });
    }


	render() {
        if(this.props.publicIframe.isLoading){
            return <Grid container justifyContent={'center'} alignItems={'center'} style={{ height: '100vh' }}>
                <Grid item>
                    <Grid xs={12}>
                        <Box style={{ textAlign: 'center', fontSize: '30px' }}>Please Wait</Box>
                        <Box textAlign={'center'}>
                            <Icon component={'i'} style={{ fontSize: '55px' }}>more_horiz</Icon>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        } else {
            if(this.props.publicIframe.url){
                return <PDFViewer fileUrl={this.props.publicIframe.url} />
            } else {
                return <Grid container justifyContent={'center'} alignItems={'center'} style={{ height: '100vh' }}>
                    <Grid item>
                        <Grid xs={12}>
                            <Box style={{ textAlign: 'center', fontSize: '30px' }}>{(this.props.publicIframe.error && this.props.publicIframe.error != '') ? this.props.publicIframe.error : 'Link Expired'}</Box>
                            <Box textAlign={'center'}>
                                <Icon component={'i'} style={{ fontSize: '55px' }}>error</Icon>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            }
        }
	}
}


const mapStateToProps = state => {
    return {
        ...state,
        ...Utils.mapStateToProps(state),
    };
};
  
const mapDispatchToProps = (dispatch) => ({
    action: {
        publicIframe: {
            callPreviewApi: (payload) => dispatch(publicIframe.callPreviewApi(payload)),
        },
    }
});
  
  
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PublicIframe);
