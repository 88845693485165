import React, { Component } from "react";
import * as yup from 'yup';
import _ from 'lodash';
import queryString from "query-string";
import h2p from 'html2plaintext';
import { EditorState } from 'draft-js';

import { 
  Row,
  Col,
  Button,
  Spinner,
  Dropdown,
  Form,
} from "react-bootstrap-v5";

import { Formik } from 'formik';

import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'
import RemoveDialog from '../../../components/dialog/RemoveDialog';
import AuditTrailDialog from "../../../components/OLD/Pages/AuditTrail/AuditTrailDialog.jsx";
import AuditTrailFunctions from "../../../components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx";

import ViewLayout from "../layout/ViewLayout";
import GeneralForm from "./GeneralForm";
import DeliveryOrderForm from "./DeliveryOrderForm";
import EmailSettingsForm from "./EmailSettingsForm";
import PrintedReceiptForm from "./PrintedReceiptForm";
import WeightChitForm from "./WeightChitForm";
import NotificationForm from "./NotificationForm";

import Utils from '../../../utils/utils'
import { QuickbookOrXero, AccountDocSettingType, AccountDocSettingVariables, AuditTrailObjectType, PagesPermissions, WeighChitLayout, XeroPaymentTermType } from '../../../utils/enums'

import { connect } from 'react-redux'
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'
import notifications from './redux/notifications';


const formFields = {
    billingProfileName: {
        id: 'billingProfileName',
        label: 'Company name',
        placeholder: ' ',
    },
    isActive: {
        id: 'isActive',
        label: 'Is active',
        placeholder: ' ',
    },
    isDefault: {
        id: 'isDefault',
        label: 'Is default profile',
        placeholder: ' ',
    },
    companyAddressPlainText: {
        id: 'companyAddressPlainText',
        label: 'Company address',
        placeholder: ' ',
    },
    companyLogo: {
        id: 'companyLogo',
        label: 'Company logo',
        placeholder: ' ',
        accept: '.jpeg,.jpg,.bmp,.gif,.png',
    },
    quickbookOrXero: {
        id: 'quickbookOrXero',
        label: 'Connect to',
        placeholder: ' ',
    },
    taxRateId: {
        id: 'taxRateId',
        label: 'Default tax rate',
        placeholder: ' ',
    },
    xeroDefaultTaxType: {
        id: 'xeroDefaultTaxType',
        label: 'Default invoice tax rate',
        placeholder: ' ',
    },
    xeroDefaultPurchaseTaxType: {
        id: 'xeroDefaultPurchaseTaxType',
        label: 'Default purchase tax rate',
        placeholder: ' ',
    },
    paymentTermsId: {
        id: 'paymentTermsId',
        label: 'Default payment terms',
        placeholder: ' ',
    },
    quickBookDefaultChartOfAccountId: {
        id: 'quickBookDefaultChartOfAccountId',
        label: 'Default product account',
        placeholder: ' ',
    },
    quickBookDefaultPurchaseChartOfAccountId: {
        id: 'quickBookDefaultPurchaseChartOfAccountId',
        label: 'Default purchase product account',
        placeholder: ' ',
    },
    xeroDefaultChartOfAccountId: {
        id: 'xeroDefaultChartOfAccountId',
        label: 'Default product account',
        placeholder: ' ',
    },
    xeroDefaultPurchaseChartOfAccountId: {
        id: 'xeroDefaultPurchaseChartOfAccountId',
        label: 'Default purchase product account',
        placeholder: ' ',
    },
    xeroPaymentTerm: {
      id: 'xeroPaymentTerm',
      label: 'Payment terms',
      placeholder: ' ',
    },
    xeroPaymentTermType: {
        id: 'xeroPaymentTermType',
        label: ' ',
        placeholder: ' ',
        error: 'Due date for sales invoices must be between 1 and 31 when set to the following (or current) month.',
    },
    customerMemo: {
        id: 'customerMemo',
        label: 'Message to customer',
        placeholder: Utils.placeholderRows(5),
        maxLength: 1000,
    },
    prefixDoNumber: {
        id: 'prefixDoNumber',
        label: 'Delivery order',
        placeholder: ' ',
    },
    invoiceNumberPrefix: {
        id: 'invoiceNumberPrefix',
        label: 'Invoice',
        placeholder: ' ',
    },
    purchaseOrderNumberPrefix: {
        id: 'purchaseOrderNumberPrefix',
        label: 'Purchase order',
        placeholder: ' ',
    },
    expenseNumberPrefix: {
        id: 'expenseNumberPrefix',
        label: 'Expense',
        placeholder: ' ',
    },
    
    
    deliveryOrderLabelDeliveryOrder: {
        id: 'deliveryOrderLabelDeliveryOrder',
        label: 'DELIVERY ORDER',
        placeholder: ' ',
    },
    deliveryOrderShowCustomerName: {
        id: 'deliveryOrderShowCustomerName',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelCustomerName: {
        id: 'deliveryOrderLabelCustomerName',
        label: 'Customer name',
        placeholder: ' ',
    },
    deliveryOrderShowCustomerAddress: {
        id: 'deliveryOrderShowCustomerAddress',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelCustomerAddress: {
        id: 'deliveryOrderLabelCustomerAddress',
        label: 'Customer Address',
        placeholder: ' ',
    },
    deliveryOrderShowProjectSite: {
        id: 'deliveryOrderShowProjectSite',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelProjectSite: {
        id: 'deliveryOrderLabelProjectSite',
        label: 'Project Site',
        placeholder: ' ',
    },
    deliveryOrderShowJobType: {
        id: 'deliveryOrderShowJobType',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelJobType: {
        id: 'deliveryOrderLabelJobType',
        label: 'Job type',
        placeholder: ' ',
    },
    deliveryOrderShowJobCompletion: {
        id: 'deliveryOrderShowJobCompletion',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderShowJobTime: {
        id: 'deliveryOrderShowJobTime',
        label: 'Include time',
        placeholder: ' ',
    },
    deliveryOrderLabelJobCompletion: {
        id: 'deliveryOrderLabelJobCompletion',
        label: 'Job Completion',
        placeholder: ' ',
    },
    deliveryOrderShowDriver: {
        id: 'deliveryOrderShowDriver',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelDriver: {
        id: 'deliveryOrderLabelDriver',
        label: 'Driver',
        placeholder: ' ',
    },
    deliveryOrderShowVehicle: {
        id: 'deliveryOrderShowVehicle',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelVehicle: {
        id: 'deliveryOrderLabelVehicle',
        label: 'Vehicle',
        placeholder: ' ',
    },
    deliveryOrderShowBinIN: {
        id: 'deliveryOrderShowBinIN',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelBinIN: {
        id: 'deliveryOrderLabelBinIN',
        label: 'Bin IN',
        placeholder: ' ',
    },
    deliveryOrderShowBinOUT: {
        id: 'deliveryOrderShowBinOUT',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelBinOUT: {
        id: 'deliveryOrderLabelBinOUT',
        label: 'Bin OUT',
        placeholder: ' ',
    },
    deliveryOrderShowSiteContacts: {
        id: 'deliveryOrderShowSiteContacts',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelSiteContacts: {
        id: 'deliveryOrderLabelSiteContacts',
        label: 'Site contacts',
        placeholder: ' ',
    },
    deliveryOrderShowPaymentTerms: {
        id: 'deliveryOrderShowPaymentTerms',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelPaymentTerms: {
        id: 'deliveryOrderLabelPaymentTerms',
        label: 'Payment Terms',
        placeholder: ' ',
    },
    deliveryOrderShowCollectedCash: {
        id: 'deliveryOrderShowCollectedCash',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelCollectedCash: {
        id: 'deliveryOrderLabelCollectedCash',
        label: 'Collection',
        placeholder: ' ',
    },


    deliveryOrderShowStandardServicesBinType: {
        id: 'deliveryOrderShowStandardServicesBinType',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelStandardServicesBinType: {
        id: 'deliveryOrderLabelStandardServicesBinType',
        label: 'Bin type',
        placeholder: ' ',
    },
    deliveryOrderShowStandardServicesWasteType: {
        id: 'deliveryOrderShowStandardServicesWasteType',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelStandardServicesWasteType: {
        id: 'deliveryOrderLabelStandardServicesWasteType',
        label: 'Waste type',
        placeholder: ' ',
    },
    deliveryOrderShowStandardServicesQty: {
        id: 'deliveryOrderShowStandardServicesQty',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelStandardServicesQty: {
        id: 'deliveryOrderLabelStandardServicesQty',
        label: 'Qty',
        placeholder: ' ',
    },
    deliveryOrderShowStandardServicesRate: {
        id: 'deliveryOrderShowStandardServicesRate',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelStandardServicesRate: {
        id: 'deliveryOrderLabelStandardServicesRate',
        label: 'Rate',
        placeholder: ' ',
    },
    deliveryOrderShowStandardServicesPrice: {
        id: 'deliveryOrderShowStandardServicesPrice',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelStandardServicesPrice: {
        id: 'deliveryOrderLabelStandardServicesPrice',
        label: 'Price',
        placeholder: ' ',
    },


    deliveryOrderShowAdditionalServices: {
        id: 'deliveryOrderShowAdditionalServices',
        label: 'Additional Service',
        placeholder: ' ',
    },
    deliveryOrderShowAdditionalServicesDescription: {
        id: 'deliveryOrderShowAdditionalServicesDescription',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelAdditionalServicesDescription: {
        id: 'deliveryOrderLabelAdditionalServicesDescription',
        label: 'Description',
        placeholder: ' ',
    },
    deliveryOrderShowAdditionalServicesQty: {
        id: 'deliveryOrderShowAdditionalServicesQty',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelAdditionalServicesQty: {
        id: 'deliveryOrderLabelAdditionalServicesQty',
        label: 'Qty',
        placeholder: ' ',
    },
    deliveryOrderShowAdditionalServicesRate: {
        id: 'deliveryOrderShowAdditionalServicesRate',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelAdditionalServicesRate: {
        id: 'deliveryOrderLabelAdditionalServicesRate',
        label: 'Rate',
        placeholder: ' ',
    },
    deliveryOrderShowAdditionalServicesPrice: {
        id: 'deliveryOrderShowAdditionalServicesPrice',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelAdditionalServicesPrice: {
        id: 'deliveryOrderLabelAdditionalServicesPrice',
        label: 'Price',
        placeholder: ' ',
    },


    deliveryOrderShowSignature: {
        id: 'deliveryOrderShowSignature',
        label: 'Signature Area',
        placeholder: ' ',
    },
    deliveryOrderShowProofOfDelivery: {
        id: 'deliveryOrderShowProofOfDelivery',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelProofOfDelivery: {
        id: 'deliveryOrderLabelProofOfDelivery',
        label: 'PROOF OF DELIVERY',
        placeholder: ' ',
    },
    deliveryOrderShowMessageToCustomer: {
        id: 'deliveryOrderShowMessageToCustomer',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelMessageToCustomerPlainText: {
        id: 'deliveryOrderLabelMessageToCustomerPlainText',
        label: 'Message To Customer',
        placeholder: ' ',
    },
    deliveryOrderShowFooter: {
        id: 'deliveryOrderShowFooter',
        label: '',
        placeholder: ' ',
    },
    deliveryOrderLabelFooterPlainText: {
        id: 'deliveryOrderLabelFooterPlainText',
        label: 'Footer Note',
        placeholder: ' ',
    },
    

    printedReceiptShowCustomerName: {
        id: 'printedReceiptShowCustomerName',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelCustomerName: {
        id: 'printedReceiptLabelCustomerName',
        label: 'Customer name',
        placeholder: ' ',
    },
    printedReceiptShowCustomerAddress: {
        id: 'printedReceiptShowCustomerAddress',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelCustomerAddress: {
        id: 'printedReceiptLabelCustomerAddress',
        label: 'Site address',
        placeholder: ' ',
    },
    printedReceiptShowDriver: {
        id: 'printedReceiptShowDriver',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelDriver: {
        id: 'printedReceiptLabelDriver',
        label: 'Driver',
        placeholder: ' ',
    },
    printedReceiptShowVehicle: {
        id: 'printedReceiptShowVehicle',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelVehicle: {
        id: 'printedReceiptLabelVehicle',
        label: 'Vehicle',
        placeholder: ' ',
    },
    printedReceiptShowJobType: {
        id: 'printedReceiptShowJobType',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelJobType: {
        id: 'printedReceiptLabelJobType',
        label: 'Job type',
        placeholder: ' ',
    },
    printedReceiptShowPaymentType: {
        id: 'printedReceiptShowPaymentType',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelPaymentType: {
        id: 'printedReceiptLabelPaymentType',
        label: 'Payment',
        placeholder: ' ',
    },
    printedReceiptShowCollectedCash: {
        id: 'printedReceiptShowCollectedCash',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelCollectedCash: {
        id: 'printedReceiptLabelCollectedCash',
        label: 'Collected (Cash)',
        placeholder: ' ',
    },


    printedReceiptShowBinDelivered: {
        id: 'printedReceiptShowBinDelivered',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelBinDelivered: {
        id: 'printedReceiptLabelBinDelivered',
        label: 'Bin delivered',
        placeholder: ' ',
    },
    printedReceiptShowBinType1: {
        id: 'printedReceiptShowBinType1',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelBinType1: {
        id: 'printedReceiptLabelBinType1',
        label: 'Bin delivered type',
        placeholder: ' ',
    },
    printedReceiptShowWasteType1: {
        id: 'printedReceiptShowWasteType1',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelWasteType1: {
        id: 'printedReceiptLabelWasteType1',
        label: 'For waste type',
        placeholder: ' ',
    },
    printedReceiptShowBinCollected: {
        id: 'printedReceiptShowBinCollected',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelBinCollected: {
        id: 'printedReceiptLabelBinCollected',
        label: 'Bin collected',
        placeholder: ' ',
    },
    printedReceiptShowBinType2: {
        id: 'printedReceiptShowBinType2',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelBinType2: {
        id: 'printedReceiptLabelBinType2',
        label: 'Bin collected type',
        placeholder: ' ',
    },
    printedReceiptShowWasteType2: {
        id: 'printedReceiptShowWasteType2',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelWasteType2: {
        id: 'printedReceiptLabelWasteType2',
        label: 'With waste type',
        placeholder: ' ',
    },


    printedReceiptShowAdditionalServices: {
        id: 'printedReceiptShowAdditionalServices',
        label: 'Additional services',
        placeholder: ' ',
    },
    printedReceiptShowAdditionalServicesDescription: {
        id: 'printedReceiptShowAdditionalServicesDescription',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelAdditionalServicesDescription: {
        id: 'printedReceiptLabelAdditionalServicesDescription',
        label: 'Description',
        placeholder: ' ',
    },
    printedReceiptShowAdditionalServicesQty: {
        id: 'printedReceiptShowAdditionalServicesQty',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelAdditionalServicesQty: {
        id: 'printedReceiptLabelAdditionalServicesQty',
        label: 'Qty',
        placeholder: ' ',
    },


    printedReceiptShowSignature: {
        id: 'printedReceiptShowSignature',
        label: 'Signature Area',
        placeholder: ' ',
    },
    printedReceiptShowSiteContact: {
        id: 'printedReceiptShowSiteContact',
        label: 'Site contact',
        placeholder: ' ',
    },
    printedReceiptShowSiteTelephone: {
        id: 'printedReceiptShowSiteTelephone',
        label: 'Site telephone',
        placeholder: ' ',
    },
    printedReceiptShowFooter: {
        id: 'printedReceiptShowFooter',
        label: '',
        placeholder: ' ',
    },
    printedReceiptLabelFooter: {
        id: 'printedReceiptLabelFooter',
        label: 'Footer Note',
        placeholder: ' ',
    },

    
    emailSettingsSubject: {
        id: 'emailSettingsSubject',
        label: 'Subject',
        placeholder: ' ',
    },
    emailSettingsFrom: {
        id: 'emailSettingsFrom',
        label: 'From',
        placeholder: ' ',
    },
    emailSettingsCc: {
        id: 'emailSettingsCc',
        label: 'C.c',
        placeholder: 'Email addresses (multiple emails with commas)',
    },
    emailSettingsMessagePlainText: {
        id: 'emailSettingsMessagePlainText',
        label: 'Message',
        placeholder: ' ',
    },
    emailSettingsDeliveryOrder: {
        id: 'emailSettingsDeliveryOrder',
        label: 'Delivery Order',
        placeholder: 'Delivery Order',
    },
    emailSettingsIncludePhotos: {
        id: 'emailSettingsIncludePhotos',
        label: 'Photos',
        placeholder: 'Photos',
    },
    emailSettingsWeighChit: {
        id: 'emailSettingsWeighChit',
        label: 'Weigh Chit',
        placeholder: 'Weigh Chit',
    },


    weightChitLabelWeightTicket: {
        id: 'weightChitLabelWeightTicket',
        label: 'WEIGH TICKET',
        placeholder: ' ',
    },
    weightChitShowCustomerName: {
        id: 'weightChitShowCustomerName',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelCustomerName: {
        id: 'weightChitLabelCustomerName',
        label: 'Customer name',
        placeholder: ' ',
    },
    weightChitShowCustomerAddress: {
        id: 'weightChitShowCustomerAddress',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelCustomerAddress: {
        id: 'weightChitLabelCustomerAddress',
        label: 'Customer address',
        placeholder: ' ',
    },
    weightChitShowSiteAddress: {
        id: 'weightChitShowSiteAddress',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelSiteAddress: {
        id: 'weightChitLabelSiteAddress',
        label: 'Site address',
        placeholder: ' ',
    },
    weightChitShowDONumber: {
        id: 'weightChitShowDONumber',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelDONumber: {
        id: 'weightChitLabelDONumber',
        label: 'DO number',
        placeholder: ' ',
    },
    weightChitShowJobCompletion: {
        id: 'weightChitShowJobCompletion',
        label: '',
        placeholder: ' ',
    },
    weightChitShowJobTime: {
        id: 'weightChitShowJobTime',
        label: 'Include time',
        placeholder: ' ',
    },
    weightChitLabelJobCompletion: {
        id: 'weightChitLabelJobCompletion',
        label: 'Job completion',
        placeholder: ' ',
    },
    weightChitShowJobType: {
        id: 'weightChitShowJobType',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelJobType: {
        id: 'weightChitLabelJobType',
        label: 'Job type',
        placeholder: ' ',
    },
    weightChitShowBinINType: {
        id: 'weightChitShowBinINType',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelBinINType: {
        id: 'weightChitLabelBinINType',
        label: 'Bin IN type',
        placeholder: ' ',
    },
    weightChitShowWasteINType: {
        id: 'weightChitShowWasteINType',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelWasteINType: {
        id: 'weightChitLabelWasteINType',
        label: 'Waste IN type',
        placeholder: ' ',
    },
    weightChitShowWeighTimestamp: {
        id: 'weightChitShowWeighTimestamp',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelWeighTimestamp: {
        id: 'weightChitLabelWeighTimestamp',
        label: 'Weigh timestamp',
        placeholder: ' ',
    },
    weightChitShowWeighOutTimestamp: {
        id: 'weightChitShowWeighOutTimestamp',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelWeighOutTimestamp: {
        id: 'weightChitLabelWeighOutTimestamp',
        label: 'Weigh out timestamp',
        placeholder: ' ',
    },
    weightChitShowDriver: {
        id: 'weightChitShowDriver',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelDriver: {
        id: 'weightChitLabelDriver',
        label: 'Driver',
        placeholder: ' ',
    },
    weightChitShowVehicle: {
        id: 'weightChitShowVehicle',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelVehicle: {
        id: 'weightChitLabelVehicle',
        label: 'Vehicle',
        placeholder: ' ',
    },
    weightChitShowWeighBy: {
        id: 'weightChitShowWeighBy',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelWeighBy: {
        id: 'weightChitLabelWeighBy',
        label: 'Weigh by',
        placeholder: ' ',
    },
    weightChitShowRemarks: {
        id: 'weightChitShowRemarks',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelRemarks: {
        id: 'weightChitLabelRemarks',
        label: 'Remarks',
        placeholder: ' ',
    },
    
    weightChitShowGross: {
        id: 'weightChitShowGross',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelGross: {
        id: 'weightChitLabelGross',
        label: 'Gross',
        placeholder: ' ',
    },
    weightChitShowTare: {
        id: 'weightChitShowTare',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelTare: {
        id: 'weightChitLabelTare',
        label: 'Tare',
        placeholder: ' ',
    },
    weightChitShowNett: {
        id: 'weightChitShowNett',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelNett: {
        id: 'weightChitLabelNett',
        label: 'Nett',
        placeholder: ' ',
    },
    weightChitShowNonBillable: {
        id: 'weightChitShowNonBillable',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelNonBillable: {
        id: 'weightChitLabelNonBillable',
        label: 'Non-Billable',
        placeholder: ' ',
    },
    weightChitShowBillable: {
        id: 'weightChitShowBillable',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelBillable: {
        id: 'weightChitLabelBillable',
        label: 'Billable',
        placeholder: ' ',
    },
    weightChitShowRate: {
        id: 'weightChitShowRate',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelRate: {
        id: 'weightChitLabelRate',
        label: 'Rate',
        placeholder: ' ',
    },
    weightChitShowAmount: {
        id: 'weightChitShowAmount',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelAmount: {
        id: 'weightChitLabelAmount',
        label: 'Amount',
        placeholder: ' ',
    },

    weightChitShowMessageToCustomer: {
        id: 'weightChitShowMessageToCustomer',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelMessageToCustomerPlainText: {
        id: 'weightChitLabelMessageToCustomerPlainText',
        label: 'Message To Customer',
        placeholder: ' ',
    },
    weightChitLabelStringHeaderTextPlainText: {
        id: 'weightChitLabelStringHeaderTextPlainText',
        label: 'Header',
        placeholder: ' ',
    },
    weightChitShowFooter: {
        id: 'weightChitShowFooter',
        label: '',
        placeholder: ' ',
    },
    weightChitLabelFooterPlainText: {
        id: 'weightChitLabelFooterPlainText',
        label: 'Footer Note',
        placeholder: ' ',
    },
    weightChitCustomFieldsLabel: {
        id: 'weightChitCustomFieldsLabel',
        label: 'Custom',
        placeholder: 'Custom Field',
    },
    
    weightChitCustomField1: {
        id: 'weightChitCustomField1',
        label: 'Custom 1',
        placeholder: 'Custom Field',
    },
    weightChitCustomField2: {
        id: 'weightChitCustomField2',
        label: 'Custom 2',
        placeholder: 'Custom Field',
    },
    weightChitCustomField3: {
        id: 'weightChitCustomField3',
        label: 'Custom 3',
        placeholder: 'Custom Field',
    },
    weightChitCustomField4: {
        id: 'weightChitCustomField4',
        label: 'Custom 4',
        placeholder: 'Custom Field',
    },
    weightChitCustomField5: {
        id: 'weightChitCustomField5',
        label: 'Custom 5',
        placeholder: 'Custom Field',
    },

    notificationConnectTo: {
        id: 'notificationConnectTo',
        label: 'Connect to',
        placeholder: ' ',
    },
    notificationConnectServices: {
        id: 'notificationConnectServices',
        label: 'Connect services',
        placeholder: ' ',
    },
    notificationMessageFrom: {
        id: 'notificationMessageFrom',
        label: 'Message from',
        placeholder: ' ',
    },
    accountSid: {
        id: 'accountSid',
        label: 'Account SID',
        placeholder: ' ',
    },
    authToken: {
        id: 'authToken',
        label: 'Auth token',
        placeholder: ' ',
    },
    fromPhoneNumber: {
        id: 'fromPhoneNumber',
        label: 'From phone number',
        placeholder: ' ',
    },
    smsTemplate: {
        id: 'smsTemplate',
        label: 'SMS text message',
        placeholder: Utils.placeholderRows(5),
        maxLength: 160,
    },
    whatsappTemplate: {
        id: 'whatsappTemplate',
        label: 'WhatsApp text message',
        placeholder: Utils.placeholderRows(5),
        maxLength: 160,
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),

    billingProfileName: yup.string().required().label(formFields.billingProfileName.label),
    isActive: yup.bool().oneOf([true, false]).label(formFields.isActive.label),
    isDefault: yup.bool().oneOf([true, false]).label(formFields.isDefault.label),
    companyAddressPlainText: yup.string().nullable().label(formFields.companyAddressPlainText.label),
    companyLogo: yup.mixed()
        .test('fileType', 'Unsupported File Format', (value) => {
            if(value && value.type){
                const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/bmp', 'image/png'];
                return SUPPORTED_FORMATS.includes(value.type)
            } else {
                return true;
            }
        })
        .test('fileSize', "File Size is too large", value => {
            if(value && value.size){
                const sizeInBytes = 5000000; //5MB
                return value.size <= sizeInBytes;
            } else {
                return true;
            }
        })
        .nullable()
        .label(formFields.companyLogo.label),
    taxRateId: yup.number().nullable().label(formFields.taxRateId.label),
    xeroDefaultTaxType: yup.string().nullable().label(formFields.xeroDefaultTaxType.label),
    xeroDefaultPurchaseTaxType: yup.string().nullable().label(formFields.xeroDefaultPurchaseTaxType.label),
    paymentTermsId: yup.number().nullable().label(formFields.paymentTermsId.label),
    quickBookDefaultChartOfAccountId: yup.string().nullable().label(formFields.quickBookDefaultChartOfAccountId.label),
    quickBookDefaultPurchaseChartOfAccountId: yup.string().nullable().label(formFields.quickBookDefaultPurchaseChartOfAccountId.label),
    xeroDefaultChartOfAccountId: yup.string().nullable().label(formFields.xeroDefaultChartOfAccountId.label),
    xeroDefaultPurchaseChartOfAccountId: yup.string().nullable().label(formFields.xeroDefaultPurchaseChartOfAccountId.label),
    // xeroPaymentTermType: yup.string().nullable().label(formFields.xeroPaymentTermType.label),
    xeroPaymentTerm: yup.number().when(['customFormParams.isConnectXero', 'xeroPaymentTermType'], (isConnectXero, xeroPaymentTermType) => {
        if(isConnectXero){
            if((xeroPaymentTermType == XeroPaymentTermType.OF_CURRENT_MONTH) || (xeroPaymentTermType == XeroPaymentTermType.OF_FOLLOWING_MONTH)){
                return yup.number().nullable().required().positive().min(0).max(31, formFields.xeroPaymentTermType.error).label(formFields.xeroPaymentTerm.label);
            } else {
                return yup.number().nullable().required().positive().min(0).label(formFields.xeroPaymentTerm.label);
            }
        } else {
          return yup.number().nullable().positive().min(0).label(formFields.xeroPaymentTerm.label);
        }
    }),
    xeroPaymentTermType: yup.number().when(['customFormParams.isConnectXero'], (isConnectXero) => {
        if(isConnectXero){
          return yup.number().nullable().required().positive().min(0).label(formFields.xeroPaymentTermType.label);
        } else {
          return yup.number().nullable().label(formFields.xeroPaymentTermType.label);
        }
    }),

    customerMemo: yup.string().test(
        'len',
        "Max length is " + formFields.customerMemo.maxLength,
        (val) => {
            if (val == undefined) {
                return true;
            }
            return  ((val.length == 0 || (val.length > 0 && val.length <= formFields.customerMemo.maxLength)))
        }
    ).label(formFields.customerMemo.label),

    prefixDoNumber: yup.string().nullable().label(formFields.prefixDoNumber.label),
    invoiceNumberPrefix: yup.string().nullable().label(formFields.invoiceNumberPrefix.label),
    purchaseOrderNumberPrefix: yup.string().nullable().label(formFields.purchaseOrderNumberPrefix.label),
    expenseNumberPrefix: yup.string().nullable().label(formFields.expenseNumberPrefix.label),

    deliveryOrderLabelDeliveryOrder: yup.string().label(formFields.deliveryOrderLabelDeliveryOrder.label),
    deliveryOrderShowCustomerName: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowCustomerName.label),
    deliveryOrderLabelCustomerName: yup.string().label(formFields.deliveryOrderLabelCustomerName.label),
    deliveryOrderShowCustomerAddress: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowCustomerAddress.label),
    deliveryOrderLabelCustomerAddress: yup.string().label(formFields.deliveryOrderLabelCustomerAddress.label),
    deliveryOrderShowProjectSite: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowProjectSite.label),
    deliveryOrderLabelProjectSite: yup.string().label(formFields.deliveryOrderLabelProjectSite.label),
    deliveryOrderShowJobType: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowJobType.label),
    deliveryOrderLabelJobType: yup.string().label(formFields.deliveryOrderLabelJobType.label),
    deliveryOrderShowJobCompletion: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowJobCompletion.label),
    deliveryOrderShowJobTime: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowJobTime.label),
    deliveryOrderLabelJobCompletion: yup.string().label(formFields.deliveryOrderLabelJobCompletion.label),
    deliveryOrderShowDriver: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowDriver.label),
    deliveryOrderLabelDriver: yup.string().label(formFields.deliveryOrderLabelDriver.label),
    deliveryOrderShowVehicle: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowVehicle.label),
    deliveryOrderLabelVehicle: yup.string().label(formFields.deliveryOrderLabelVehicle.label),
    deliveryOrderShowBinIN: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowBinIN.label),
    deliveryOrderLabelBinIN: yup.string().label(formFields.deliveryOrderLabelBinIN.label),
    deliveryOrderShowBinOUT: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowBinOUT.label),
    deliveryOrderLabelBinOUT: yup.string().label(formFields.deliveryOrderLabelBinOUT.label),
    deliveryOrderShowSiteContacts: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowSiteContacts.label),
    deliveryOrderLabelSiteContacts: yup.string().label(formFields.deliveryOrderLabelSiteContacts.label),
    deliveryOrderShowPaymentTerms: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowPaymentTerms.label),
    deliveryOrderLabelPaymentTerms: yup.string().label(formFields.deliveryOrderLabelPaymentTerms.label),
    deliveryOrderShowCollectedCash: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowCollectedCash.label),
    deliveryOrderLabelCollectedCash: yup.string().label(formFields.deliveryOrderLabelCollectedCash.label),

    deliveryOrderShowStandardServicesBinType: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowStandardServicesBinType.label),
    // deliveryOrderLabelStandardServicesBinType: yup.string().label(formFields.deliveryOrderLabelStandardServicesBinType.label),
    deliveryOrderShowStandardServicesWasteType: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowStandardServicesWasteType.label),
    // deliveryOrderLabelStandardServicesWasteType: yup.string().label(formFields.deliveryOrderLabelStandardServicesWasteType.label),
    deliveryOrderShowStandardServicesQty: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowStandardServicesQty.label),
    // deliveryOrderLabelStandardServicesQty: yup.string().label(formFields.deliveryOrderLabelStandardServicesQty.label),
    deliveryOrderShowStandardServicesRate: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowStandardServicesRate.label),
    // deliveryOrderLabelStandardServicesRate: yup.string().label(formFields.deliveryOrderLabelStandardServicesRate.label),
    deliveryOrderShowStandardServicesPrice: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowStandardServicesPrice.label),
    // deliveryOrderLabelStandardServicesPrice: yup.string().label(formFields.deliveryOrderLabelStandardServicesPrice.label),

    deliveryOrderShowAdditionalServices: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowAdditionalServices.label),
    deliveryOrderShowAdditionalServicesDescription: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowAdditionalServicesDescription.label),
    // deliveryOrderLabelAdditionalServicesDescription: yup.string().label(formFields.deliveryOrderLabelAdditionalServicesDescription.label),
    deliveryOrderShowAdditionalServicesQty: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowAdditionalServicesQty.label),
    // deliveryOrderLabelAdditionalServicesQty: yup.string().label(formFields.deliveryOrderLabelAdditionalServicesQty.label),
    deliveryOrderShowAdditionalServicesRate: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowAdditionalServicesRate.label),
    // deliveryOrderLabelAdditionalServicesRate: yup.string().label(formFields.deliveryOrderLabelAdditionalServicesRate.label),
    deliveryOrderShowAdditionalServicesPrice: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowAdditionalServicesPrice.label),
    // deliveryOrderLabelAdditionalServicesPrice: yup.string().label(formFields.deliveryOrderLabelAdditionalServicesPrice.label),

    deliveryOrderShowSignature: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowSignature.label),
    deliveryOrderShowProofOfDelivery: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowProofOfDelivery.label),
    deliveryOrderLabelProofOfDelivery: yup.string().label(formFields.deliveryOrderLabelProofOfDelivery.label),
    deliveryOrderShowMessageToCustomer: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowMessageToCustomer.label),
    deliveryOrderLabelMessageToCustomerPlainText: yup.string().label(formFields.deliveryOrderLabelMessageToCustomerPlainText.label),
    deliveryOrderShowFooter: yup.bool().oneOf([true, false]).label(formFields.deliveryOrderShowFooter.label),
    deliveryOrderLabelFooterPlainText: yup.string().label(formFields.deliveryOrderLabelFooterPlainText.label),

    printedReceiptShowCustomerName: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowCustomerName.label),
    // printedReceiptLabelCustomerName: yup.string().label(formFields.printedReceiptLabelCustomerName.label),
    // printedReceiptShowCustomerAddress: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowCustomerAddress.label),
    printedReceiptLabelCustomerAddress: yup.string().label(formFields.printedReceiptLabelCustomerAddress.label),
    printedReceiptShowDriver: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowDriver.label),
    printedReceiptLabelDriver: yup.string().label(formFields.printedReceiptLabelDriver.label),
    printedReceiptShowVehicle: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowVehicle.label),
    printedReceiptLabelVehicle: yup.string().label(formFields.printedReceiptLabelVehicle.label),
    printedReceiptShowJobType: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowJobType.label),
    printedReceiptLabelJobType: yup.string().label(formFields.printedReceiptLabelJobType.label),
    printedReceiptShowPaymentType: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowPaymentType.label),
    printedReceiptLabelPaymentType: yup.string().label(formFields.printedReceiptLabelPaymentType.label),
    printedReceiptShowCollectedCash: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowCollectedCash.label),
    printedReceiptLabelCollectedCash: yup.string().label(formFields.printedReceiptLabelCollectedCash.label),

    printedReceiptShowBinDelivered: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowBinDelivered.label),
    printedReceiptLabelBinDelivered: yup.string().label(formFields.printedReceiptLabelBinDelivered.label),
    printedReceiptShowBinType1: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowBinType1.label),
    printedReceiptLabelBinType1: yup.string().label(formFields.printedReceiptLabelBinType1.label),
    printedReceiptShowWasteType1: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowWasteType1.label),
    printedReceiptLabelWasteType1: yup.string().label(formFields.printedReceiptLabelWasteType1.label),
    printedReceiptShowBinCollected: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowBinCollected.label),
    printedReceiptLabelBinCollected: yup.string().label(formFields.printedReceiptLabelBinCollected.label),
    printedReceiptShowBinType2: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowBinType2.label),
    printedReceiptLabelBinType2: yup.string().label(formFields.printedReceiptLabelBinType2.label),
    printedReceiptShowWasteType2: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowWasteType2.label),
    printedReceiptLabelWasteType2: yup.string().label(formFields.printedReceiptLabelWasteType2.label),

    printedReceiptShowAdditionalServices: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowAdditionalServices.label),
    // printedReceiptShowAdditionalServicesDescription: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowAdditionalServicesDescription.label),
    // printedReceiptLabelAdditionalServicesDescription: yup.string().label(formFields.printedReceiptLabelAdditionalServicesDescription.label),
    // printedReceiptShowAdditionalServicesQty: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowAdditionalServicesQty.label),
    // printedReceiptLabelAdditionalServicesQty: yup.string().label(formFields.printedReceiptLabelAdditionalServicesQty.label),
    
    printedReceiptShowSignature: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowSignature.label),
    printedReceiptShowSiteContact: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowSiteContact.label),
    printedReceiptShowSiteTelephone: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowSiteTelephone.label),
    // printedReceiptShowFooter: yup.bool().oneOf([true, false]).label(formFields.printedReceiptShowFooter.label),
    printedReceiptLabelFooter: yup.string().label(formFields.printedReceiptLabelFooter.label),

    
    emailSettingsSubject: yup.string().label(formFields.emailSettingsSubject.label),
    emailSettingsFrom: yup.string().label(formFields.emailSettingsFrom.label),
    // emailSettingsCc: yup.string().email('Must be a valid email').label(formFields.emailSettingsCc.label),
    emailSettingsCc: yup.array().of(yup.string().email('Must be a valid email')).label(formFields.emailSettingsCc.label),
    emailSettingsMessagePlainText: yup.string().label(formFields.emailSettingsMessagePlainText.label),
    emailSettingsDeliveryOrder: yup.bool().oneOf([true, false]).label(formFields.emailSettingsDeliveryOrder.label),
    emailSettingsIncludePhotos: yup.bool().oneOf([true, false]).label(formFields.emailSettingsIncludePhotos.label),
    emailSettingsWeighChit: yup.bool().oneOf([true, false]).label(formFields.emailSettingsWeighChit.label),


    
    weightChitLabelWeightTicket: yup.string().label(formFields.weightChitLabelWeightTicket.label),
    weightChitShowCustomerName: yup.bool().oneOf([true, false]).label(formFields.weightChitShowCustomerName.label),
    weightChitLabelCustomerName: yup.string().label(formFields.weightChitLabelCustomerName.label),
    weightChitShowCustomerAddress: yup.bool().oneOf([true, false]).label(formFields.weightChitShowCustomerAddress.label),
    weightChitLabelCustomerAddress: yup.string().label(formFields.weightChitLabelCustomerAddress.label),
    weightChitShowSiteAddress: yup.bool().oneOf([true, false]).label(formFields.weightChitShowSiteAddress.label),
    weightChitLabelSiteAddress: yup.string().label(formFields.weightChitLabelSiteAddress.label),
    weightChitShowDONumber: yup.bool().oneOf([true, false]).label(formFields.weightChitShowDONumber.label),
    weightChitLabelDONumber: yup.string().label(formFields.weightChitLabelDONumber.label),
    weightChitShowJobCompletion: yup.bool().oneOf([true, false]).label(formFields.weightChitShowJobCompletion.label),
    weightChitShowJobTime: yup.bool().oneOf([true, false]).label(formFields.weightChitShowJobTime.label),
    weightChitLabelJobCompletion: yup.string().label(formFields.weightChitLabelJobCompletion.label),
    weightChitShowJobType: yup.bool().oneOf([true, false]).label(formFields.weightChitShowJobType.label),
    weightChitLabelJobType: yup.string().label(formFields.weightChitLabelJobType.label),
    weightChitShowBinINType: yup.bool().oneOf([true, false]).label(formFields.weightChitShowBinINType.label),
    weightChitLabelBinINType: yup.string().label(formFields.weightChitLabelBinINType.label),
    weightChitShowWasteINType: yup.bool().oneOf([true, false]).label(formFields.weightChitShowWasteINType.label),
    weightChitLabelWasteINType: yup.string().label(formFields.weightChitLabelWasteINType.label),
    weightChitShowWeighTimestamp: yup.bool().oneOf([true, false]).label(formFields.weightChitShowWeighTimestamp.label),
    weightChitLabelWeighTimestamp: yup.string().label(formFields.weightChitLabelWeighTimestamp.label),
    weightChitShowWeighOutTimestamp: yup.bool().oneOf([true, false]).label(formFields.weightChitShowWeighOutTimestamp.label),
    weightChitLabelWeighOutTimestamp: yup.string().label(formFields.weightChitLabelWeighOutTimestamp.label),
    weightChitShowDriver: yup.bool().oneOf([true, false]).label(formFields.weightChitShowDriver.label),
    weightChitLabelDriver: yup.string().label(formFields.weightChitLabelDriver.label),
    weightChitShowVehicle: yup.bool().oneOf([true, false]).label(formFields.weightChitShowVehicle.label),
    weightChitLabelVehicle: yup.string().label(formFields.weightChitLabelVehicle.label),
    weightChitShowWeighBy: yup.bool().oneOf([true, false]).label(formFields.weightChitShowWeighBy.label),
    weightChitLabelWeighBy: yup.string().label(formFields.weightChitLabelWeighBy.label),
    weightChitShowRemarks: yup.bool().oneOf([true, false]).label(formFields.weightChitShowRemarks.label),
    weightChitLabelRemarks: yup.string().label(formFields.weightChitLabelRemarks.label),

    weightChitShowGross: yup.bool().oneOf([true, false]).label(formFields.weightChitShowGross.label),
    weightChitLabelGross: yup.string().label(formFields.weightChitLabelGross.label),
    weightChitShowTare: yup.bool().oneOf([true, false]).label(formFields.weightChitShowTare.label),
    weightChitLabelTare: yup.string().label(formFields.weightChitLabelTare.label),
    weightChitShowNett: yup.bool().oneOf([true, false]).label(formFields.weightChitShowNett.label),
    weightChitLabelNett: yup.string().label(formFields.weightChitLabelNett.label),
    weightChitLabelNonBillable: yup.string().label(formFields.weightChitLabelNonBillable.label),
    weightChitShowNonBillable: yup.bool().oneOf([true, false]).label(formFields.weightChitShowNonBillable.label),
    weightChitShowBillable: yup.bool().oneOf([true, false]).label(formFields.weightChitShowBillable.label),
    weightChitLabelBillable: yup.string().label(formFields.weightChitLabelBillable.label),
    weightChitShowRate: yup.bool().oneOf([true, false]).label(formFields.weightChitShowRate.label),
    weightChitLabelRate: yup.string().label(formFields.weightChitLabelRate.label),
    weightChitShowAmount: yup.bool().oneOf([true, false]).label(formFields.weightChitShowAmount.label),
    weightChitLabelAmount: yup.string().label(formFields.weightChitLabelAmount.label),

    weightChitShowMessageToCustomer: yup.bool().oneOf([true, false]).label(formFields.weightChitShowMessageToCustomer.label),
    weightChitLabelMessageToCustomerPlainText: yup.string().label(formFields.weightChitLabelMessageToCustomerPlainText.label),
    weightChitLabelStringHeaderTextPlainText: yup.string().label(formFields.weightChitLabelStringHeaderTextPlainText.label),
    weightChitShowFooter: yup.bool().oneOf([true, false]).label(formFields.weightChitShowFooter.label),
    weightChitLabelFooterPlainText: yup.string().label(formFields.weightChitLabelFooterPlainText.label),

    weightChitCustomFields: yup.array().of(yup.object().shape({
        label: yup.string().nullable().label(formFields.weightChitCustomFieldsLabel.label),
    })),

    
    notificationConnectTo: yup.string().label(formFields.notificationConnectTo.label),
    notificationConnectServices: yup.string().label(formFields.notificationConnectServices.label),
    notificationMessageFrom: yup.string().label(formFields.notificationMessageFrom.label),
    accountMessageTemplates: yup.array().of(yup.object().shape({
        smsTemplate: yup.string().nullable().test(
            'len',
            "Max length is " + formFields.smsTemplate.maxLength,
            (val) => {
                if (val == null || val == undefined) {
                    return true;
                }
                return  ((val.length == 0 || (val.length > 0 && val.length <= formFields.smsTemplate.maxLength)))
            }
        ).label(formFields.smsTemplate.label),
        whatsappTemplate: yup.string().nullable().test(
            'len',
            "Max length is " + formFields.whatsappTemplate.maxLength,
            (val) => {
                if (val == null || val == undefined) {
                    return true;
                }
                return  ((val.length == 0 || (val.length > 0 && val.length <= formFields.whatsappTemplate.maxLength)))
            }
        ).label(formFields.whatsappTemplate.label),
    })),
});


class CompanyView extends Component {

    constructor(props) {
        super(props);

        this.pageName = "manage-company-page";
        this.pageIdField = "accountBillingProfileId";
        this.pageNameField = "billingProfileName";
        this.pageAPI = "AccountBillingProfile";
        this.pageCaption = 'Company'

        let user = (props.auth && props.auth.user) ? props.auth.user : null;
        let accountName = user && user.userAccount && user.userAccount.accountName ? user.userAccount.accountName : "";
        
        this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

        props.layoutRef.current = this;
        
        this.refFormik = React.createRef();

        
        let q = queryString.parse(window.location.search);
        this.id = q.id ? q.id : "";
        this.selectedTab = q.selectedTab ? parseInt(q.selectedTab) : 1;
        this.itegration = q.itegration ? parseInt(q.itegration) : QuickbookOrXero.None;
        this.err = q.err ? q.err : "";
        this.isErr = q.isErr ? q.isErr : "0";
        this.isConnected = q.isConnected ? q.isConnected : "";


        this.rowInitialValues = {
            isEdit: false,
            billingProfileName: '',
            isActive: false,
            isDefault: false,
            companyAddressPlainText: '',
            companyAddressHTML: '',
            companyAddress: EditorState.createEmpty(),
            companyLogo: '',
            companyLogoURL: '',
            taxRateId: null,
            taxRateName: '',
            xeroDefaultTaxType: null,
            xeroDefaultTaxTypeName: '',
            xeroDefaultPurchaseTaxType: null,
            xeroDefaultPurchaseTaxTypeName: '',
            paymentTermsId: null,
            paymentTermsName: '',
            quickBookDefaultChartOfAccountId: null,
            quickBookDefaultPurchaseChartOfAccountId: null,
            xeroDefaultChartOfAccountId: null,
            xeroDefaultPurchaseChartOfAccountId: null,
            xeroPaymentTerm: '0',
            xeroPaymentTermType: '0',
            xeroDefaultChartOfAccountName: '',
            xeroDefaultPurchaseChartOfAccountName: '',
            customerMemo: '',
            prefixDoNumber: '',
            invoiceNumberPrefix: '',
            purchaseOrderNumberPrefix: '',
            expenseNumberPrefix: '',

            deliveryOrderLabelDeliveryOrder: '',
            deliveryOrderShowCustomerName: false,
            deliveryOrderLabelCustomerName: '',
            deliveryOrderShowCustomerAddress: false,
            deliveryOrderLabelCustomerAddress: '',
            deliveryOrderShowProjectSite: false,
            deliveryOrderLabelProjectSite: '',
            deliveryOrderShowJobType: false,
            deliveryOrderLabelJobType: '',
            deliveryOrderShowJobCompletion: false,
            deliveryOrderShowJobTime: false,
            deliveryOrderLabelJobCompletion: '',
            deliveryOrderShowDriver: false,
            deliveryOrderLabelDriver: '',
            deliveryOrderShowVehicle: false,
            deliveryOrderLabelVehicle: '',
            deliveryOrderShowBinIN: false,
            deliveryOrderLabelBinIN: '',
            deliveryOrderShowBinOUT: false,
            deliveryOrderLabelBinOUT: '',
            deliveryOrderShowSiteContacts: false,
            deliveryOrderLabelSiteContacts: '',
            deliveryOrderShowPaymentTerms: false,
            deliveryOrderLabelPaymentTerms: '',
            deliveryOrderShowCollectedCash: false,
            deliveryOrderLabelCollectedCash: '',

            deliveryOrderShowStandardServicesBinType: false,
            // deliveryOrderLabelStandardServicesBinType: '',
            deliveryOrderShowStandardServicesWasteType: false,
            // deliveryOrderLabelStandardServicesWasteType: '',
            deliveryOrderShowStandardServicesQty: false,
            // deliveryOrderLabelStandardServicesQty: '',
            deliveryOrderShowStandardServicesRate: false,
            // deliveryOrderLabelStandardServicesRate: '',
            deliveryOrderShowStandardServicesPrice: false,
            // deliveryOrderLabelStandardServicesPrice: '',

            deliveryOrderShowAdditionalServices: false,
            deliveryOrderShowAdditionalServicesDescription: false,
            // deliveryOrderLabelAdditionalServicesDescription: '',
            deliveryOrderShowAdditionalServicesQty: false,
            // deliveryOrderLabelAdditionalServicesQty: '',
            deliveryOrderShowAdditionalServicesRate: false,
            // deliveryOrderLabelAdditionalServicesRate: '',
            deliveryOrderShowAdditionalServicesPrice: false,
            // deliveryOrderLabelAdditionalServicesPrice: '',

            deliveryOrderShowSignature: false,
            deliveryOrderShowProofOfDelivery: false,
            deliveryOrderLabelProofOfDelivery: '',
            deliveryOrderShowMessageToCustomer: false,
            deliveryOrderLabelMessageToCustomerPlainText: '',
            deliveryOrderLabelMessageToCustomerHTML: '',
            deliveryOrderLabelMessageToCustomer: EditorState.createEmpty(),
            deliveryOrderShowFooter: false,
            deliveryOrderLabelFooterPlainText: '',
            deliveryOrderLabelFooterHTML: '',
            deliveryOrderLabelFooter: EditorState.createEmpty(),

            
            printedReceiptShowCustomerName: false,
            // printedReceiptLabelCustomerName: '',
            // printedReceiptShowCustomerAddress: false,
            printedReceiptLabelCustomerAddress: '',
            printedReceiptShowDriver: false,
            printedReceiptLabelDriver: '',
            printedReceiptShowVehicle: false,
            printedReceiptLabelVehicle: '',
            printedReceiptShowJobType: false,
            printedReceiptLabelJobType: '',
            printedReceiptShowPaymentType: false,
            printedReceiptLabelPaymentType: '',
            printedReceiptShowCollectedCash: false,
            printedReceiptLabelCollectedCash: '',

            printedReceiptShowBinDelivered: false,
            printedReceiptLabelBinDelivered: '',
            printedReceiptShowBinType1: false,
            printedReceiptLabelBinType1: '',
            printedReceiptShowWasteType1: false,
            printedReceiptLabelWasteType1: '',
            printedReceiptShowBinCollected: false,
            printedReceiptLabelBinCollected: '',
            printedReceiptShowBinType2: false,
            printedReceiptLabelBinType2: '',
            printedReceiptShowWasteType2: false,
            printedReceiptLabelWasteType2: '',

            printedReceiptShowAdditionalServices: false,
            // printedReceiptShowAdditionalServicesDescription: false,
            // printedReceiptLabelAdditionalServicesDescription: '',
            // printedReceiptShowAdditionalServicesQty: false,
            // printedReceiptLabelAdditionalServicesQty: '',

            printedReceiptShowSignature: false,
            printedReceiptShowSiteContact: false,
            printedReceiptShowSiteTelephone: false,
            // printedReceiptShowFooter: false,
            printedReceiptLabelFooter: '',

            
            emailSettingsSubject: '',
            emailSettingsFrom: '',
            emailSettingsCc: [],
            emailSettingsMessagePlainText: '',
            emailSettingsMessageHTML: '',
            emailSettingsMessage: EditorState.createEmpty(),
            emailSettingsDeliveryOrder: false,
            emailSettingsIncludePhotos: false,
            emailSettingsWeighChit: false,


            
            weightChitLabelWeightTicket: '',
            weightChitShowCustomerName: false,
            weightChitLabelCustomerName: '',
            weightChitShowCustomerAddress: false,
            weightChitLabelCustomerAddress: '',
            weightChitShowSiteAddress: false,
            weightChitLabelSiteAddress: '',
            weightChitShowDONumber: false,
            weightChitLabelDONumber: '',
            weightChitShowJobCompletion: false,
            weightChitShowJobTime: false,
            weightChitLabelJobCompletion: '',
            weightChitShowJobType: false,
            weightChitLabelJobType: '',
            weightChitShowBinINType: false,
            weightChitLabelBinINType: '',
            weightChitShowWasteINType: false,
            weightChitLabelWasteINType: '',
            weightChitShowWeighTimestamp: false,
            weightChitLabelWeighTimestamp: '',
            weightChitShowWeighOutTimestamp: false,
            weightChitLabelWeighOutTimestamp: '',
            weightChitShowDriver: false,
            weightChitLabelDriver: '',
            weightChitShowVehicle: false,
            weightChitLabelVehicle: '',
            weightChitShowWeighBy: false,
            weightChitLabelWeighBy: '',
            weightChitShowRemarks: false,
            weightChitLabelRemarks: '',

            weightChitShowGross: false,
            weightChitLabelGross: '',
            weightChitShowTare: false,
            weightChitLabelTare: '',
            weightChitShowNett: false,
            weightChitLabelNett: '',
            weightChitShowNonBillable: false,
            weightChitLabelNonBillable: '',
            weightChitShowBillable: false,
            weightChitLabelBillable: '',
            weightChitShowRate: false,
            weightChitLabelRate: '',
            weightChitShowAmount: false,
            weightChitLabelAmount: '',

            weightChitShowMessageToCustomer: false,
            weightChitLabelMessageToCustomerPlainText: '',
            weightChitLabelMessageToCustomerHTML: '',
            weightChitLabelMessageToCustomer: EditorState.createEmpty(),
            weightChitLabelStringHeaderTextPlainText: '',
            weightChitLabelStringHeaderTextHTML: '',
            weightChitLabelStringHeaderText: EditorState.createEmpty(),
            weightChitShowFooter: false,
            weightChitLabelFooterPlainText: '',
            weightChitLabelFooterHTML: '',
            weightChitLabelFooter: EditorState.createEmpty(),
            
            weightChitAccountDocSettinglayout: WeighChitLayout.Default,
            weightChitCustomFields: [
                {
                    accountCustomFieldId: null,
                    label: '',
                }
            ],


            
            notificationConnectTo: 'Twilio',
            notificationConnectServices: 'SMS, WhatsApp',
            notificationMessageFrom: accountName,
            accountSid: '',
            authToken: '',
            fromPhoneNumber: null,
            accountMessageTemplates: [],
            twilioApp: null,


            customFormParams: {
                companyLogoIsUploading: false,
                isConnectQuickbook: false,
                isConnectXero: false,
                isConnectTwilio: false,
                quickbookOrXero: QuickbookOrXero.None,
                quickbookOrXeroName: 'None',
                quickBookApp: null,
                xeroApp: null,
                taxRateItems: [],
                taxRateIsLoading: false,
                paymentTermsItems: [],
                paymentTermsIsLoading: false,
            }
        };


        this.state = {
            data: null,
            err: null,
            isError: false,
            isLoading: false,

            search: '',
            sortColumn: 'created',
            sortDir: 'asc',
            searchTags: [],
            searchOperator: [],
            searchColumns: [],

            currentPage: 1,
            totalRows: 0,
            rowsPerPageSelected: Utils.getMaxPageSize(),
            rows: [],
            rowsInline: [],
            rowsDropdown: [],
            
            selectedProfileId: null,
            selectedProfileItem: null,
            selectedTab: this.selectedTab,

            isOpenAuditTrail: false,
            objectId: null,

            isRemoveDialog: false,
            removeRow: null,

            isIncludeInactive: false,

            isBlockingFormDrawer: false,
            rowInitialValues: this.rowInitialValues,
            rowValues: this.rowInitialValues,

            fromPhoneNumber: '',
        }
    }


    componentDidMount() {
        if(this.isConnected != ""){
            Utils.toast('All tax rates will be overridden', 'warning');
        }

        this.callReadApi(() => {
            if(this.id !== ''){
                this.selectIntegration();
            } else {
                this.selectDefault();
            }
        });
    }
  
    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
	}
  

	/* FUNCTIONS */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let array = (data && data.data && data.data.length > 0) ? data.data : [];
                let totalRows = (data && data.total) ? data.total : 0;
                
                let SHOW_IN_ROW = 4;

                let rowsInline = [];
                let rowsDropdown = [];
                if(array && array.length > 0){
                    for(let i = 0; i < array.length; i++){
                        if(i < SHOW_IN_ROW){
                            rowsInline.push(array[i]);
                        } else {
                            rowsDropdown.push(array[i]);
                        }
                    }
                }

                this.setState({
                    rows: array,
                    rowsInline: rowsInline,
                    rowsDropdown: rowsDropdown,
                    totalRows: totalRows,
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback();
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,
                    details: data,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-create', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-copy', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-quickBook_update_customer_memo', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-update_email_settings', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-update_account_doc_setting', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-update_notifications', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-delete', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
    }

    getProfileById = (arr = [], value = null, id = null) => {
        let item = null;

        let arrObj = arr.filter(x => x[value] == id);
        if(arrObj && arrObj.length > 0){
            item = arrObj[0];
        }

        return item;
    }
    isSelectedProfile = (id = null) => {
        if(this.state.selectedProfileId && id){
            return (this.state.selectedProfileId == id) ? true : false;
        } else {
            return false;
        }
    }
    isSelectedAnyProfile = () => {
        if(this.state.selectedProfileId && this.state.rowsDropdown && this.state.rowsDropdown.length > 0){
            let itemArr = this.state.rowsDropdown.filter(x => x[this.pageIdField] == this.state.selectedProfileId);
            return (itemArr && itemArr.length > 0) ? true : false;
        } else {
            return false;
        }
    }
    selectDefault = () => {
        if(this.state.rows && this.state.rows.length > 0){
            let itemArr = this.state.rows.filter(x => x.isDefault === true);
            if(itemArr && itemArr.length > 0){
                this.details(itemArr[0][this.pageIdField], itemArr[0]);
            };
        }
    }
    selectIntegration = () => {
        if(this.state.rows && this.state.rows.length > 0){
            let itemArr = this.state.rows.filter(x => x[this.pageIdField] == this.id);
            if(itemArr && itemArr.length > 0){
                this.details(itemArr[0][this.pageIdField], itemArr[0], this.state.selectedTab);
            };
        }
    }
    savePrevTab = (form, nextTab, formOptions) => {
        let {
            touched,
            setTouched,
        } = formOptions;

        if(_.isEmpty(touched)){
            this.setState({
                selectedTab: nextTab,
            }, () => {
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?id=' + this.id + '&selectedTab=' + nextTab;
                window.history.pushState({ path: newurl }, '', newurl);
            });
        } else {
            if(this.state.selectedTab === 6){
                let data = this.prepareDataUpdateNotifications(form);
                this.callUpdateNotificationsApi(data, (res) => {
                    this.props.action.notifications.setSelectedMenu(0);
                    this.details(this.state.selectedProfileId, res, nextTab);
                });

            } else if(this.state.selectedTab === 5){
                let data = this.prepareDataUpdateWeightChit(form);
                this.callUpdateAccountDocSettingApi(data, (res) => {
                    this.callReadApi();
                    this.details(res[this.pageIdField], res, nextTab);
                    setTouched({});
                });
    
            } else if(this.state.selectedTab === 4){
                let data = this.prepareDataUpdatePrintedReceipt(form);
                this.callUpdateAccountDocSettingApi(data, (res) => {
                    this.callReadApi();
                    this.details(res[this.pageIdField], res, nextTab);
                    setTouched({});
                });
    
            } else if(this.state.selectedTab === 3){
                let data = this.prepareDataUpdateEmailSettings(form);
                this.callUpdateEmailSettingsApi(data, (res) => {
                    this.callReadApi();
                    this.details(res[this.pageIdField], res, nextTab);
                    setTouched({});
                });
        
            } else if(this.state.selectedTab === 2){
                let data = this.prepareDataUpdateDeliveryOrder(form);
                this.callUpdateAccountDocSettingApi(data, (res) => {
                    this.details(res[this.pageIdField], res, nextTab);
                    setTouched({});
                });
        
            } else {
                let data = this.prepareDataUpdate(form);
                this.callUpdateApi(form, data, (res) => {
                    this.callReadApi();
                    this.details(res[this.pageIdField], res, nextTab);
                    setTouched({});
                });
            }
        }
    }

    details = (id = null, item = null, selectedTab = 1) => {
        this.id = id;
        
        this.setState({
            selectedProfileId: id,
            selectedProfileItem: null,
            selectedTab: selectedTab,
            rowValues: this.state.rowInitialValues,
        }, () => {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?id=' + id + '&selectedTab=' + selectedTab;
            window.history.pushState({ path: newurl }, '', newurl);

            if(this.refFormik && this.refFormik.current){
                this.refFormik.current.resetForm()
            }

            this.callDetailsApi(id, (data) => {
                let rowValues = this.prepareForm(data);
      
                try {
                    if(rowValues.fromPhoneNumber != this.state.fromPhoneNumber){
                        this.props.action.notifications.setSenderPhoneNumberValue(rowValues.fromPhoneNumber);
                    }
                } catch(e){}

                this.setState({
                  rowValues: rowValues,
                  selectedProfileItem: data,
                  fromPhoneNumber: rowValues.fromPhoneNumber,
                });
            });
        });
    }
    prepareForm = (data) => {
        if(data){
            let companyAddressPlainText = h2p(data.companyAddress);
            let companyAddressHTML = data.companyAddress;
            let companyAddress = Utils.createEditorState(companyAddressHTML);


            let isConnectQuickbook = ((data.isConnectQuickbook === false) || (data.isConnectQuickbook === true)) ? data.isConnectQuickbook : false;
            let isConnectXero = ((data.isConnectXero === false) || (data.isConnectXero === true)) ? data.isConnectXero : false;
            let isConnectTwilio = ((data.isConnectTwilio === false) || (data.isConnectTwilio === true)) ? data.isConnectTwilio : false;
            
            let quickbookOrXero = QuickbookOrXero.None;
            let quickbookOrXeroName = 'None';
            if(isConnectQuickbook){
                quickbookOrXero = QuickbookOrXero.Quickbook;
                quickbookOrXeroName = 'Quickbook Online';
            } else if(isConnectXero){
                quickbookOrXero = QuickbookOrXero.Xero;
                quickbookOrXeroName = 'Xero Online';
            } else {
                quickbookOrXero = QuickbookOrXero.None;
                quickbookOrXeroName = 'None';
            }


            let quickBookApp = (data && data.quickBookApp) ? data.quickBookApp : null;
            let quickBookDefaultTaxCodeId = (quickBookApp && quickBookApp.quickBookDefaultTaxCodeId) ? quickBookApp.quickBookDefaultTaxCodeId : null;
            let quickBookDefaultTermId = (quickBookApp && quickBookApp.quickBookDefaultTermId) ? quickBookApp.quickBookDefaultTermId : null;
            let customerMemo = (quickBookApp && quickBookApp.customerMemo && quickBookApp.customerMemo != '') ? quickBookApp.customerMemo : '';
            let quickBookDefaultChartOfAccountId = (quickBookApp && quickBookApp.quickBookDefaultChartOfAccountId) ? quickBookApp.quickBookDefaultChartOfAccountId : null;
            let quickBookDefaultPurchaseChartOfAccountId = (quickBookApp && quickBookApp.quickBookDefaultPurchaseChartOfAccountId) ? quickBookApp.quickBookDefaultPurchaseChartOfAccountId : null;

            let xeroApp = (data && data.xeroApp) ? data.xeroApp : null;
            let xeroDefaultChartOfAccountId = (xeroApp && xeroApp.xeroDefaultChartOfAccountId) ? xeroApp.xeroDefaultChartOfAccountId : null;
            let xeroDefaultPurchaseChartOfAccountId = (xeroApp && xeroApp.xeroDefaultPurchaseChartOfAccountId) ? xeroApp.xeroDefaultPurchaseChartOfAccountId : null;

            let xeroDefaultTaxType = (xeroApp && xeroApp.xeroDefaultTaxType) ? xeroApp.xeroDefaultTaxType : null;
            let xeroDefaultPurchaseTaxType = (xeroApp && xeroApp.xeroDefaultPurchaseTaxType) ? xeroApp.xeroDefaultPurchaseTaxType : null;
            
            let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm : '0';
            let xeroPaymentTermType = (xeroApp) ? xeroApp.xeroPaymentTermType.toString() : '0';

            let deliveryOrderAccountDocSettingVariables = [];
            let printedReceiptAccountDocSettingVariables = [];
            let weightChitAccountDocSettingVariables = [];
            let weightChitAccountDocSettinglayout = WeighChitLayout.Default;
            let accountDocSettings = (data && data.accountDocSettings && data.accountDocSettings.length > 0) ? data.accountDocSettings : [];
            if(data.accountDocSettings && accountDocSettings.length > 0){
                let deliveryOrderItems = accountDocSettings.filter(x => x.type === AccountDocSettingType.DeliveryOrder);
                if(deliveryOrderItems && deliveryOrderItems.length > 0){
                    deliveryOrderAccountDocSettingVariables = deliveryOrderItems[0].accountDocSettingVariables;
                }

                let printedReceiptItems = accountDocSettings.filter(x => x.type === AccountDocSettingType.JobReceipt);
                if(printedReceiptItems && printedReceiptItems.length > 0){
                    printedReceiptAccountDocSettingVariables = printedReceiptItems[0].accountDocSettingVariables;
                }

                let weightChitItems = accountDocSettings.filter(x => x.type === AccountDocSettingType.WeightChit);
                if(weightChitItems && weightChitItems.length > 0){
                    weightChitAccountDocSettingVariables = weightChitItems[0].accountDocSettingVariables;
                    weightChitAccountDocSettinglayout = weightChitItems[0].layout;
                }
            }


            let deliveryOrderLabelDeliveryOrderItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelDELIVERY_ORDER');
            let deliveryOrderLabelDeliveryOrder = Utils.findSettingsItem(deliveryOrderLabelDeliveryOrderItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelDeliveryOrderSortOrder = Utils.findSettingsItem(deliveryOrderLabelDeliveryOrderItem, null, 'sortOrder');
            
            
            let deliveryOrderShowCustomerNameItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowCustomerName');
            let deliveryOrderShowCustomerName = Utils.findSettingsItem(deliveryOrderShowCustomerNameItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowCustomerNameSortOrder = Utils.findSettingsItem(deliveryOrderShowCustomerNameItem, null, 'sortOrder');
            
            let deliveryOrderLabelCustomerNameItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelCustomer_Name');
            let deliveryOrderLabelCustomerName = Utils.findSettingsItem(deliveryOrderLabelCustomerNameItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelCustomerNameSortOrder = Utils.findSettingsItem(deliveryOrderLabelCustomerNameItem, null, 'sortOrder');
            
            
            let deliveryOrderShowCustomerAddressItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowCustomerAddress');
            let deliveryOrderShowCustomerAddress = Utils.findSettingsItem(deliveryOrderShowCustomerAddressItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowCustomerAddressSortOrder = Utils.findSettingsItem(deliveryOrderShowCustomerAddressItem, null, 'sortOrder');
            
            let deliveryOrderLabelCustomerAddressItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelCustomer_Address');
            let deliveryOrderLabelCustomerAddress = Utils.findSettingsItem(deliveryOrderLabelCustomerAddressItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelCustomerAddressSortOrder = Utils.findSettingsItem(deliveryOrderLabelCustomerAddressItem, null, 'sortOrder');
            
            
            let deliveryOrderShowProjectSiteItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowProjectSite');
            let deliveryOrderShowProjectSite = Utils.findSettingsItem(deliveryOrderShowProjectSiteItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowProjectSiteSortOrder = Utils.findSettingsItem(deliveryOrderShowProjectSiteItem, null, 'sortOrder');
            
            let deliveryOrderLabelProjectSiteItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelProject_Site');
            let deliveryOrderLabelProjectSite = Utils.findSettingsItem(deliveryOrderLabelProjectSiteItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelProjectSiteSortOrder = Utils.findSettingsItem(deliveryOrderLabelProjectSiteItem, null, 'sortOrder');
            

            let deliveryOrderShowJobTypeItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowJobType');
            let deliveryOrderShowJobType = Utils.findSettingsItem(deliveryOrderShowJobTypeItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowJobTypeSortOrder = Utils.findSettingsItem(deliveryOrderShowJobTypeItem, null, 'sortOrder');
            
            let deliveryOrderLabelJobTypeItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelJob_Type');
            let deliveryOrderLabelJobType = Utils.findSettingsItem(deliveryOrderLabelJobTypeItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelJobTypeSortOrder = Utils.findSettingsItem(deliveryOrderLabelJobTypeItem, null, 'sortOrder');
            

            let deliveryOrderShowJobCompletionItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowJobCompletion');
            let deliveryOrderShowJobCompletion = Utils.findSettingsItem(deliveryOrderShowJobCompletionItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowJobCompletionSortOrder = Utils.findSettingsItem(deliveryOrderShowJobCompletionItem, null, 'sortOrder');

            let deliveryOrderShowJobTimeItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowJobCompletionTime');
            let deliveryOrderShowJobTime = Utils.findSettingsItem(deliveryOrderShowJobTimeItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowJobTimeSortOrder = Utils.findSettingsItem(deliveryOrderShowJobTimeItem, null, 'sortOrder');
            
            let deliveryOrderLabelJobCompletionItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelJob_Completion');
            let deliveryOrderLabelJobCompletion = Utils.findSettingsItem(deliveryOrderLabelJobCompletionItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelJobCompletionSortOrder = Utils.findSettingsItem(deliveryOrderLabelJobCompletionItem, null, 'sortOrder');
            

            let deliveryOrderShowDriverItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowDriver');
            let deliveryOrderShowDriver = Utils.findSettingsItem(deliveryOrderShowDriverItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowDriverSortOrder = Utils.findSettingsItem(deliveryOrderShowDriverItem, null, 'sortOrder');
            
            let deliveryOrderLabelDriverItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelDriver');
            let deliveryOrderLabelDriver = Utils.findSettingsItem(deliveryOrderLabelDriverItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelDriverSortOrder = Utils.findSettingsItem(deliveryOrderLabelDriverItem, null, 'sortOrder');
            

            let deliveryOrderShowVehicleItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowVehicle');
            let deliveryOrderShowVehicle = Utils.findSettingsItem(deliveryOrderShowVehicleItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowVehicleSortOrder = Utils.findSettingsItem(deliveryOrderShowVehicleItem, null, 'sortOrder');
            
            let deliveryOrderLabelVehicleItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelVehicle');
            let deliveryOrderLabelVehicle = Utils.findSettingsItem(deliveryOrderLabelVehicleItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelVehicleSortOrder = Utils.findSettingsItem(deliveryOrderLabelVehicleItem, null, 'sortOrder');
            

            let deliveryOrderShowBinNumberINItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowBinNumberIN');
            let deliveryOrderShowBinNumberIN = Utils.findSettingsItem(deliveryOrderShowBinNumberINItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowBinNumberINSortOrder = Utils.findSettingsItem(deliveryOrderShowBinNumberINItem, null, 'sortOrder');
            
            let deliveryOrderLabelBinNumberINItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelBin_Number_IN');
            let deliveryOrderLabelBinNumberIN = Utils.findSettingsItem(deliveryOrderLabelBinNumberINItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelBinNumberINSortOrder = Utils.findSettingsItem(deliveryOrderLabelBinNumberINItem, null, 'sortOrder');
            

            let deliveryOrderShowBinNumberOUTItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowBinNumberOUT');
            let deliveryOrderShowBinNumberOUT = Utils.findSettingsItem(deliveryOrderShowBinNumberOUTItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowBinNumberOUTSortOrder = Utils.findSettingsItem(deliveryOrderShowBinNumberOUTItem, null, 'sortOrder');
            
            let deliveryOrderLabelBinNumberOUTItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelBin_Number_OUT');
            let deliveryOrderLabelBinNumberOUT = Utils.findSettingsItem(deliveryOrderLabelBinNumberOUTItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelBinNumberOUTSortOrder = Utils.findSettingsItem(deliveryOrderLabelBinNumberOUTItem, null, 'sortOrder');
            

            let deliveryOrderShowSiteContactItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowSiteContact');
            let deliveryOrderShowSiteContact = Utils.findSettingsItem(deliveryOrderShowSiteContactItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowSiteContactSortOrder = Utils.findSettingsItem(deliveryOrderShowSiteContactItem, null, 'sortOrder');
            
            let deliveryOrderLabelSiteContactItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelSite_Contact');
            let deliveryOrderLabelSiteContact = Utils.findSettingsItem(deliveryOrderLabelSiteContactItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelSiteContactSortOrder = Utils.findSettingsItem(deliveryOrderLabelSiteContactItem, null, 'sortOrder');
            

            let deliveryOrderShowPaymentTermsItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowPaymentTerms');
            let deliveryOrderShowPaymentTerms = Utils.findSettingsItem(deliveryOrderShowPaymentTermsItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowPaymentTermsSortOrder = Utils.findSettingsItem(deliveryOrderShowPaymentTermsItem, null, 'sortOrder');
            
            let deliveryOrderLabelPaymentTermsItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelPayment_Terms');
            let deliveryOrderLabelPaymentTerms = Utils.findSettingsItem(deliveryOrderLabelPaymentTermsItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelPaymentTermsSortOrder = Utils.findSettingsItem(deliveryOrderLabelPaymentTermsItem, null, 'sortOrder');
            

            let deliveryOrderShowAmountCollectedItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowAmountCollected');
            let deliveryOrderShowAmountCollected = Utils.findSettingsItem(deliveryOrderShowAmountCollectedItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowAmountCollectedSortOrder = Utils.findSettingsItem(deliveryOrderShowAmountCollectedItem, null, 'sortOrder');
            
            let deliveryOrderLabelAmountCollectedItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelCollections');
            let deliveryOrderLabelAmountCollected = Utils.findSettingsItem(deliveryOrderLabelAmountCollectedItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelAmountCollectedSortOrder = Utils.findSettingsItem(deliveryOrderLabelAmountCollectedItem, null, 'sortOrder');
            

            let deliveryOrderShowStandardServicesBinTypeItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowStandardServicesBinType');
            let deliveryOrderShowStandardServicesBinType = Utils.findSettingsItem(deliveryOrderShowStandardServicesBinTypeItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowStandardServicesBinTypeSortOrder = Utils.findSettingsItem(deliveryOrderShowStandardServicesBinTypeItem, null, 'sortOrder');
            

            let deliveryOrderShowStandardServicesWasteTypeItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowStandardServicesWasteType');
            let deliveryOrderShowStandardServicesWasteType = Utils.findSettingsItem(deliveryOrderShowStandardServicesWasteTypeItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowStandardServicesWasteTypeSortOrder = Utils.findSettingsItem(deliveryOrderShowStandardServicesWasteTypeItem, null, 'sortOrder');
            

            let deliveryOrderShowStandardServicesQtyItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowStandardServicesQty');
            let deliveryOrderShowStandardServicesQty = Utils.findSettingsItem(deliveryOrderShowStandardServicesQtyItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowStandardServicesQtySortOrder = Utils.findSettingsItem(deliveryOrderShowStandardServicesQtyItem, null, 'sortOrder');
            

            let deliveryOrderShowStandardServicesRateItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowStandardServicesRate');
            let deliveryOrderShowStandardServicesRate = Utils.findSettingsItem(deliveryOrderShowStandardServicesRateItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowStandardServicesRateSortOrder = Utils.findSettingsItem(deliveryOrderShowStandardServicesRateItem, null, 'sortOrder');
            

            let deliveryOrderShowStandardServicesPriceItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowStandardServicesPrice');
            let deliveryOrderShowStandardServicesPrice = Utils.findSettingsItem(deliveryOrderShowStandardServicesPriceItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowStandardServicesPriceSortOrder = Utils.findSettingsItem(deliveryOrderShowStandardServicesPriceItem, null, 'sortOrder');
            
            
            let deliveryOrderShowAdditionalServiceTableItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowAdditionalServiceTable');
            let deliveryOrderShowAdditionalServiceTable = Utils.findSettingsItem(deliveryOrderShowAdditionalServiceTableItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowAdditionalServiceTableSortOrder = Utils.findSettingsItem(deliveryOrderShowAdditionalServiceTableItem, null, 'sortOrder');
            

            let deliveryOrderShowAdditionalServicesDescriptionItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowAdditionalServicesDescription');
            let deliveryOrderShowAdditionalServicesDescription = Utils.findSettingsItem(deliveryOrderShowAdditionalServicesDescriptionItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowAdditionalServicesDescriptionSortOrder = Utils.findSettingsItem(deliveryOrderShowAdditionalServicesDescriptionItem, null, 'sortOrder');
            

            let deliveryOrderShowAdditionalServicesQtyItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowAdditionalServicesQty');
            let deliveryOrderShowAdditionalServicesQty = Utils.findSettingsItem(deliveryOrderShowAdditionalServicesQtyItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowAdditionalServicesQtySortOrder = Utils.findSettingsItem(deliveryOrderShowAdditionalServicesQtyItem, null, 'sortOrder');
            

            let deliveryOrderShowAdditionalServicesRateItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowAdditionalServicesRate');
            let deliveryOrderShowAdditionalServicesRate = Utils.findSettingsItem(deliveryOrderShowAdditionalServicesRateItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowAdditionalServicesRateSortOrder = Utils.findSettingsItem(deliveryOrderShowAdditionalServicesRateItem, null, 'sortOrder');
            

            let deliveryOrderShowAdditionalServicesPriceItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowAdditionalServicesPrice');
            let deliveryOrderShowAdditionalServicesPrice = Utils.findSettingsItem(deliveryOrderShowAdditionalServicesPriceItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowAdditionalServicesPriceSortOrder = Utils.findSettingsItem(deliveryOrderShowAdditionalServicesPriceItem, null, 'sortOrder');
            
            
            let deliveryOrderShowSignatureItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowSignature');
            let deliveryOrderShowSignature = Utils.findSettingsItem(deliveryOrderShowSignatureItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowSignatureSortOrder = Utils.findSettingsItem(deliveryOrderShowSignatureItem, null, 'sortOrder');
            

            let deliveryOrderShowProofOfDeliveryItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowProofOfDelivery');
            let deliveryOrderShowProofOfDelivery = Utils.findSettingsItem(deliveryOrderShowProofOfDeliveryItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowProofOfDeliverySortOrder = Utils.findSettingsItem(deliveryOrderShowProofOfDeliveryItem, null, 'sortOrder');
            
            let deliveryOrderLabelProofOfDeliveryItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'LabelPROOF_OF_DELIVERY');
            let deliveryOrderLabelProofOfDelivery = Utils.findSettingsItem(deliveryOrderLabelProofOfDeliveryItem, AccountDocSettingVariables.Label);
            let deliveryOrderLabelProofOfDeliverySortOrder = Utils.findSettingsItem(deliveryOrderLabelProofOfDeliveryItem, null, 'sortOrder');
            

            let deliveryOrderShowMessageToCustomerItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowMessageToCustomer');
            let deliveryOrderShowMessageToCustomer = Utils.findSettingsItem(deliveryOrderShowMessageToCustomerItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowMessageToCustomerSortOrder = Utils.findSettingsItem(deliveryOrderShowMessageToCustomerItem, null, 'sortOrder');
            
            let deliveryOrderLabelMessageToCustomerItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'StringMessageToCustomer');
            let deliveryOrderLabelMessageToCustomer = Utils.findSettingsItem(deliveryOrderLabelMessageToCustomerItem, AccountDocSettingVariables.String);
            let deliveryOrderLabelMessageToCustomerSortOrder = Utils.findSettingsItem(deliveryOrderLabelMessageToCustomerItem, null, 'sortOrder');
            
            let deliveryOrderShowDisclaimerItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'ShowDisclaimer');
            let deliveryOrderShowDisclaimer = Utils.findSettingsItem(deliveryOrderShowDisclaimerItem, AccountDocSettingVariables.Show);
            let deliveryOrderShowDisclaimerSortOrder = Utils.findSettingsItem(deliveryOrderShowDisclaimerItem, null, 'sortOrder');
            
            let deliveryOrderLabelDisclaimerItem = Utils.findSettings(deliveryOrderAccountDocSettingVariables, 'StringDisclaimerText');
            let deliveryOrderLabelDisclaimer = Utils.findSettingsItem(deliveryOrderLabelDisclaimerItem, AccountDocSettingVariables.String);
            let deliveryOrderLabelDisclaimerSortOrder = Utils.findSettingsItem(deliveryOrderLabelDisclaimerItem, null, 'sortOrder');
            
            
            let deliveryOrderMessageToCustomerPlainText = h2p(deliveryOrderLabelMessageToCustomer);
            let deliveryOrderMessageToCustomerHTML = deliveryOrderLabelMessageToCustomer;
            let deliveryOrderMessageToCustomer = Utils.createEditorState(deliveryOrderMessageToCustomerHTML);
            
            let deliveryOrderDisclaimerPlainText = h2p(deliveryOrderLabelDisclaimer);
            let deliveryOrderDisclaimerHTML = deliveryOrderLabelDisclaimer;
            let deliveryOrderDisclaimer = Utils.createEditorState(deliveryOrderDisclaimerHTML);
            

            
            let printedReceiptShowCustomerNameItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowCustomerName');
            let printedReceiptShowCustomerName = Utils.findSettingsItem(printedReceiptShowCustomerNameItem, AccountDocSettingVariables.Show);
            let printedReceiptShowCustomerNameSortOrder = Utils.findSettingsItem(printedReceiptShowCustomerNameItem, null, 'sortOrder');
            

            let printedReceiptLabelCustomerAddressItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelSite_Address');
            let printedReceiptLabelCustomerAddress = Utils.findSettingsItem(printedReceiptLabelCustomerAddressItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelCustomerAddressSortOrder = Utils.findSettingsItem(printedReceiptLabelCustomerAddressItem, null, 'sortOrder');
            

            let printedReceiptShowDriverItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowDriver');
            let printedReceiptShowDriver = Utils.findSettingsItem(printedReceiptShowDriverItem, AccountDocSettingVariables.Show);
            let printedReceiptShowDriverSortOrder = Utils.findSettingsItem(printedReceiptShowDriverItem, null, 'sortOrder');

            let printedReceiptLabelDriverItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelDriver');
            let printedReceiptLabelDriver = Utils.findSettingsItem(printedReceiptLabelDriverItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelDriverSortOrder = Utils.findSettingsItem(printedReceiptLabelDriverItem, null, 'sortOrder');
            

            let printedReceiptShowVehicleItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowVehicle');
            let printedReceiptShowVehicle = Utils.findSettingsItem(printedReceiptShowVehicleItem, AccountDocSettingVariables.Show);
            let printedReceiptShowVehicleSortOrder = Utils.findSettingsItem(printedReceiptShowVehicleItem, null, 'sortOrder');

            let printedReceiptLabelVehicleItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelVehicle');
            let printedReceiptLabelVehicle = Utils.findSettingsItem(printedReceiptLabelVehicleItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelVehicleSortOrder = Utils.findSettingsItem(printedReceiptLabelVehicleItem, null, 'sortOrder');

            
            let printedReceiptShowJobTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowJobType');
            let printedReceiptShowJobType = Utils.findSettingsItem(printedReceiptShowJobTypeItem, AccountDocSettingVariables.Show);
            let printedReceiptShowJobTypeSortOrder = Utils.findSettingsItem(printedReceiptShowJobTypeItem, null, 'sortOrder');

            let printedReceiptLabelJobTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelJob_Type');
            let printedReceiptLabelJobType = Utils.findSettingsItem(printedReceiptLabelJobTypeItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelJobTypeSortOrder = Utils.findSettingsItem(printedReceiptLabelJobTypeItem, null, 'sortOrder');

            
            let printedReceiptShowPaymentTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowPaymentType');
            let printedReceiptShowPaymentType = Utils.findSettingsItem(printedReceiptShowPaymentTypeItem, AccountDocSettingVariables.Show);
            let printedReceiptShowPaymentTypeSortOrder = Utils.findSettingsItem(printedReceiptShowPaymentTypeItem, null, 'sortOrder');

            let printedReceiptLabelPaymentTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelPayment_Type');
            let printedReceiptLabelPaymentType = Utils.findSettingsItem(printedReceiptLabelPaymentTypeItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelPaymentTypeSortOrder = Utils.findSettingsItem(printedReceiptLabelPaymentTypeItem, null, 'sortOrder');

            
            let printedReceiptShowAmountCollectedItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowAmountCollected');
            let printedReceiptShowAmountCollected = Utils.findSettingsItem(printedReceiptShowAmountCollectedItem, AccountDocSettingVariables.Show);
            let printedReceiptShowAmountCollectedSortOrder = Utils.findSettingsItem(printedReceiptShowAmountCollectedItem, null, 'sortOrder');

            let printedReceiptLabelAmountCollectedItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelCollected_Cash');
            let printedReceiptLabelAmountCollected = Utils.findSettingsItem(printedReceiptLabelAmountCollectedItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelAmountCollectedSortOrder = Utils.findSettingsItem(printedReceiptLabelAmountCollectedItem, null, 'sortOrder');
            

            let printedReceiptShowBinDeliveredItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowBinDelivered');
            let printedReceiptShowBinDelivered = Utils.findSettingsItem(printedReceiptShowBinDeliveredItem, AccountDocSettingVariables.Show);
            let printedReceiptShowBinDeliveredSortOrder = Utils.findSettingsItem(printedReceiptShowBinDeliveredItem, null, 'sortOrder');

            let printedReceiptLabelBinDeliveredItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelBin_Delivered');
            let printedReceiptLabelBinDelivered = Utils.findSettingsItem(printedReceiptLabelBinDeliveredItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelBinDeliveredSortOrder = Utils.findSettingsItem(printedReceiptLabelBinDeliveredItem, null, 'sortOrder');

            
            let printedReceiptShowBinDeliveredTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowBinDeliveredType');
            let printedReceiptShowBinDeliveredType = Utils.findSettingsItem(printedReceiptShowBinDeliveredTypeItem, AccountDocSettingVariables.Show);
            let printedReceiptShowBinDeliveredTypeSortOrder = Utils.findSettingsItem(printedReceiptShowBinDeliveredTypeItem, null, 'sortOrder');

            let printedReceiptLabelBinDeliveredTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelBin_Type_Delivered');
            let printedReceiptLabelBinDeliveredType = Utils.findSettingsItem(printedReceiptLabelBinDeliveredTypeItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelBinDeliveredTypeSortOrder = Utils.findSettingsItem(printedReceiptLabelBinDeliveredTypeItem, null, 'sortOrder');
            

            let printedReceiptShowPlannedWasteTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowPlannedWasteType');
            let printedReceiptShowPlannedWasteType = Utils.findSettingsItem(printedReceiptShowPlannedWasteTypeItem, AccountDocSettingVariables.Show);
            let printedReceiptShowPlannedWasteTypeSortOrder = Utils.findSettingsItem(printedReceiptShowPlannedWasteTypeItem, null, 'sortOrder');

            let printedReceiptLabelPlannedWasteTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelPlanned_Waste_Type');
            let printedReceiptLabelPlannedWasteType = Utils.findSettingsItem(printedReceiptLabelPlannedWasteTypeItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelPlannedWasteTypeSortOrder = Utils.findSettingsItem(printedReceiptLabelPlannedWasteTypeItem, null, 'sortOrder');
            

            let printedReceiptShowBinCollectedItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowBinCollected');
            let printedReceiptShowBinCollected = Utils.findSettingsItem(printedReceiptShowBinCollectedItem, AccountDocSettingVariables.Show);
            let printedReceiptShowBinCollectedSortOrder = Utils.findSettingsItem(printedReceiptShowBinCollectedItem, null, 'sortOrder');

            let printedReceiptLabelBinCollectedItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelBin_Collected');
            let printedReceiptLabelBinCollected = Utils.findSettingsItem(printedReceiptLabelBinCollectedItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelBinCollectedSortOrder = Utils.findSettingsItem(printedReceiptLabelBinCollectedItem, null, 'sortOrder');

            
            let printedReceiptShowBinCollectedTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowBinCollectedType');
            let printedReceiptShowBinCollectedType = Utils.findSettingsItem(printedReceiptShowBinCollectedTypeItem, AccountDocSettingVariables.Show);
            let printedReceiptShowBinCollectedTypeSortOrder = Utils.findSettingsItem(printedReceiptShowBinCollectedTypeItem, null, 'sortOrder');

            let printedReceiptLabelBinCollectedTypeItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelBin_Type_Collected');
            let printedReceiptLabelBinCollectedType = Utils.findSettingsItem(printedReceiptLabelBinCollectedTypeItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelBinCollectedTypeSortOrder = Utils.findSettingsItem(printedReceiptLabelBinCollectedTypeItem, null, 'sortOrder');
            

            let printedReceiptShowWasteTypeCollectedItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowWasteTypeCollected');
            let printedReceiptShowWasteTypeCollected = Utils.findSettingsItem(printedReceiptShowWasteTypeCollectedItem, AccountDocSettingVariables.Show);
            let printedReceiptShowWasteTypeCollectedSortOrder = Utils.findSettingsItem(printedReceiptShowWasteTypeCollectedItem, null, 'sortOrder');

            let printedReceiptLabelWasteTypeCollectedItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'LabelWaste_Type_Collected');
            let printedReceiptLabelWasteTypeCollected = Utils.findSettingsItem(printedReceiptLabelWasteTypeCollectedItem, AccountDocSettingVariables.Label);
            let printedReceiptLabelWasteTypeCollectedSortOrder = Utils.findSettingsItem(printedReceiptLabelWasteTypeCollectedItem, null, 'sortOrder');
            

            let printedReceiptShowAdditionalServiceItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowAdditionalService');
            let printedReceiptShowAdditionalService = Utils.findSettingsItem(printedReceiptShowAdditionalServiceItem, AccountDocSettingVariables.Show);
            let printedReceiptShowAdditionalServiceSortOrder = Utils.findSettingsItem(printedReceiptShowAdditionalServiceItem, null, 'sortOrder');
            

            let printedReceiptShowSignatureItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowSignature');
            let printedReceiptShowSignature = Utils.findSettingsItem(printedReceiptShowSignatureItem, AccountDocSettingVariables.Show);
            let printedReceiptShowSignatureSortOrder = Utils.findSettingsItem(printedReceiptShowSignatureItem, null, 'sortOrder');
            

            let printedReceiptShowSiteContactItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowSiteContact');
            let printedReceiptShowSiteContact = Utils.findSettingsItem(printedReceiptShowSiteContactItem, AccountDocSettingVariables.Show);
            let printedReceiptShowSiteContactSortOrder = Utils.findSettingsItem(printedReceiptShowSiteContactItem, null, 'sortOrder');
            

            let printedReceiptShowSiteTelephoneItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'ShowSiteTelephone');
            let printedReceiptShowSiteTelephone = Utils.findSettingsItem(printedReceiptShowSiteTelephoneItem, AccountDocSettingVariables.Show);
            let printedReceiptShowSiteTelephoneSortOrder = Utils.findSettingsItem(printedReceiptShowSiteTelephoneItem, null, 'sortOrder');
            

            let printedReceiptLabelDisclaimerItem = Utils.findSettings(printedReceiptAccountDocSettingVariables, 'StringDisclaimerText');
            let printedReceiptLabelDisclaimer = Utils.findSettingsItem(printedReceiptLabelDisclaimerItem, AccountDocSettingVariables.String);
            let printedReceiptLabelDisclaimerSortOrder = Utils.findSettingsItem(printedReceiptLabelDisclaimerItem, null, 'sortOrder');
            


            let accountEmailTemplatesVariables = (data && data.accountEmailTemplates && data.accountEmailTemplates.length > 0) ? data.accountEmailTemplates[0] : null;
            let emailSettingsSubject = (accountEmailTemplatesVariables && accountEmailTemplatesVariables.subject && accountEmailTemplatesVariables.subject !==  '') ? accountEmailTemplatesVariables.subject : '';
            let emailSettingsFrom = (accountEmailTemplatesVariables && accountEmailTemplatesVariables.fromName && accountEmailTemplatesVariables.fromName !==  '') ? accountEmailTemplatesVariables.fromName : '';
            // let emailSettingsCc = (accountEmailTemplatesVariables && accountEmailTemplatesVariables.recipientCc && accountEmailTemplatesVariables.recipientCc !==  '') ? accountEmailTemplatesVariables.recipientCc : '';
            let emailSettingsCc = (accountEmailTemplatesVariables && accountEmailTemplatesVariables.recipientCc && accountEmailTemplatesVariables.recipientCc !== '') ? accountEmailTemplatesVariables.recipientCc.split(',') : [];
            let emailSettingsMessageHTML = (accountEmailTemplatesVariables && accountEmailTemplatesVariables.message && accountEmailTemplatesVariables.message !==  '') ? accountEmailTemplatesVariables.message : '';
            let emailSettingsDeliveryOrder = (accountEmailTemplatesVariables && ((accountEmailTemplatesVariables.includePdfAttachmentDeliveryOrder === false) | (accountEmailTemplatesVariables.includePdfAttachmentDeliveryOrder === true))) ? accountEmailTemplatesVariables.includePdfAttachmentDeliveryOrder : true;
            let emailSettingsIncludePhotos = (accountEmailTemplatesVariables && ((accountEmailTemplatesVariables.includePdfAttachmentPhoto === false) | (accountEmailTemplatesVariables.includePdfAttachmentPhoto === true))) ? accountEmailTemplatesVariables.includePdfAttachmentPhoto : false;
            let emailSettingsWeighChit = (accountEmailTemplatesVariables && ((accountEmailTemplatesVariables.includePdfAttachmentWeightChit === false) | (accountEmailTemplatesVariables.includePdfAttachmentWeightChit === true))) ? accountEmailTemplatesVariables.includePdfAttachmentWeightChit : false;
            
            let emailSettingsMessagePlainText = h2p(emailSettingsMessageHTML);
            let emailSettingsMessage = Utils.createEditorState(emailSettingsMessageHTML);

            

            let weightChitLabelWeightTicketItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelWEIGH_TICKET');
            let weightChitLabelWeightTicket = Utils.findSettingsItem(weightChitLabelWeightTicketItem, AccountDocSettingVariables.Label);
            let weightChitLabelWeightTicketSortOrder = Utils.findSettingsItem(weightChitLabelWeightTicketItem, null, 'sortOrder');
            let weightChitLabelWeightTicketGroup = Utils.findSettingsItem(weightChitLabelWeightTicketItem, null, 'group');


            let weightChitShowCustomerNameItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowCustomerName');
            let weightChitShowCustomerName = Utils.findSettingsItem(weightChitShowCustomerNameItem, AccountDocSettingVariables.Show);
            let weightChitShowCustomerNameSortOrder = Utils.findSettingsItem(weightChitShowCustomerNameItem, null, 'sortOrder');
            let weightChitShowCustomerNameGroup = Utils.findSettingsItem(weightChitShowCustomerNameItem, null, 'group');

            let weightChitLabelCustomerNameItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelCustomer_Name');
            let weightChitLabelCustomerName = Utils.findSettingsItem(weightChitLabelCustomerNameItem, AccountDocSettingVariables.Label);
            let weightChitLabelCustomerNameSortOrder = Utils.findSettingsItem(weightChitLabelCustomerNameItem, null, 'sortOrder');
            let weightChitLabelCustomerNameGroup = Utils.findSettingsItem(weightChitLabelCustomerNameItem, null, 'group');
            

            let weightChitShowCustomerAddressItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowCustomerAddress');
            let weightChitShowCustomerAddress = Utils.findSettingsItem(weightChitShowCustomerAddressItem, AccountDocSettingVariables.Show);
            let weightChitShowCustomerAddressSortOrder = Utils.findSettingsItem(weightChitShowCustomerAddressItem, null, 'sortOrder');
            let weightChitShowCustomerAddressGroup = Utils.findSettingsItem(weightChitShowCustomerAddressItem, null, 'group');

            let weightChitLabelCustomerAddressItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelCustomer_Address');
            let weightChitLabelCustomerAddress = Utils.findSettingsItem(weightChitLabelCustomerAddressItem, AccountDocSettingVariables.Label);
            let weightChitLabelCustomerAddressSortOrder = Utils.findSettingsItem(weightChitLabelCustomerAddressItem, null, 'sortOrder');
            let weightChitLabelCustomerAddressGroup = Utils.findSettingsItem(weightChitLabelCustomerAddressItem, null, 'group');
            

            let weightChitShowSiteAddressItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowSiteAddress');
            let weightChitShowSiteAddress = Utils.findSettingsItem(weightChitShowSiteAddressItem, AccountDocSettingVariables.Show);
            let weightChitShowSiteAddressSortOrder = Utils.findSettingsItem(weightChitShowSiteAddressItem, null, 'sortOrder');
            let weightChitShowSiteAddressGroup = Utils.findSettingsItem(weightChitShowSiteAddressItem, null, 'group');

            let weightChitLabelSiteAddressItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelSite_Address');
            let weightChitLabelSiteAddress = Utils.findSettingsItem(weightChitLabelSiteAddressItem, AccountDocSettingVariables.Label);
            let weightChitLabelSiteAddressSortOrder = Utils.findSettingsItem(weightChitLabelSiteAddressItem, null, 'sortOrder');
            let weightChitLabelSiteAddressGroup = Utils.findSettingsItem(weightChitLabelSiteAddressItem, null, 'group');
            

            let weightChitShowDONumberItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowDONumber');
            let weightChitShowDONumber = Utils.findSettingsItem(weightChitShowDONumberItem, AccountDocSettingVariables.Show);
            let weightChitShowDONumberSortOrder = Utils.findSettingsItem(weightChitShowDONumberItem, null, 'sortOrder');
            let weightChitShowDONumberGroup = Utils.findSettingsItem(weightChitShowDONumberItem, null, 'group');

            let weightChitLabelDONumberItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelDO_Number');
            let weightChitLabelDONumber = Utils.findSettingsItem(weightChitLabelDONumberItem, AccountDocSettingVariables.Label);
            let weightChitLabelDONumberSortOrder = Utils.findSettingsItem(weightChitLabelDONumberItem, null, 'sortOrder');
            let weightChitLabelDONumberGroup = Utils.findSettingsItem(weightChitLabelDONumberItem, null, 'group');

            
            let weightChitShowJobCompletionItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowJobCompletion');
            let weightChitShowJobCompletion = Utils.findSettingsItem(weightChitShowJobCompletionItem, AccountDocSettingVariables.Show);
            let weightChitShowJobCompletionSortOrder = Utils.findSettingsItem(weightChitShowJobCompletionItem, null, 'sortOrder');
            let weightChitShowJobCompletionGroup = Utils.findSettingsItem(weightChitShowJobCompletionItem, null, 'group');

            let weightChitShowJobTimeItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowJobCompletionTime');
            let weightChitShowJobTime = Utils.findSettingsItem(weightChitShowJobTimeItem, AccountDocSettingVariables.Show);
            let weightChitShowJobTimeSortOrder = Utils.findSettingsItem(weightChitShowJobTimeItem, null, 'sortOrder');
            let weightChitShowJobTimeGroup = Utils.findSettingsItem(weightChitShowJobTimeItem, null, 'group');

            let weightChitLabelJobCompletionItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelJob_Completion');
            let weightChitLabelJobCompletion = Utils.findSettingsItem(weightChitLabelJobCompletionItem, AccountDocSettingVariables.Label);
            let weightChitLabelJobCompletionSortOrder = Utils.findSettingsItem(weightChitLabelJobCompletionItem, null, 'sortOrder');
            let weightChitLabelJobCompletionGroup = Utils.findSettingsItem(weightChitLabelJobCompletionItem, null, 'group');
            

            let weightChitShowJobTypeItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowJobType');
            let weightChitShowJobType = Utils.findSettingsItem(weightChitShowJobTypeItem, AccountDocSettingVariables.Show);
            let weightChitShowJobTypeSortOrder = Utils.findSettingsItem(weightChitShowJobTypeItem, null, 'sortOrder');
            let weightChitShowJobTypeGroup = Utils.findSettingsItem(weightChitShowJobTypeItem, null, 'group');

            let weightChitLabelJobTypeItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelJob_Type');
            let weightChitLabelJobType = Utils.findSettingsItem(weightChitLabelJobTypeItem, AccountDocSettingVariables.Label);
            let weightChitLabelJobTypeSortOrder = Utils.findSettingsItem(weightChitLabelJobTypeItem, null, 'sortOrder');
            let weightChitLabelJobTypeGroup = Utils.findSettingsItem(weightChitLabelJobTypeItem, null, 'group');
            

            let weightChitShowBinINTypeItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowBinINType');
            let weightChitShowBinINType = Utils.findSettingsItem(weightChitShowBinINTypeItem, AccountDocSettingVariables.Show);
            let weightChitShowBinINTypeSortOrder = Utils.findSettingsItem(weightChitShowBinINTypeItem, null, 'sortOrder');
            let weightChitShowBinINTypeGroup = Utils.findSettingsItem(weightChitShowBinINTypeItem, null, 'group');

            let weightChitLabelBinINTypeItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelBin_IN_Type');
            let weightChitLabelBinINType = Utils.findSettingsItem(weightChitLabelBinINTypeItem, AccountDocSettingVariables.Label);
            let weightChitLabelBinINTypeSortOrder = Utils.findSettingsItem(weightChitLabelBinINTypeItem, null, 'sortOrder');
            let weightChitLabelBinINTypeGroup = Utils.findSettingsItem(weightChitLabelBinINTypeItem, null, 'group');
            

            let weightChitShowWasteINTypeItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowWasteINType');
            let weightChitShowWasteINType = Utils.findSettingsItem(weightChitShowWasteINTypeItem, AccountDocSettingVariables.Show);
            let weightChitShowWasteINTypeSortOrder = Utils.findSettingsItem(weightChitShowWasteINTypeItem, null, 'sortOrder');
            let weightChitShowWasteINTypeGroup = Utils.findSettingsItem(weightChitShowWasteINTypeItem, null, 'group');

            let weightChitLabelWasteINTypeItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelWaste_IN_Type');
            let weightChitLabelWasteINType = Utils.findSettingsItem(weightChitLabelWasteINTypeItem, AccountDocSettingVariables.Label);
            let weightChitLabelWasteINTypeSortOrder = Utils.findSettingsItem(weightChitLabelWasteINTypeItem, null, 'sortOrder');
            let weightChitLabelWasteINTypeGroup = Utils.findSettingsItem(weightChitLabelWasteINTypeItem, null, 'group');
            

            let weightChitShowWeighTimestampItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowWeighTimestamp');
            let weightChitShowWeighTimestamp = Utils.findSettingsItem(weightChitShowWeighTimestampItem, AccountDocSettingVariables.Show);
            let weightChitShowWeighTimestampSortOrder = Utils.findSettingsItem(weightChitShowWeighTimestampItem, null, 'sortOrder');
            let weightChitShowWeighTimestampGroup = Utils.findSettingsItem(weightChitShowWeighTimestampItem, null, 'group');

            let weightChitLabelWeighTimestampItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelWeigh_Timestamp');
            let weightChitLabelWeighTimestamp = Utils.findSettingsItem(weightChitLabelWeighTimestampItem, AccountDocSettingVariables.Label);
            let weightChitLabelWeighTimestampSortOrder = Utils.findSettingsItem(weightChitLabelWeighTimestampItem, null, 'sortOrder');
            let weightChitLabelWeighTimestampGroup = Utils.findSettingsItem(weightChitLabelWeighTimestampItem, null, 'group');
            

            let weightChitShowWeighOutTimestampItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowWeighOutTimestamp');
            let weightChitShowWeighOutTimestamp = Utils.findSettingsItem(weightChitShowWeighOutTimestampItem, AccountDocSettingVariables.Show);
            let weightChitShowWeighOutTimestampSortOrder = Utils.findSettingsItem(weightChitShowWeighOutTimestampItem, null, 'sortOrder');
            let weightChitShowWeighOutTimestampGroup = Utils.findSettingsItem(weightChitShowWeighOutTimestampItem, null, 'group');

            let weightChitLabelWeighOutTimestampItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelWeigh_Out_Timestamp');
            let weightChitLabelWeighOutTimestamp = Utils.findSettingsItem(weightChitLabelWeighOutTimestampItem, AccountDocSettingVariables.Label);
            let weightChitLabelWeighOutTimestampSortOrder = Utils.findSettingsItem(weightChitLabelWeighOutTimestampItem, null, 'sortOrder');
            let weightChitLabelWeighOutTimestampGroup = Utils.findSettingsItem(weightChitLabelWeighOutTimestampItem, null, 'group');
            

            let weightChitShowDriverItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowDriver');
            let weightChitShowDriver = Utils.findSettingsItem(weightChitShowDriverItem, AccountDocSettingVariables.Show);
            let weightChitShowDriverSortOrder = Utils.findSettingsItem(weightChitShowDriverItem, null, 'sortOrder');
            let weightChitShowDriverGroup = Utils.findSettingsItem(weightChitShowDriverItem, null, 'group');

            let weightChitLabelDriverItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelDriver');
            let weightChitLabelDriver = Utils.findSettingsItem(weightChitLabelDriverItem, AccountDocSettingVariables.Label);
            let weightChitLabelDriverSortOrder = Utils.findSettingsItem(weightChitLabelDriverItem, null, 'sortOrder');
            let weightChitLabelDriverGroup = Utils.findSettingsItem(weightChitLabelDriverItem, null, 'group');
            

            let weightChitShowVehicleItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowVehicle');
            let weightChitShowVehicle = Utils.findSettingsItem(weightChitShowVehicleItem, AccountDocSettingVariables.Show);
            let weightChitShowVehicleSortOrder = Utils.findSettingsItem(weightChitShowVehicleItem, null, 'sortOrder');
            let weightChitShowVehicleGroup = Utils.findSettingsItem(weightChitShowVehicleItem, null, 'group');

            let weightChitLabelVehicleItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelVehicle');
            let weightChitLabelVehicle = Utils.findSettingsItem(weightChitLabelVehicleItem, AccountDocSettingVariables.Label);
            let weightChitLabelVehicleSortOrder = Utils.findSettingsItem(weightChitLabelVehicleItem, null, 'sortOrder');
            let weightChitLabelVehicleGroup = Utils.findSettingsItem(weightChitLabelVehicleItem, null, 'group');
            

            let weightChitShowWeighByItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowWeighBy');
            let weightChitShowWeighBy = Utils.findSettingsItem(weightChitShowWeighByItem, AccountDocSettingVariables.Show);
            let weightChitShowWeighBySortOrder = Utils.findSettingsItem(weightChitShowWeighByItem, null, 'sortOrder');
            let weightChitShowWeighByGroup = Utils.findSettingsItem(weightChitShowWeighByItem, null, 'group');

            let weightChitLabelWeighByItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelWeigh_By');
            let weightChitLabelWeighBy = Utils.findSettingsItem(weightChitLabelWeighByItem, AccountDocSettingVariables.Label);
            let weightChitLabelWeighBySortOrder = Utils.findSettingsItem(weightChitLabelWeighByItem, null, 'sortOrder');
            let weightChitLabelWeighByGroup = Utils.findSettingsItem(weightChitLabelWeighByItem, null, 'group');
            

            let weightChitShowRemarksItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowRemarks');
            let weightChitShowRemarks = Utils.findSettingsItem(weightChitShowRemarksItem, AccountDocSettingVariables.Show);
            let weightChitShowRemarksSortOrder = Utils.findSettingsItem(weightChitShowRemarksItem, null, 'sortOrder');
            let weightChitShowRemarksGroup = Utils.findSettingsItem(weightChitShowRemarksItem, null, 'group');

            let weightChitLabelRemarksItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelRemarks');
            let weightChitLabelRemarks = Utils.findSettingsItem(weightChitLabelRemarksItem, AccountDocSettingVariables.Label);
            let weightChitLabelRemarksSortOrder = Utils.findSettingsItem(weightChitLabelRemarksItem, null, 'sortOrder');
            let weightChitLabelRemarksGroup = Utils.findSettingsItem(weightChitLabelRemarksItem, null, 'group');
            
                        
            let weightChitShowGrossItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowGrossWeight');
            let weightChitShowGross = Utils.findSettingsItem(weightChitShowGrossItem, AccountDocSettingVariables.Show);
            let weightChitShowGrossSortOrder = Utils.findSettingsItem(weightChitShowGrossItem, null, 'sortOrder');
            let weightChitShowGrossGroup = Utils.findSettingsItem(weightChitShowGrossItem, null, 'group');

            let weightChitLabelGrossItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelGross_Weight');
            let weightChitLabelGross = Utils.findSettingsItem(weightChitLabelGrossItem, AccountDocSettingVariables.Label);
            let weightChitLabelGrossSortOrder = Utils.findSettingsItem(weightChitLabelGrossItem, null, 'sortOrder');
            let weightChitLabelGrossGroup = Utils.findSettingsItem(weightChitLabelGrossItem, null, 'group');
            

            let weightChitShowTareItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowTareWeight');
            let weightChitShowTare = Utils.findSettingsItem(weightChitShowTareItem, AccountDocSettingVariables.Show);
            let weightChitShowTareSortOrder = Utils.findSettingsItem(weightChitShowTareItem, null, 'sortOrder');
            let weightChitShowTareGroup = Utils.findSettingsItem(weightChitShowTareItem, null, 'group');

            let weightChitLabelTareItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelTare_Weight');
            let weightChitLabelTare = Utils.findSettingsItem(weightChitLabelTareItem, AccountDocSettingVariables.Label);
            let weightChitLabelTareSortOrder = Utils.findSettingsItem(weightChitLabelTareItem, null, 'sortOrder');
            let weightChitLabelTareGroup = Utils.findSettingsItem(weightChitLabelTareItem, null, 'group');
            

            let weightChitShowNettItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowNettWeight');
            let weightChitShowNett = Utils.findSettingsItem(weightChitShowNettItem, AccountDocSettingVariables.Show);
            let weightChitShowNettSortOrder = Utils.findSettingsItem(weightChitShowNettItem, null, 'sortOrder');
            let weightChitShowNettGroup = Utils.findSettingsItem(weightChitShowNettItem, null, 'group');

            let weightChitLabelNettItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelNett_Weight');
            let weightChitLabelNett = Utils.findSettingsItem(weightChitLabelNettItem, AccountDocSettingVariables.Label);
            let weightChitLabelNettSortOrder = Utils.findSettingsItem(weightChitLabelNettItem, null, 'sortOrder');
            let weightChitLabelNettGroup = Utils.findSettingsItem(weightChitLabelNettItem, null, 'group');
            
            
            let weightChitShowNonBillableItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowNonBillableWeight');
            let weightChitShowNonBillable = Utils.findSettingsItem(weightChitShowNonBillableItem, AccountDocSettingVariables.Show);
            let weightChitShowNonBillableSortOrder = Utils.findSettingsItem(weightChitShowNonBillableItem, null, 'sortOrder');
            let weightChitShowNonBillableGroup = Utils.findSettingsItem(weightChitShowNonBillableItem, null, 'group');

            let weightChitLabelNonBillableItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelNonBillable_Weight');
            let weightChitLabelNonBillable = Utils.findSettingsItem(weightChitLabelNonBillableItem, AccountDocSettingVariables.Label);
            let weightChitLabelNonBillableSortOrder = Utils.findSettingsItem(weightChitLabelNonBillableItem, null, 'sortOrder');
            let weightChitLabelNonBillableGroup = Utils.findSettingsItem(weightChitLabelNonBillableItem, null, 'group');


            let weightChitShowBillableItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowBillableWeight');
            let weightChitShowBillable = Utils.findSettingsItem(weightChitShowBillableItem, AccountDocSettingVariables.Show);
            let weightChitShowBillableSortOrder = Utils.findSettingsItem(weightChitShowBillableItem, null, 'sortOrder');
            let weightChitShowBillableGroup = Utils.findSettingsItem(weightChitShowBillableItem, null, 'group');

            let weightChitLabelBillableItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelBillable_Weight');
            let weightChitLabelBillable = Utils.findSettingsItem(weightChitLabelBillableItem, AccountDocSettingVariables.Label);
            let weightChitLabelBillableSortOrder = Utils.findSettingsItem(weightChitLabelBillableItem, null, 'sortOrder');
            let weightChitLabelBillableGroup = Utils.findSettingsItem(weightChitLabelBillableItem, null, 'group');
            

            let weightChitShowRateItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowRate');
            let weightChitShowRate = Utils.findSettingsItem(weightChitShowRateItem, AccountDocSettingVariables.Show);
            let weightChitShowRateSortOrder = Utils.findSettingsItem(weightChitShowRateItem, null, 'sortOrder');
            let weightChitShowRateGroup = Utils.findSettingsItem(weightChitShowRateItem, null, 'group');

            let weightChitLabelRateItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelRate_Weight');
            let weightChitLabelRate = Utils.findSettingsItem(weightChitLabelRateItem, AccountDocSettingVariables.Label);
            let weightChitLabelRateSortOrder = Utils.findSettingsItem(weightChitLabelRateItem, null, 'sortOrder');
            let weightChitLabelRateGroup = Utils.findSettingsItem(weightChitLabelRateItem, null, 'group');
            

            let weightChitShowAmountItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowAmount');
            let weightChitShowAmount = Utils.findSettingsItem(weightChitShowAmountItem, AccountDocSettingVariables.Show);
            let weightChitShowAmountSortOrder = Utils.findSettingsItem(weightChitShowAmountItem, null, 'sortOrder');
            let weightChitShowAmountGroup = Utils.findSettingsItem(weightChitShowAmountItem, null, 'group');

            let weightChitLabelAmountItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'LabelAmount_Weight');
            let weightChitLabelAmount = Utils.findSettingsItem(weightChitLabelAmountItem, AccountDocSettingVariables.Label);
            let weightChitLabelAmountSortOrder = Utils.findSettingsItem(weightChitLabelAmountItem, null, 'sortOrder');
            let weightChitLabelAmountGroup = Utils.findSettingsItem(weightChitLabelAmountItem, null, 'group');
            

            let weightChitShowMessageToCustomerItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowMessageToCustomer');
            let weightChitShowMessageToCustomer = Utils.findSettingsItem(weightChitShowMessageToCustomerItem, AccountDocSettingVariables.Show);
            let weightChitShowMessageToCustomerSortOrder = Utils.findSettingsItem(weightChitShowMessageToCustomerItem, null, 'sortOrder');
            let weightChitShowMessageToCustomerGroup = Utils.findSettingsItem(weightChitShowMessageToCustomerItem, null, 'group');

            let weightChitLabelMessageToCustomerItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'StringMessageToCustomer');
            let weightChitLabelMessageToCustomer = Utils.findSettingsItem(weightChitLabelMessageToCustomerItem, AccountDocSettingVariables.String);
            let weightChitLabelMessageToCustomerSortOrder = Utils.findSettingsItem(weightChitLabelMessageToCustomerItem, null, 'sortOrder');
            let weightChitLabelMessageToCustomerGroup = Utils.findSettingsItem(weightChitLabelMessageToCustomerItem, null, 'group');
            
            let weightChitLabelStringHeaderTextItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'StringHeaderText');
            let weightChitLabelStringHeaderText = Utils.findSettingsItem(weightChitLabelStringHeaderTextItem, AccountDocSettingVariables.String);
            

            let weightChitShowDisclaimerItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'ShowDisclaimer');
            let weightChitShowDisclaimer = Utils.findSettingsItem(weightChitShowDisclaimerItem, AccountDocSettingVariables.Show);
            let weightChitShowFooterSortOrder = Utils.findSettingsItem(weightChitShowDisclaimerItem, null, 'sortOrder');
            let weightChitShowFooterGroup = Utils.findSettingsItem(weightChitShowDisclaimerItem, null, 'group');

            let weightChitLabelDisclaimerItem = Utils.findSettings(weightChitAccountDocSettingVariables, 'StringDisclaimerText');
            let weightChitLabelDisclaimer = Utils.findSettingsItem(weightChitLabelDisclaimerItem, AccountDocSettingVariables.String);
            let weightChitLabelFooterSortOrder = Utils.findSettingsItem(weightChitLabelDisclaimerItem, null, 'sortOrder');
            let weightChitLabelFooterGroup = Utils.findSettingsItem(weightChitLabelDisclaimerItem, null, 'group');
            
            
            let weightChitMessageToCustomerPlainText = h2p(weightChitLabelMessageToCustomer);
            let weightChitMessageToCustomerHTML = weightChitLabelMessageToCustomer;
            let weightChitMessageToCustomer = Utils.createEditorState(weightChitMessageToCustomerHTML);

            let weightChitStringHeaderTextPlainText = h2p(weightChitLabelStringHeaderText);
            let weightChitStringHeaderTextHTML = weightChitLabelStringHeaderText;
            let weightChitStringHeaderText = Utils.createEditorState(weightChitStringHeaderTextHTML);
            

            let weightChitDisclaimerPlainText = h2p(weightChitLabelDisclaimer);
            let weightChitDisclaimerHTML = weightChitLabelDisclaimer;
            let weightChitDisclaimer = Utils.createEditorState(weightChitDisclaimerHTML);

            
            let weightChitCustomFieldId1 = null;
            let weightChitCustomField1 = false;
            let weightChitCustomField1SortOrder = null;
            let weightChitCustomField1Group = null;
            
            let weightChitCustomFieldId2 = null;
            let weightChitCustomField2 = false;
            let weightChitCustomField2SortOrder = null;
            let weightChitCustomField2Group = null;
            
            let weightChitCustomFieldId3 = null;
            let weightChitCustomField3 = false;
            let weightChitCustomField3SortOrder = null;
            let weightChitCustomField3Group = null;
            
            let weightChitCustomFieldId4 = null;
            let weightChitCustomField4 = false;
            let weightChitCustomField4SortOrder = null;
            let weightChitCustomField4Group = null;
            
            let weightChitCustomFieldId5 = null;
            let weightChitCustomField5 = false;
            let weightChitCustomField5SortOrder = null;
            let weightChitCustomField5Group = null;

            let weightChitShowCustomFieldsItems = Utils.findSettingsItems(weightChitAccountDocSettingVariables, 'ShowCustomField_');
            if(weightChitShowCustomFieldsItems && weightChitShowCustomFieldsItems.length > 0){
                weightChitShowCustomFieldsItems.forEach((itm, i) => {
                    let splitArr = itm.variableName.split('_');
                    if(splitArr && splitArr.length > 0){
                        let id = splitArr[1];

                        let weightChitShowCustomField = Utils.findSettingsItem(itm, AccountDocSettingVariables.Show);
                        let customFieldsSortOrder = Utils.findSettingsItem(itm, null, 'sortOrder');
                        let customFieldsGroup = Utils.findSettingsItem(itm, null, 'group');
    
                        if(i == 0){
                            weightChitCustomFieldId1 = id;
                            weightChitCustomField1 = weightChitShowCustomField;
                            weightChitCustomField1SortOrder = customFieldsSortOrder;
                            weightChitCustomField1Group = customFieldsGroup;
                        } else if(i == 1){
                            weightChitCustomFieldId2 = id;
                            weightChitCustomField2 = weightChitShowCustomField;
                            weightChitCustomField2SortOrder = customFieldsSortOrder;
                            weightChitCustomField2Group = customFieldsGroup;
                        } else if(i == 2){
                            weightChitCustomFieldId3 = id;
                            weightChitCustomField3 = weightChitShowCustomField;
                            weightChitCustomField3SortOrder = customFieldsSortOrder;
                            weightChitCustomField3Group = customFieldsGroup;
                        } else if(i == 3){
                            weightChitCustomFieldId4 = id;
                            weightChitCustomField4 = weightChitShowCustomField;
                            weightChitCustomField4SortOrder = customFieldsSortOrder;
                            weightChitCustomField4Group = customFieldsGroup;
                        } else if(i == 4){
                            weightChitCustomFieldId5 = id;
                            weightChitCustomField5 = weightChitShowCustomField;
                            weightChitCustomField5SortOrder = customFieldsSortOrder;
                            weightChitCustomField5Group = customFieldsGroup;
                        }
                    }
                });
            }

            
            let accountMessageTemplates = (data && data.accountMessageTemplates && data.accountMessageTemplates.length > 0) ? data.accountMessageTemplates : [];
            let twilioApp = (data && data.twilioApp) ? data.twilioApp : null;
            let accountSid = (twilioApp && twilioApp.accountSid) ? twilioApp.accountSid : '';
            let authToken = (twilioApp && twilioApp.authToken) ? twilioApp.authToken : '';
            let fromPhoneNumber = (twilioApp && twilioApp.fromPhoneNumber) ? twilioApp.fromPhoneNumber : null;


            let customFormParams = this.state.rowInitialValues.customFormParams;
            customFormParams.isConnectTwilio = isConnectTwilio;
            customFormParams.isConnectQuickbook = isConnectQuickbook;
            customFormParams.isConnectXero = isConnectXero;
            customFormParams.quickbookOrXero = quickbookOrXero;
            customFormParams.quickbookOrXeroName = quickbookOrXeroName;
            customFormParams.quickBookApp = quickBookApp;
            customFormParams.xeroApp = xeroApp;
            customFormParams.deliveryOrderAccountDocSettingVariables = deliveryOrderAccountDocSettingVariables;


            let rowValues = {
                ...this.state.rowInitialValues,

                isEdit: true,
                
                [this.pageIdField]: this.state.selectedProfileId,
            
                billingProfileName: data.billingProfileName,
                isActive: data.isActive,
                isDefault: data.isDefault,
                companyAddressPlainText: companyAddressPlainText,
                companyAddressHTML: companyAddressHTML,
                companyAddress: companyAddress,
                companyLogoURL: data.logoImageUrl,
                taxRateId: quickBookDefaultTaxCodeId,
                paymentTermsId: quickBookDefaultTermId,
                quickBookDefaultChartOfAccountId: quickBookDefaultChartOfAccountId,
                quickBookDefaultPurchaseChartOfAccountId: quickBookDefaultPurchaseChartOfAccountId,
                quickBookDefaultTaxCodeId: quickBookDefaultTaxCodeId,
                quickBookDefaultTermId: quickBookDefaultTermId,
                xeroDefaultChartOfAccountId: xeroDefaultChartOfAccountId,
                xeroDefaultPurchaseChartOfAccountId: xeroDefaultPurchaseChartOfAccountId,
                xeroDefaultTaxType: xeroDefaultTaxType,
                xeroDefaultPurchaseTaxType: xeroDefaultPurchaseTaxType,
                xeroPaymentTerm: xeroPaymentTerm,
                xeroPaymentTermType: xeroPaymentTermType,
                customerMemo: customerMemo,
                prefixDoNumber: (data.doNumberPrefix && data.doNumberPrefix !== '') ? data.doNumberPrefix : '',
                invoiceNumberPrefix: data.invoiceNumberPrefix,
                purchaseOrderNumberPrefix: data.purchaseOrderNumberPrefix,
                expenseNumberPrefix: data.expenseNumberPrefix,


                deliveryOrderLabelDeliveryOrder: deliveryOrderLabelDeliveryOrder,
                deliveryOrderLabelDeliveryOrderSortOrder: deliveryOrderLabelDeliveryOrderSortOrder,

                deliveryOrderShowCustomerName: deliveryOrderShowCustomerName,
                deliveryOrderShowCustomerNameSortOrder: deliveryOrderShowCustomerNameSortOrder,
                deliveryOrderLabelCustomerName: deliveryOrderLabelCustomerName,
                deliveryOrderLabelCustomerNameSortOrder: deliveryOrderLabelCustomerNameSortOrder,

                deliveryOrderShowCustomerAddress: deliveryOrderShowCustomerAddress,
                deliveryOrderShowCustomerAddressSortOrder: deliveryOrderShowCustomerAddressSortOrder,
                deliveryOrderLabelCustomerAddress: deliveryOrderLabelCustomerAddress,
                deliveryOrderLabelCustomerAddressSortOrder: deliveryOrderLabelCustomerAddressSortOrder,

                deliveryOrderShowProjectSite: deliveryOrderShowProjectSite,
                deliveryOrderShowProjectSiteSortOrder: deliveryOrderShowProjectSiteSortOrder,
                deliveryOrderLabelProjectSite: deliveryOrderLabelProjectSite,
                deliveryOrderLabelProjectSiteSortOrder: deliveryOrderLabelProjectSiteSortOrder,

                deliveryOrderShowJobType: deliveryOrderShowJobType,
                deliveryOrderShowJobTypeSortOrder: deliveryOrderShowJobTypeSortOrder,
                deliveryOrderLabelJobType: deliveryOrderLabelJobType,
                deliveryOrderLabelJobTypeSortOrder: deliveryOrderLabelJobTypeSortOrder,

                deliveryOrderShowJobCompletion: deliveryOrderShowJobCompletion,
                deliveryOrderShowJobCompletionSortOrder: deliveryOrderShowJobCompletionSortOrder,
                deliveryOrderShowJobTime: deliveryOrderShowJobTime,
                deliveryOrderShowJobTimeSortOrder: deliveryOrderShowJobTimeSortOrder,
                deliveryOrderLabelJobCompletion: deliveryOrderLabelJobCompletion,
                deliveryOrderLabelJobCompletionSortOrder: deliveryOrderLabelJobCompletionSortOrder,

                deliveryOrderShowDriver: deliveryOrderShowDriver,
                deliveryOrderShowDriverSortOrder: deliveryOrderShowDriverSortOrder,
                deliveryOrderLabelDriver: deliveryOrderLabelDriver,
                deliveryOrderLabelDriverSortOrder: deliveryOrderLabelDriverSortOrder,

                deliveryOrderShowVehicle: deliveryOrderShowVehicle,
                deliveryOrderShowVehicleSortOrder: deliveryOrderShowVehicleSortOrder,
                deliveryOrderLabelVehicle: deliveryOrderLabelVehicle,
                deliveryOrderLabelVehicleSortOrder: deliveryOrderLabelVehicleSortOrder,

                deliveryOrderShowBinIN: deliveryOrderShowBinNumberIN,
                deliveryOrderShowBinINSortOrder: deliveryOrderShowBinNumberINSortOrder,
                deliveryOrderLabelBinIN: deliveryOrderLabelBinNumberIN,
                deliveryOrderLabelBinINSortOrder: deliveryOrderLabelBinNumberINSortOrder,

                deliveryOrderShowBinOUT: deliveryOrderShowBinNumberOUT,
                deliveryOrderShowBinOUTSortOrder: deliveryOrderShowBinNumberOUTSortOrder,
                deliveryOrderLabelBinOUT: deliveryOrderLabelBinNumberOUT,
                deliveryOrderLabelBinOUTSortOrder: deliveryOrderLabelBinNumberOUTSortOrder,

                deliveryOrderShowSiteContacts: deliveryOrderShowSiteContact,
                deliveryOrderShowSiteContactsSortOrder: deliveryOrderShowSiteContactSortOrder,
                deliveryOrderLabelSiteContacts: deliveryOrderLabelSiteContact,
                deliveryOrderLabelSiteContactsSortOrder: deliveryOrderLabelSiteContactSortOrder,

                deliveryOrderShowPaymentTerms: deliveryOrderShowPaymentTerms,
                deliveryOrderShowPaymentTermsSortOrder: deliveryOrderShowPaymentTermsSortOrder,
                deliveryOrderLabelPaymentTerms: deliveryOrderLabelPaymentTerms,
                deliveryOrderLabelPaymentTermsSortOrder: deliveryOrderLabelPaymentTermsSortOrder,

                deliveryOrderShowCollectedCash: deliveryOrderShowAmountCollected,
                deliveryOrderShowCollectedCashSortOrder: deliveryOrderShowAmountCollectedSortOrder,
                deliveryOrderLabelCollectedCash: deliveryOrderLabelAmountCollected,
                deliveryOrderLabelCollectedCashSortOrder: deliveryOrderLabelAmountCollectedSortOrder,


                deliveryOrderShowStandardServicesBinType: deliveryOrderShowStandardServicesBinType,
                deliveryOrderShowStandardServicesBinTypeSortOrder: deliveryOrderShowStandardServicesBinTypeSortOrder,
                // deliveryOrderLabelStandardServicesBinType: '',
                deliveryOrderShowStandardServicesWasteType: deliveryOrderShowStandardServicesWasteType,
                deliveryOrderShowStandardServicesWasteTypeSortOrder: deliveryOrderShowStandardServicesWasteTypeSortOrder,
                // deliveryOrderLabelStandardServicesWasteType: '',
                deliveryOrderShowStandardServicesQty: deliveryOrderShowStandardServicesQty,
                deliveryOrderShowStandardServicesQtySortOrder: deliveryOrderShowStandardServicesQtySortOrder,
                // deliveryOrderLabelStandardServicesQty: '',
                deliveryOrderShowStandardServicesRate: deliveryOrderShowStandardServicesRate,
                deliveryOrderShowStandardServicesRateSortOrder: deliveryOrderShowStandardServicesRateSortOrder,
                // deliveryOrderLabelStandardServicesRate: '',
                deliveryOrderShowStandardServicesPrice: deliveryOrderShowStandardServicesPrice,
                deliveryOrderShowStandardServicesPriceSortOrder: deliveryOrderShowStandardServicesPriceSortOrder,
                // deliveryOrderLabelStandardServicesPrice: '',
            
                deliveryOrderShowAdditionalServices: deliveryOrderShowAdditionalServiceTable,
                deliveryOrderShowAdditionalServicesSortOrder: deliveryOrderShowAdditionalServiceTableSortOrder,
                deliveryOrderShowAdditionalServicesDescription: deliveryOrderShowAdditionalServicesDescription,
                deliveryOrderShowAdditionalServicesDescriptionSortOrder: deliveryOrderShowAdditionalServicesDescriptionSortOrder,
                // deliveryOrderLabelAdditionalServicesDescription: '',
                deliveryOrderShowAdditionalServicesQty: deliveryOrderShowAdditionalServicesQty,
                deliveryOrderShowAdditionalServicesQtySortOrder: deliveryOrderShowAdditionalServicesQtySortOrder,
                // deliveryOrderLabelAdditionalServicesQty: '',
                deliveryOrderShowAdditionalServicesRate: deliveryOrderShowAdditionalServicesRate,
                deliveryOrderShowAdditionalServicesRateSortOrder: deliveryOrderShowAdditionalServicesRateSortOrder,
                // deliveryOrderLabelAdditionalServicesRate: '',
                deliveryOrderShowAdditionalServicesPrice: deliveryOrderShowAdditionalServicesPrice,
                deliveryOrderShowAdditionalServicesPriceSortOrder: deliveryOrderShowAdditionalServicesPriceSortOrder,
                // deliveryOrderLabelAdditionalServicesPrice: '',
            
                deliveryOrderShowSignature: deliveryOrderShowSignature,
                deliveryOrderShowSignatureSortOrder: deliveryOrderShowSignatureSortOrder,
                deliveryOrderShowProofOfDelivery: deliveryOrderShowProofOfDelivery,
                deliveryOrderShowProofOfDeliverySortOrder: deliveryOrderShowProofOfDeliverySortOrder,
                deliveryOrderLabelProofOfDelivery: deliveryOrderLabelProofOfDelivery,
                deliveryOrderLabelProofOfDeliverySortOrder: deliveryOrderLabelProofOfDeliverySortOrder,
                deliveryOrderShowMessageToCustomer: deliveryOrderShowMessageToCustomer,
                deliveryOrderShowMessageToCustomerSortOrder: deliveryOrderShowMessageToCustomerSortOrder,
                deliveryOrderLabelMessageToCustomerPlainText: deliveryOrderMessageToCustomerPlainText,
                deliveryOrderLabelMessageToCustomerHTML: deliveryOrderMessageToCustomerHTML,
                deliveryOrderLabelMessageToCustomer: deliveryOrderMessageToCustomer,
                deliveryOrderLabelMessageToCustomerSortOrder: deliveryOrderLabelMessageToCustomerSortOrder,
                deliveryOrderShowFooter: deliveryOrderShowDisclaimer,
                deliveryOrderShowDisclaimerSortOrder: deliveryOrderShowDisclaimerSortOrder,
                deliveryOrderLabelFooterPlainText: deliveryOrderDisclaimerPlainText,
                deliveryOrderLabelFooterHTML: deliveryOrderDisclaimerHTML,
                deliveryOrderLabelFooter: deliveryOrderDisclaimer,
                deliveryOrderLabelDisclaimerSortOrder: deliveryOrderLabelDisclaimerSortOrder,
                


                printedReceiptShowCustomerName: printedReceiptShowCustomerName,
                printedReceiptShowCustomerNameSortOrder: printedReceiptShowCustomerNameSortOrder,
                // printedReceiptLabelCustomerName: '',
                // printedReceiptShowCustomerAddress: false,
                printedReceiptLabelCustomerAddress: printedReceiptLabelCustomerAddress,
                printedReceiptLabelCustomerAddressSortOrder: printedReceiptLabelCustomerAddressSortOrder,
                printedReceiptShowDriver: printedReceiptShowDriver,
                printedReceiptShowDriverSortOrder: printedReceiptShowDriverSortOrder,
                printedReceiptLabelDriver: printedReceiptLabelDriver,
                printedReceiptLabelDriverSortOrder: printedReceiptLabelDriverSortOrder,
                printedReceiptShowVehicle: printedReceiptShowVehicle,
                printedReceiptShowVehicleSortOrder: printedReceiptShowVehicleSortOrder,
                printedReceiptLabelVehicle: printedReceiptLabelVehicle,
                printedReceiptLabelVehicleSortOrder: printedReceiptLabelVehicleSortOrder,
                printedReceiptShowJobType: printedReceiptShowJobType,
                printedReceiptShowJobTypeSortOrder: printedReceiptShowJobTypeSortOrder,
                printedReceiptLabelJobType: printedReceiptLabelJobType,
                printedReceiptLabelJobTypeSortOrder: printedReceiptLabelJobTypeSortOrder,
                printedReceiptShowPaymentType: printedReceiptShowPaymentType,
                printedReceiptShowPaymentTypeSortOrder: printedReceiptShowPaymentTypeSortOrder,
                printedReceiptLabelPaymentType: printedReceiptLabelPaymentType,
                printedReceiptLabelPaymentTypeSortOrder: printedReceiptLabelPaymentTypeSortOrder,
                printedReceiptShowCollectedCash: printedReceiptShowAmountCollected,
                printedReceiptShowCollectedCashSortOrder: printedReceiptShowAmountCollectedSortOrder,
                printedReceiptLabelCollectedCash: printedReceiptLabelAmountCollected,
                printedReceiptLabelCollectedCashSortOrder: printedReceiptLabelAmountCollectedSortOrder,
            
                printedReceiptShowBinDelivered: printedReceiptShowBinDelivered,
                printedReceiptShowBinDeliveredSortOrder: printedReceiptShowBinDeliveredSortOrder,
                printedReceiptLabelBinDelivered: printedReceiptLabelBinDelivered,
                printedReceiptLabelBinDeliveredSortOrder: printedReceiptLabelBinDeliveredSortOrder,
                printedReceiptShowBinType1: printedReceiptShowBinDeliveredType,
                printedReceiptShowBinType1SortOrder: printedReceiptShowBinDeliveredTypeSortOrder,
                printedReceiptLabelBinType1: printedReceiptLabelBinDeliveredType,
                printedReceiptLabelBinType1SortOrder: printedReceiptLabelBinDeliveredTypeSortOrder,
                printedReceiptShowWasteType1: printedReceiptShowPlannedWasteType,
                printedReceiptShowWasteType1SortOrder: printedReceiptShowPlannedWasteTypeSortOrder,
                printedReceiptLabelWasteType1: printedReceiptLabelPlannedWasteType,
                printedReceiptLabelWasteType1SortOrder: printedReceiptLabelPlannedWasteTypeSortOrder,
                printedReceiptShowBinCollected: printedReceiptShowBinCollected,
                printedReceiptShowBinCollectedSortOrder: printedReceiptShowBinCollectedSortOrder,
                printedReceiptLabelBinCollected: printedReceiptLabelBinCollected,
                printedReceiptLabelBinCollectedSortOrder: printedReceiptLabelBinCollectedSortOrder,
                printedReceiptShowBinType2: printedReceiptShowBinCollectedType,
                printedReceiptShowBinType2SortOrder: printedReceiptShowBinCollectedTypeSortOrder,
                printedReceiptLabelBinType2: printedReceiptLabelBinCollectedType,
                printedReceiptLabelBinType2SortOrder: printedReceiptLabelBinCollectedTypeSortOrder,
                printedReceiptShowWasteType2: printedReceiptShowWasteTypeCollected,
                printedReceiptShowWasteType2SortOrder: printedReceiptShowWasteTypeCollectedSortOrder,
                printedReceiptLabelWasteType2: printedReceiptLabelWasteTypeCollected,
                printedReceiptLabelWasteType2SortOrder: printedReceiptLabelWasteTypeCollectedSortOrder,
            
                printedReceiptShowAdditionalServices: printedReceiptShowAdditionalService,
                printedReceiptShowAdditionalServicesSortOrder: printedReceiptShowAdditionalServiceSortOrder,
                // printedReceiptShowAdditionalServicesDescription: false,
                // printedReceiptLabelAdditionalServicesDescription: '',
                // printedReceiptShowAdditionalServicesQty: false,
                // printedReceiptLabelAdditionalServicesQty: '',
            
                printedReceiptShowSignature: printedReceiptShowSignature,
                printedReceiptShowSignatureSortOrder: printedReceiptShowSignatureSortOrder,
                printedReceiptShowSiteContact: printedReceiptShowSiteContact,
                printedReceiptShowSiteContactSortOrder: printedReceiptShowSiteContactSortOrder,
                printedReceiptShowSiteTelephone: printedReceiptShowSiteTelephone,
                printedReceiptShowSiteTelephoneSortOrder: printedReceiptShowSiteTelephoneSortOrder,
                // printedReceiptShowFooter: false,
                printedReceiptLabelFooter: printedReceiptLabelDisclaimer,
                printedReceiptLabelFooterSortOrder: printedReceiptLabelDisclaimerSortOrder,
            
                    

                emailSettingsSubject: emailSettingsSubject,
                emailSettingsFrom: emailSettingsFrom,
                emailSettingsCc: emailSettingsCc,
                emailSettingsMessagePlainText: emailSettingsMessagePlainText,
                emailSettingsMessageHTML: emailSettingsMessageHTML,
                emailSettingsMessage: emailSettingsMessage,
                emailSettingsDeliveryOrder: emailSettingsDeliveryOrder,
                emailSettingsIncludePhotos: emailSettingsIncludePhotos,
                emailSettingsWeighChit: emailSettingsWeighChit,

                

                weightChitLabelWeightTicket: weightChitLabelWeightTicket,
                weightChitLabelWeightTicketSortOrder: weightChitLabelWeightTicketSortOrder,
                weightChitLabelWeightTicketGroup: weightChitLabelWeightTicketGroup,
                weightChitShowCustomerName: weightChitShowCustomerName,
                weightChitShowCustomerNameSortOrder: weightChitShowCustomerNameSortOrder,
                weightChitShowCustomerNameGroup: weightChitShowCustomerNameGroup,
                weightChitLabelCustomerName: weightChitLabelCustomerName,
                weightChitLabelCustomerNameSortOrder: weightChitLabelCustomerNameSortOrder,
                weightChitLabelCustomerNameGroup: weightChitLabelCustomerNameGroup,
                weightChitShowCustomerAddress: weightChitShowCustomerAddress,
                weightChitShowCustomerAddressSortOrder: weightChitShowCustomerAddressSortOrder,
                weightChitShowCustomerAddressGroup: weightChitShowCustomerAddressGroup,
                weightChitLabelCustomerAddress: weightChitLabelCustomerAddress,
                weightChitLabelCustomerAddressSortOrder: weightChitLabelCustomerAddressSortOrder,
                weightChitLabelCustomerAddressGroup: weightChitLabelCustomerAddressGroup,
                weightChitShowSiteAddress: weightChitShowSiteAddress,
                weightChitShowSiteAddressSortOrder: weightChitShowSiteAddressSortOrder,
                weightChitShowSiteAddressGroup: weightChitShowSiteAddressGroup,
                weightChitLabelSiteAddress: weightChitLabelSiteAddress,
                weightChitLabelSiteAddressSortOrder: weightChitLabelSiteAddressSortOrder,
                weightChitLabelSiteAddressGroup: weightChitLabelSiteAddressGroup,
                weightChitShowDONumber: weightChitShowDONumber,
                weightChitShowDONumberSortOrder: weightChitShowDONumberSortOrder,
                weightChitShowDONumberGroup: weightChitShowDONumberGroup,
                weightChitLabelDONumber: weightChitLabelDONumber,
                weightChitLabelDONumberSortOrder: weightChitLabelDONumberSortOrder,
                weightChitLabelDONumberGroup: weightChitLabelDONumberGroup,
                weightChitShowJobCompletion: weightChitShowJobCompletion,
                weightChitShowJobCompletionSortOrder: weightChitShowJobCompletionSortOrder,
                weightChitShowJobCompletionGroup: weightChitShowJobCompletionGroup,
                weightChitShowJobTime: weightChitShowJobTime,
                weightChitShowJobTimeSortOrder: weightChitShowJobTimeSortOrder,
                weightChitShowJobTimeGroup: weightChitShowJobTimeGroup,
                weightChitLabelJobCompletion: weightChitLabelJobCompletion,
                weightChitLabelJobCompletionSortOrder: weightChitLabelJobCompletionSortOrder,
                weightChitLabelJobCompletionGroup: weightChitLabelJobCompletionGroup,
                weightChitShowJobType: weightChitShowJobType,
                weightChitShowJobTypeSortOrder: weightChitShowJobTypeSortOrder,
                weightChitShowJobTypeGroup: weightChitShowJobTypeGroup,
                weightChitLabelJobType: weightChitLabelJobType,
                weightChitLabelJobTypeSortOrder: weightChitLabelJobTypeSortOrder,
                weightChitLabelJobTypeGroup: weightChitLabelJobTypeGroup,
                weightChitShowBinINType: weightChitShowBinINType,
                weightChitShowBinINTypeSortOrder: weightChitShowBinINTypeSortOrder,
                weightChitShowBinINTypeGroup: weightChitShowBinINTypeGroup,
                weightChitLabelBinINType: weightChitLabelBinINType,
                weightChitLabelBinINTypeSortOrder: weightChitLabelBinINTypeSortOrder,
                weightChitLabelBinINTypeGroup: weightChitLabelBinINTypeGroup,
                weightChitShowWasteINType: weightChitShowWasteINType,
                weightChitShowWasteINTypeSortOrder: weightChitShowWasteINTypeSortOrder,
                weightChitShowWasteINTypeGroup: weightChitShowWasteINTypeGroup,
                weightChitLabelWasteINType: weightChitLabelWasteINType,
                weightChitLabelWasteINTypeSortOrder: weightChitLabelWasteINTypeSortOrder,
                weightChitLabelWasteINTypeGroup: weightChitLabelWasteINTypeGroup,
                weightChitShowWeighTimestamp: weightChitShowWeighTimestamp,
                weightChitShowWeighTimestampSortOrder: weightChitShowWeighTimestampSortOrder,
                weightChitShowWeighTimestampGroup: weightChitShowWeighTimestampGroup,
                weightChitLabelWeighTimestamp: weightChitLabelWeighTimestamp,
                weightChitLabelWeighTimestampSortOrder: weightChitLabelWeighTimestampSortOrder,
                weightChitLabelWeighTimestampGroup: weightChitLabelWeighTimestampGroup,
                weightChitShowWeighOutTimestamp: weightChitShowWeighOutTimestamp,
                weightChitShowWeighOutTimestampSortOrder: weightChitShowWeighOutTimestampSortOrder,
                weightChitShowWeighOutTimestampGroup: weightChitShowWeighOutTimestampGroup,
                weightChitLabelWeighOutTimestamp: weightChitLabelWeighOutTimestamp,
                weightChitLabelWeighOutTimestampSortOrder: weightChitLabelWeighOutTimestampSortOrder,
                weightChitLabelWeighOutTimestampGroup: weightChitLabelWeighOutTimestampGroup,
                weightChitShowDriver: weightChitShowDriver,
                weightChitShowDriverSortOrder: weightChitShowDriverSortOrder,
                weightChitShowDriverGroup: weightChitShowDriverGroup,
                weightChitLabelDriver: weightChitLabelDriver,
                weightChitLabelDriverSortOrder: weightChitLabelDriverSortOrder,
                weightChitLabelDriverGroup: weightChitLabelDriverGroup,
                weightChitShowVehicle: weightChitShowVehicle,
                weightChitShowVehicleSortOrder: weightChitShowVehicleSortOrder,
                weightChitShowVehicleGroup: weightChitShowVehicleGroup,
                weightChitLabelVehicle: weightChitLabelVehicle,
                weightChitLabelVehicleSortOrder: weightChitLabelVehicleSortOrder,
                weightChitLabelVehicleGroup: weightChitLabelVehicleGroup,
                weightChitShowWeighBy: weightChitShowWeighBy,
                weightChitShowWeighBySortOrder: weightChitShowWeighBySortOrder,
                weightChitShowWeighByGroup: weightChitShowWeighByGroup,
                weightChitLabelWeighBy: weightChitLabelWeighBy,
                weightChitLabelWeighBySortOrder: weightChitLabelWeighBySortOrder,
                weightChitLabelWeighByGroup: weightChitLabelWeighByGroup,
                weightChitShowRemarks: weightChitShowRemarks,
                weightChitShowRemarksSortOrder: weightChitShowRemarksSortOrder,
                weightChitShowRemarksGroup: weightChitShowRemarksGroup,
                weightChitLabelRemarks: weightChitLabelRemarks,
                weightChitLabelRemarksSortOrder: weightChitLabelRemarksSortOrder,
                weightChitLabelRemarksGroup: weightChitLabelRemarksGroup,

                weightChitShowGross: weightChitShowGross,
                weightChitShowGrossSortOrder: weightChitShowGrossSortOrder,
                weightChitShowGrossGroup: weightChitShowGrossGroup,
                weightChitLabelGross: weightChitLabelGross,
                weightChitLabelGrossSortOrder: weightChitLabelGrossSortOrder,
                weightChitLabelGrossGroup: weightChitLabelGrossGroup,
                weightChitShowTare: weightChitShowTare,
                weightChitShowTareSortOrder: weightChitShowTareSortOrder,
                weightChitShowTareGroup: weightChitShowTareGroup,
                weightChitLabelTare: weightChitLabelTare,
                weightChitLabelTareSortOrder: weightChitLabelTareSortOrder,
                weightChitLabelTareGroup: weightChitLabelTareGroup,
                weightChitShowNett: weightChitShowNett,
                weightChitShowNettSortOrder: weightChitShowNettSortOrder,
                weightChitShowNettGroup: weightChitShowNettGroup,
                weightChitLabelNett: weightChitLabelNett,
                weightChitLabelNettSortOrder: weightChitLabelNettSortOrder,
                weightChitLabelNettGroup: weightChitLabelNettGroup,
                weightChitShowNonBillable: weightChitShowNonBillable,
                weightChitShowNonBillableSortOrder: weightChitShowNonBillableSortOrder,
                weightChitShowNonBillableGroup: weightChitShowNonBillableGroup,
                weightChitLabelNonBillable: weightChitLabelNonBillable,
                weightChitLabelNonBillableSortOrder: weightChitLabelNonBillableSortOrder,
                weightChitLabelNonBillableGroup: weightChitLabelNonBillableGroup,
                weightChitShowBillable: weightChitShowBillable,
                weightChitShowBillableSortOrder: weightChitShowBillableSortOrder,
                weightChitShowBillableGroup: weightChitShowBillableGroup,
                weightChitLabelBillable: weightChitLabelBillable,
                weightChitLabelBillableSortOrder: weightChitLabelBillableSortOrder,
                weightChitLabelBillableGroup: weightChitLabelBillableGroup,
                weightChitShowRate: weightChitShowRate,
                weightChitShowRateSortOrder: weightChitShowRateSortOrder,
                weightChitShowRateGroup: weightChitShowRateGroup,
                weightChitLabelRate: weightChitLabelRate,
                weightChitLabelRateSortOrder: weightChitLabelRateSortOrder,
                weightChitLabelRateGroup: weightChitLabelRateGroup,
                weightChitShowAmount: weightChitShowAmount,
                weightChitShowAmountSortOrder: weightChitShowAmountSortOrder,
                weightChitShowAmountGroup: weightChitShowAmountGroup,
                weightChitLabelAmount: weightChitLabelAmount,
                weightChitLabelAmountSortOrder: weightChitLabelAmountSortOrder,
                weightChitLabelAmountGroup: weightChitLabelAmountGroup,

                weightChitShowMessageToCustomer: weightChitShowMessageToCustomer,
                weightChitShowMessageToCustomerSortOrder: weightChitShowMessageToCustomerSortOrder,
                weightChitShowMessageToCustomerGroup: weightChitShowMessageToCustomerGroup,
                weightChitLabelMessageToCustomerPlainText: weightChitMessageToCustomerPlainText,
                weightChitLabelMessageToCustomerHTML: weightChitMessageToCustomerHTML,
                weightChitLabelMessageToCustomer: weightChitMessageToCustomer,
                weightChitLabelStringHeaderTextPlainText: weightChitStringHeaderTextPlainText,
                weightChitLabelStringHeaderTextHTML: weightChitStringHeaderTextHTML,
                weightChitLabelStringHeaderText: weightChitStringHeaderText,
                weightChitLabelMessageToCustomerSortOrder: weightChitLabelMessageToCustomerSortOrder,
                weightChitLabelMessageToCustomerGroup: weightChitLabelMessageToCustomerGroup,
                weightChitShowFooter: weightChitShowDisclaimer,
                weightChitShowFooterSortOrder: weightChitShowFooterSortOrder,
                weightChitShowFooterGroup: weightChitShowFooterGroup,
                weightChitLabelFooterPlainText: weightChitDisclaimerPlainText,
                weightChitLabelFooterHTML: weightChitDisclaimerHTML,
                weightChitLabelFooter: weightChitDisclaimer,
                weightChitLabelFooterSortOrder: weightChitLabelFooterSortOrder,
                weightChitLabelFooterGroup: weightChitLabelFooterGroup,

                weightChitAccountDocSettinglayout: weightChitAccountDocSettinglayout,

                weightChitShowCustomFieldsItems: weightChitShowCustomFieldsItems,
                weightChitCustomFieldId1: weightChitCustomFieldId1,
                weightChitCustomField1: weightChitCustomField1,
                weightChitCustomField1SortOrder: weightChitCustomField1SortOrder,
                weightChitCustomField1Group: weightChitCustomField1Group,
                weightChitCustomFieldId2: weightChitCustomFieldId2,
                weightChitCustomField2: weightChitCustomField2,
                weightChitCustomField2SortOrder: weightChitCustomField2SortOrder,
                weightChitCustomField2Group: weightChitCustomField2Group,
                weightChitCustomFieldId3: weightChitCustomFieldId3,
                weightChitCustomField3: weightChitCustomField3,
                weightChitCustomField3SortOrder: weightChitCustomField3SortOrder,
                weightChitCustomField3Group: weightChitCustomField3Group,
                weightChitCustomFieldId4: weightChitCustomFieldId4,
                weightChitCustomField4: weightChitCustomField4,
                weightChitCustomField4SortOrder: weightChitCustomField4SortOrder,
                weightChitCustomField4Group: weightChitCustomField4Group,
                weightChitCustomFieldId5: weightChitCustomFieldId5,
                weightChitCustomField5: weightChitCustomField5,
                weightChitCustomField5SortOrder: weightChitCustomField5SortOrder,
                weightChitCustomField5Group: weightChitCustomField5Group,

                accountMessageTemplates: accountMessageTemplates,
                twilioApp: twilioApp,
                accountSid: accountSid,
                authToken: authToken,
                fromPhoneNumber: fromPhoneNumber,

                customFormParams: customFormParams,
            };
        
            return rowValues;
        } else {
            return this.state.rowInitialValues;
        }
    }
    prepareDataCreate = (form) => {
        let data = {
            billingProfileName: form.billingProfileName,
            isActive: form.isActive,
            isDefault: false,
            companyAddress: form.companyAddressHTML,
            logoImageUrl: form.companyLogoURL,
            doNumberPrefix: form.prefixDoNumber,
            invoiceNumberPrefix: form.invoiceNumberPrefix,
            purchaseOrderNumberPrefix: form.purchaseOrderNumberPrefix,
            expenseNumberPrefix: form.expenseNumberPrefix,
            
            quickBookDefaultTaxCodeId: form.taxRateId,
            quickBookDefaultTermId: form.paymentTermsId,
            customerMemo: form.customerMemo,
            quickBookDefaultChartOfAccountId: form.quickBookDefaultChartOfAccountId,
            quickBookDefaultPurchaseChartOfAccountId: form.quickBookDefaultPurchaseChartOfAccountId,
			
            xeroDefaultChartOfAccountId: form.xeroDefaultChartOfAccountId,
            xeroDefaultPurchaseChartOfAccountId: form.xeroDefaultPurchaseChartOfAccountId,
            xeroDefaultTaxType: form.xeroDefaultTaxType,
            xeroDefaultPurchaseTaxType: form.xeroDefaultPurchaseTaxType,
            xeroPaymentTerm: form.xeroPaymentTerm,
            xeroPaymentTermType: form.xeroPaymentTermType,
        };

        return data;
    }
    prepareDataUpdate = (form) => {
        let data = {
            [this.pageIdField]: this.state.selectedProfileId,
            
            billingProfileName: form.billingProfileName,
            isActive: form.isActive,
            isDefault: form.isDefault,
            companyAddress: form.companyAddressHTML,
            logoImageUrl: form.companyLogoURL,
            doNumberPrefix: form.prefixDoNumber,
            invoiceNumberPrefix: form.invoiceNumberPrefix,
            purchaseOrderNumberPrefix: form.purchaseOrderNumberPrefix,
            expenseNumberPrefix: form.expenseNumberPrefix,
            
            quickBookDefaultTaxCodeId: form.taxRateId,
            quickBookDefaultTermId: form.paymentTermsId,
            customerMemo: form.customerMemo,
            quickBookDefaultChartOfAccountId: form.quickBookDefaultChartOfAccountId,
            quickBookDefaultPurchaseChartOfAccountId: form.quickBookDefaultPurchaseChartOfAccountId,
			
            xeroDefaultChartOfAccountId: form.xeroDefaultChartOfAccountId,
            xeroDefaultPurchaseChartOfAccountId: form.xeroDefaultPurchaseChartOfAccountId,
            xeroDefaultTaxType: form.xeroDefaultTaxType,
            xeroDefaultPurchaseTaxType: form.xeroDefaultPurchaseTaxType,
            xeroPaymentTerm: form.xeroPaymentTerm,
            xeroPaymentTermType: form.xeroPaymentTermType,
        };
        
        return data;
    }
    prepareDataUpdateDeliveryOrder = (form) => {
        let accountDocSettingVariables = [];
        let accountDocSettings = (this.state.selectedProfileItem && this.state.selectedProfileItem.accountDocSettings && this.state.selectedProfileItem.accountDocSettings.length > 0) ? this.state.selectedProfileItem.accountDocSettings : [];
        if(accountDocSettings && accountDocSettings.length > 0){
            let deliveryOrderItems = accountDocSettings.filter(x => x.type === AccountDocSettingType.DeliveryOrder);
            if(deliveryOrderItems && deliveryOrderItems.length > 0){
                accountDocSettingVariables = deliveryOrderItems[0].accountDocSettingVariables;
            }
        }
        

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDELIVERY_ORDER', form.deliveryOrderLabelDeliveryOrder);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDELIVERY_ORDER', form.deliveryOrderLabelDeliveryOrderSortOrder, 'variableName', 'sortOrder');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerName', Utils.boolToStr(form.deliveryOrderShowCustomerName));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerName', form.deliveryOrderShowCustomerNameSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Name', form.deliveryOrderLabelCustomerName);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Name', form.deliveryOrderLabelCustomerNameSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerAddress', Utils.boolToStr(form.deliveryOrderShowCustomerAddress));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerAddress', form.deliveryOrderShowCustomerAddressSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Address', form.deliveryOrderLabelCustomerAddress);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Address', form.deliveryOrderLabelCustomerAddressSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowProjectSite', Utils.boolToStr(form.deliveryOrderShowProjectSite));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowProjectSite', form.deliveryOrderShowProjectSiteSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelProject_Site', form.deliveryOrderLabelProjectSite);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelProject_Site', form.deliveryOrderLabelProjectSiteSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobType', Utils.boolToStr(form.deliveryOrderShowJobType));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobType', form.deliveryOrderShowJobTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Type', form.deliveryOrderLabelJobType);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Type', form.deliveryOrderLabelJobTypeSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletion', Utils.boolToStr(form.deliveryOrderShowJobCompletion));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletion', form.deliveryOrderShowJobCompletionSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletionTime', Utils.boolToStr(form.deliveryOrderShowJobTime));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletionTime', form.deliveryOrderShowJobTimeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Completion', form.deliveryOrderLabelJobCompletion);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Completion', form.deliveryOrderLabelJobCompletionSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDriver', Utils.boolToStr(form.deliveryOrderShowDriver));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDriver', form.deliveryOrderShowDriverSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDriver', form.deliveryOrderLabelDriver);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDriver', form.deliveryOrderLabelDriverSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowVehicle', Utils.boolToStr(form.deliveryOrderShowVehicle));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowVehicle', form.deliveryOrderShowVehicleSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelVehicle', form.deliveryOrderLabelVehicle);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelVehicle', form.deliveryOrderLabelVehicleSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinNumberIN', Utils.boolToStr(form.deliveryOrderShowBinIN));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinNumberIN', form.deliveryOrderShowBinINSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Number_IN', form.deliveryOrderLabelBinIN);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Number_IN', form.deliveryOrderLabelBinINSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinNumberOUT', Utils.boolToStr(form.deliveryOrderShowBinOUT));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinNumberOUT', form.deliveryOrderShowBinOUTSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Number_OUT', form.deliveryOrderLabelBinOUT);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Number_OUT', form.deliveryOrderLabelBinOUTSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSiteContact', Utils.boolToStr(form.deliveryOrderShowSiteContacts));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSiteContact', form.deliveryOrderShowSiteContactsSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelSite_Contact', form.deliveryOrderLabelSiteContacts);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelSite_Contact', form.deliveryOrderLabelSiteContactsSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowPaymentTerms', Utils.boolToStr(form.deliveryOrderShowPaymentTerms));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowPaymentTerms', form.deliveryOrderShowPaymentTermsSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelPayment_Terms', form.deliveryOrderLabelPaymentTerms);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelPayment_Terms', form.deliveryOrderLabelPaymentTermsSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAmountCollected', Utils.boolToStr(form.deliveryOrderShowCollectedCash));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAmountCollected', form.deliveryOrderShowCollectedCashSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCollections', form.deliveryOrderLabelCollectedCash);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCollections', form.deliveryOrderLabelCollectedCashSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesBinType', Utils.boolToStr(form.deliveryOrderShowStandardServicesBinType));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesBinType', form.deliveryOrderShowStandardServicesBinTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesWasteType', Utils.boolToStr(form.deliveryOrderShowStandardServicesWasteType));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesWasteType', form.deliveryOrderShowStandardServicesWasteTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesQty', Utils.boolToStr(form.deliveryOrderShowStandardServicesQty));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesQty', form.deliveryOrderShowStandardServicesQtySortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesRate', Utils.boolToStr(form.deliveryOrderShowStandardServicesRate));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesRate', form.deliveryOrderShowStandardServicesRateSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesPrice', Utils.boolToStr(form.deliveryOrderShowStandardServicesPrice));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowStandardServicesPrice', form.deliveryOrderShowStandardServicesPriceSortOrder, 'variableName', 'sortOrder');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServiceTable', Utils.boolToStr(form.deliveryOrderShowAdditionalServices));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServiceTable', form.deliveryOrderShowAdditionalServicesSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServicesDescription', Utils.boolToStr(form.deliveryOrderShowAdditionalServicesDescription));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServicesDescription', form.deliveryOrderShowAdditionalServicesDescriptionSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServicesQty', Utils.boolToStr(form.deliveryOrderShowAdditionalServicesQty));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServicesQty', form.deliveryOrderShowAdditionalServicesQtySortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServicesRate', Utils.boolToStr(form.deliveryOrderShowAdditionalServicesRate));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServicesRate', form.deliveryOrderShowAdditionalServicesRateSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServicesPrice', Utils.boolToStr(form.deliveryOrderShowAdditionalServicesPrice));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalServicesPrice', form.deliveryOrderShowAdditionalServicesPriceSortOrder, 'variableName', 'sortOrder');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSignature', Utils.boolToStr(form.deliveryOrderShowSignature));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSignature', form.deliveryOrderShowSignatureSortOrder, 'variableName', 'sortOrder');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowProofOfDelivery', Utils.boolToStr(form.deliveryOrderShowProofOfDelivery));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowProofOfDelivery', form.deliveryOrderShowProofOfDeliverySortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelPROOF_OF_DELIVERY', form.deliveryOrderLabelProofOfDelivery);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelPROOF_OF_DELIVERY', form.deliveryOrderLabelProofOfDeliverySortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowMessageToCustomer', Utils.boolToStr(form.deliveryOrderShowMessageToCustomer));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowMessageToCustomer', form.deliveryOrderLabelMessageToCustomerSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringMessageToCustomer', form.deliveryOrderLabelMessageToCustomerHTML);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringMessageToCustomer', form.deliveryOrderLabelMessageToCustomerSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDisclaimer', Utils.boolToStr(form.deliveryOrderShowFooter));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDisclaimer', form.deliveryOrderShowDisclaimerSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringDisclaimerText', form.deliveryOrderLabelFooterHTML);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringDisclaimerText', form.deliveryOrderLabelDisclaimerSortOrder, 'variableName', 'sortOrder');
    

        let data = {
            [this.pageIdField]: this.state.selectedProfileId,
            
            accountDocSettingVariables: accountDocSettingVariables,
            type: AccountDocSettingType.DeliveryOrder,
        };

        return data;
    }
    prepareDataUpdateEmailSettings = (form) => {
        let recipientCc = form.emailSettingsCc && form.emailSettingsCc.length > 0 ? form.emailSettingsCc.join(',') : '';

        let data = {
            [this.pageIdField]: this.state.selectedProfileId,
            
            subject: form.emailSettingsSubject,
            fromName: form.emailSettingsFrom,
            recipientCc: recipientCc,
            message: form.emailSettingsMessageHTML,
            includePdfAttachmentDeliveryOrder: form.emailSettingsDeliveryOrder,
            includePdfAttachmentPhoto: form.emailSettingsIncludePhotos,
            includePdfAttachmentWeightChit: form.emailSettingsWeighChit,
        };

        return data;
    }
    prepareDataUpdatePrintedReceipt = (form) => {
        let accountDocSettingVariables = [];
        let accountDocSettings = (this.state.selectedProfileItem && this.state.selectedProfileItem.accountDocSettings && this.state.selectedProfileItem.accountDocSettings.length > 0) ? this.state.selectedProfileItem.accountDocSettings : [];
        if(accountDocSettings && accountDocSettings.length > 0){
            let printedReceiptItems = accountDocSettings.filter(x => x.type === AccountDocSettingType.JobReceipt);
            if(printedReceiptItems && printedReceiptItems.length > 0){
                accountDocSettingVariables = printedReceiptItems[0].accountDocSettingVariables;
            }
        }

        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerName', Utils.boolToStr(form.printedReceiptShowCustomerName));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerName', form.printedReceiptShowCustomerNameSortOrder, 'variableName', 'sortOrder');
        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelSite_Address', form.printedReceiptLabelCustomerAddress);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelSite_Address', form.printedReceiptLabelCustomerAddressSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDriver', Utils.boolToStr(form.printedReceiptShowDriver));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDriver', form.printedReceiptShowDriverSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDriver', form.printedReceiptLabelDriver);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDriver', form.printedReceiptLabelDriverSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowVehicle', Utils.boolToStr(form.printedReceiptShowVehicle));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowVehicle', form.printedReceiptShowVehicleSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelVehicle', form.printedReceiptLabelVehicle);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelVehicle', form.printedReceiptLabelVehicleSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobType', Utils.boolToStr(form.printedReceiptShowJobType));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobType', form.printedReceiptShowJobTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Type', form.printedReceiptLabelJobType);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Type', form.printedReceiptLabelJobTypeSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowPaymentType', Utils.boolToStr(form.printedReceiptShowPaymentType));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowPaymentType', form.printedReceiptShowPaymentTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelPayment_Type', form.printedReceiptLabelPaymentType);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelPayment_Type', form.printedReceiptLabelPaymentTypeSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAmountCollected', Utils.boolToStr(form.printedReceiptShowCollectedCash));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAmountCollected', form.printedReceiptShowCollectedCashSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCollected_Cash', form.printedReceiptLabelCollectedCash);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCollected_Cash', form.printedReceiptLabelCollectedCashSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinDelivered', Utils.boolToStr(form.printedReceiptShowBinDelivered));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinDelivered', form.printedReceiptShowBinDeliveredSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Delivered', form.printedReceiptLabelBinDelivered);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Delivered', form.printedReceiptLabelBinDeliveredSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinDeliveredType', Utils.boolToStr(form.printedReceiptShowBinType1));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinDeliveredType', form.printedReceiptShowBinType1SortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Type_Delivered', form.printedReceiptLabelBinType1);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Type_Delivered', form.printedReceiptLabelBinType1SortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowPlannedWasteType', Utils.boolToStr(form.printedReceiptShowWasteType1));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowPlannedWasteType', form.printedReceiptShowWasteType1SortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelPlanned_Waste_Type', form.printedReceiptLabelWasteType1);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelPlanned_Waste_Type', form.printedReceiptLabelWasteType1SortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinCollected', Utils.boolToStr(form.printedReceiptShowBinCollected));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinCollected', form.printedReceiptShowBinCollectedSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Collected', form.printedReceiptLabelBinCollected);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Collected', form.printedReceiptLabelBinCollectedSortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinCollectedType', Utils.boolToStr(form.printedReceiptShowBinType2));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinCollectedType', form.printedReceiptShowBinType2SortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Type_Collected', form.printedReceiptLabelBinType2);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_Type_Collected', form.printedReceiptLabelBinType2SortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWasteTypeCollected', Utils.boolToStr(form.printedReceiptShowWasteType2));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWasteTypeCollected', form.printedReceiptShowWasteType2SortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWaste_Type_Collected', form.printedReceiptLabelWasteType2);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWaste_Type_Collected', form.printedReceiptLabelWasteType2SortOrder, 'variableName', 'sortOrder');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalService', Utils.boolToStr(form.printedReceiptShowAdditionalServices));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAdditionalService', form.printedReceiptShowAdditionalServicesSortOrder, 'variableName', 'sortOrder');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSignature', Utils.boolToStr(form.printedReceiptShowSignature));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSignature', form.printedReceiptShowSignatureSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSiteContact', Utils.boolToStr(form.printedReceiptShowSiteContact));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSiteContact', form.printedReceiptShowSiteContactSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSiteTelephone', Utils.boolToStr(form.printedReceiptShowSiteTelephone));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSiteTelephone', form.printedReceiptShowSiteTelephoneSortOrder, 'variableName', 'sortOrder');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringDisclaimerText', form.printedReceiptLabelFooter);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringDisclaimerText', form.printedReceiptLabelFooterSortOrder, 'variableName', 'sortOrder');
    

        let data = {
            [this.pageIdField]: this.state.selectedProfileId,
            
            accountDocSettingVariables: accountDocSettingVariables,
            type: AccountDocSettingType.JobReceipt,
        };

        return data;
    }
    prepareDataUpdateWeightChit = (form) => {
        let accountDocSettingVariables = [];
        let accountDocSettings = (this.state.selectedProfileItem && this.state.selectedProfileItem.accountDocSettings && this.state.selectedProfileItem.accountDocSettings.length > 0) ? this.state.selectedProfileItem.accountDocSettings : [];
        if(accountDocSettings && accountDocSettings.length > 0){
            let weightChitItems = accountDocSettings.filter(x => x.type === AccountDocSettingType.WeightChit);
            if(weightChitItems && weightChitItems.length > 0){
                accountDocSettingVariables = weightChitItems[0].accountDocSettingVariables;
            }
        }
        
        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWEIGH_TICKET', form.weightChitLabelWeightTicket);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWEIGH_TICKET', form.weightChitLabelWeightTicketSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWEIGH_TICKET', form.weightChitLabelWeightTicketGroup, 'variableName', 'group');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerName', Utils.boolToStr(form.weightChitShowCustomerName));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerName', form.weightChitShowCustomerNameSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerName', form.weightChitShowCustomerNameGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Name', form.weightChitLabelCustomerName);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Name', form.weightChitLabelCustomerNameSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Name', form.weightChitLabelCustomerNameGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerAddress', Utils.boolToStr(form.weightChitShowCustomerAddress));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerAddress', form.weightChitShowCustomerAddressSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomerAddress', form.weightChitShowCustomerAddressGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Address', form.weightChitLabelCustomerAddress);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Address', form.weightChitLabelCustomerAddressSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelCustomer_Address', form.weightChitLabelCustomerAddressGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSiteAddress', Utils.boolToStr(form.weightChitShowSiteAddress));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSiteAddress', form.weightChitShowSiteAddressSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowSiteAddress', form.weightChitShowSiteAddressGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelSite_Address', form.weightChitLabelSiteAddress);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelSite_Address', form.weightChitLabelSiteAddressSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelSite_Address', form.weightChitLabelSiteAddressGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDONumber', Utils.boolToStr(form.weightChitShowDONumber));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDONumber', form.weightChitShowDONumberSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDONumber', form.weightChitShowDONumberGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDO_Number', form.weightChitLabelDONumber);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDO_Number', form.weightChitLabelDONumberSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDO_Number', form.weightChitLabelDONumberGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletion', Utils.boolToStr(form.weightChitShowJobCompletion));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletion', form.weightChitShowJobCompletionSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletion', form.weightChitShowJobCompletionGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletionTime', Utils.boolToStr(form.weightChitShowJobTime));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletionTime', form.weightChitShowJobTimeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobCompletionTime', form.weightChitShowJobTimeGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Completion', form.weightChitLabelJobCompletion);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Completion', form.weightChitLabelJobCompletionSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Completion', form.weightChitLabelJobCompletionGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobType', Utils.boolToStr(form.weightChitShowJobType));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobType', form.weightChitShowJobTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowJobType', form.weightChitShowJobTypeGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Type', form.weightChitLabelJobType);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Type', form.weightChitLabelJobTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelJob_Type', form.weightChitLabelJobTypeGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinINType', Utils.boolToStr(form.weightChitShowBinINType));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinINType', form.weightChitShowBinINTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBinINType', form.weightChitShowBinINTypeGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_IN_Type', form.weightChitLabelBinINType);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_IN_Type', form.weightChitLabelBinINTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBin_IN_Type', form.weightChitLabelBinINTypeGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWasteINType', Utils.boolToStr(form.weightChitShowWasteINType));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWasteINType', form.weightChitShowWasteINTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWasteINType', form.weightChitShowWasteINTypeGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWaste_IN_Type', form.weightChitLabelWasteINType);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWaste_IN_Type', form.weightChitLabelWasteINTypeSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWaste_IN_Type', form.weightChitLabelWasteINTypeGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWeighTimestamp', Utils.boolToStr(form.weightChitShowWeighTimestamp));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWeighTimestamp', form.weightChitShowWeighTimestampSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWeighTimestamp', form.weightChitShowWeighTimestampGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWeigh_Timestamp', form.weightChitLabelWeighTimestamp);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWeigh_Timestamp', form.weightChitLabelWeighTimestampSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWeigh_Timestamp', form.weightChitLabelWeighTimestampGroup, 'variableName', 'group');
        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWeighOutTimestamp', Utils.boolToStr(form.weightChitShowWeighOutTimestamp));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWeighOutTimestamp', form.weightChitShowWeighOutTimestampSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWeighOutTimestamp', form.weightChitShowWeighOutTimestampGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWeigh_Out_Timestamp', form.weightChitLabelWeighOutTimestamp);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWeigh_Out_Timestamp', form.weightChitLabelWeighOutTimestampSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWeigh_Out_Timestamp', form.weightChitLabelWeighOutTimestampGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDriver', Utils.boolToStr(form.weightChitShowDriver));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDriver', form.weightChitShowDriverSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDriver', form.weightChitShowDriverGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDriver', form.weightChitLabelDriver);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDriver', form.weightChitLabelDriverSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelDriver', form.weightChitLabelDriverGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowVehicle', Utils.boolToStr(form.weightChitShowVehicle));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowVehicle', form.weightChitShowVehicleSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowVehicle', form.weightChitShowVehicleGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelVehicle', form.weightChitLabelVehicle);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelVehicle', form.weightChitLabelVehicleSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelVehicle', form.weightChitLabelVehicleGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWeighBy', Utils.boolToStr(form.weightChitShowWeighBy));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWeighBy', form.weightChitShowWeighBySortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowWeighBy', form.weightChitShowWeighByGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWeigh_By', form.weightChitLabelWeighBy);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWeigh_By', form.weightChitLabelWeighBySortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelWeigh_By', form.weightChitLabelWeighByGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowRemarks', Utils.boolToStr(form.weightChitShowRemarks));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowRemarks', form.weightChitShowRemarksSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowRemarks', form.weightChitShowRemarksGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelRemarks', form.weightChitLabelRemarks);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelRemarks', form.weightChitLabelRemarksSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelRemarks', form.weightChitLabelRemarksGroup, 'variableName', 'group');
    

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowGrossWeight', Utils.boolToStr(form.weightChitShowGross));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowGrossWeight', form.weightChitShowGrossSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowGrossWeight', form.weightChitShowGrossGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelGross_Weight', form.weightChitLabelGross);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelGross_Weight', form.weightChitLabelGrossSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelGross_Weight', form.weightChitLabelGrossGroup, 'variableName', 'group');
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowTareWeight', Utils.boolToStr(form.weightChitShowTare));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowTareWeight', form.weightChitShowTareSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowTareWeight', form.weightChitShowTareGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelTare_Weight', form.weightChitLabelTare);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelTare_Weight', form.weightChitLabelTareSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelTare_Weight', form.weightChitLabelTareGroup, 'variableName', 'group');
        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowNettWeight', Utils.boolToStr(form.weightChitShowNett));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowNettWeight', form.weightChitShowNettSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowNettWeight', form.weightChitShowNettGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelNett_Weight', form.weightChitLabelNett);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelNett_Weight', form.weightChitLabelNettSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelNett_Weight', form.weightChitLabelNettGroup, 'variableName', 'group');
        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowNonBillableWeight', Utils.boolToStr(form.weightChitShowNonBillable));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowNonBillableWeight', form.weightChitShowNonBillableSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowNonBillableWeight', form.weightChitShowNonBillableGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelNonBillable_Weight', form.weightChitLabelNonBillable);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelNonBillable_Weight', form.weightChitLabelNonBillableSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelNonBillable_Weight', form.weightChitLabelNonBillableGroup, 'variableName', 'group');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBillableWeight', Utils.boolToStr(form.weightChitShowBillable));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBillableWeight', form.weightChitShowBillableSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowBillableWeight', form.weightChitShowBillableGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBillable_Weight', form.weightChitLabelBillable);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBillable_Weight', form.weightChitLabelBillableSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelBillable_Weight', form.weightChitLabelBillableGroup, 'variableName', 'group');
        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowRate', Utils.boolToStr(form.weightChitShowRate));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowRate', form.weightChitShowRateSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowRate', form.weightChitShowRateGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelRate_Weight', form.weightChitLabelRate);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelRate_Weight', form.weightChitLabelRateSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelRate_Weight', form.weightChitLabelRateGroup, 'variableName', 'group');
        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAmount', Utils.boolToStr(form.weightChitShowAmount));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAmount', form.weightChitShowAmountSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowAmount', form.weightChitShowAmountGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelAmount_Weight', form.weightChitLabelAmount);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelAmount_Weight', form.weightChitLabelAmountSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'LabelAmount_Weight', form.weightChitLabelAmountGroup, 'variableName', 'group');


        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowMessageToCustomer', Utils.boolToStr(form.weightChitShowMessageToCustomer));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowMessageToCustomer', form.weightChitShowMessageToCustomerSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowMessageToCustomer', form.weightChitShowMessageToCustomerGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringMessageToCustomer', form.weightChitLabelMessageToCustomerHTML);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringMessageToCustomer', form.weightChitLabelMessageToCustomerSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringMessageToCustomer', form.weightChitLabelMessageToCustomerGroup, 'variableName', 'group');
        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringHeaderText', form.weightChitLabelStringHeaderTextHTML);
    
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDisclaimer', Utils.boolToStr(form.weightChitShowFooter));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDisclaimer', form.weightChitShowFooterSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowDisclaimer', form.weightChitShowFooterGroup, 'variableName', 'group');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringDisclaimerText', form.weightChitLabelFooterHTML);
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringDisclaimerText', form.weightChitLabelFooterSortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'StringDisclaimerText', form.weightChitLabelFooterGroup, 'variableName', 'group');

        
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId1, Utils.boolToStr(form.weightChitCustomField1));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId1, form.weightChitCustomField1SortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId1, form.weightChitCustomField1Group, 'variableName', 'group');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId2, Utils.boolToStr(form.weightChitCustomField2));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId2, form.weightChitCustomField2SortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId2, form.weightChitCustomField2Group, 'variableName', 'group');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId3, Utils.boolToStr(form.weightChitCustomField3));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId3, form.weightChitCustomField3SortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId3, form.weightChitCustomField3Group, 'variableName', 'group');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId4, Utils.boolToStr(form.weightChitCustomField4));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId4, form.weightChitCustomField4SortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId4, form.weightChitCustomField4Group, 'variableName', 'group');

        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId5, Utils.boolToStr(form.weightChitCustomField5));
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId5, form.weightChitCustomField5SortOrder, 'variableName', 'sortOrder');
        accountDocSettingVariables = Utils.setSettingsItem(accountDocSettingVariables, 'ShowCustomField_' + form.weightChitCustomFieldId5, form.weightChitCustomField5Group, 'variableName', 'group');


        let data = {
            [this.pageIdField]: this.state.selectedProfileId,
            
            accountDocSettingVariables: accountDocSettingVariables,
            type: AccountDocSettingType.WeightChit,
            layout: form.weightChitAccountDocSettinglayout,
        };

        return data;
    }
    prepareDataUpdateNotifications = (form) => {
        let data = [];

        let accountMessageTemplates = (form && form.accountMessageTemplates && form.accountMessageTemplates.length > 0) ? form.accountMessageTemplates : [];
        if(accountMessageTemplates && accountMessageTemplates.length > 0){
            data = accountMessageTemplates.map((item, i) => {
                return {
                    accountBillingProfileId: item.accountBillingProfileId,
                    jobStatusId: item.jobStatusId,
                    smsTemplate: item.smsTemplate,
                    whatsappTemplate: item.whatsappTemplate,
                }
            });
        }

        return data;
    }
	/* END FUNCTIONS */

	
	/* API */
    callReadApi = (callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        let data = {
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            searchQuery: this.state.search,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
            isIncludeAll: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
        };
    
        if (this.state.searchTags && this.state.searchTags.length > 0) {
            data["searchTags"] = Utils.joinItems(this.state.searchTags);
        }
        if (this.state.searchOperator && this.state.searchOperator.length > 0) {
            data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
        }
        if (this.state.searchColumns && this.state.searchColumns.length > 0) {
            data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-list', this.pageAPI, null, callback, () => {});
    }

    callDetailsApi = (id = null, callback = null) => {
        if(id){
            this.setState({
                isBlockingFormDrawer: true,
                details: null,
            });
        
            this.props.dispatchApiCallGet(null, this.pageName + '-details', this.pageAPI + '/' + id, null, callback, () => {});
        } else {
            if(callback){
                callback(null)
            }
        }
    }

    callCreateApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        this.props.dispatchApiCallPost(data, this.pageName + '-create', this.pageAPI, data, callback, null);
    }

    callCopyApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        data[this.pageNameField] = data[this.pageNameField] + ' - Copy';
        data['isCopy'] = true;

        delete data[this.pageIdField];

        this.props.dispatchApiCallPost(data, this.pageName + '-copy', this.pageAPI, data, callback, null);
    }

    callUpdateApi = async (form, data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        await this.callQuickbookUpdateCustomerMemoApi(form, () => {
            this.props.dispatchApiCallPut(data, this.pageName + '-update', this.pageAPI, data, callback, null);
        });
    }

    callQuickbookUpdateCustomerMemoApi = async (form, callback = null) => {
        if(form && form.customFormParams && form.customFormParams.isConnectQuickbook){
            let customerMemoLength = (form.customerMemo && form.customerMemo.length > 0) ? form.customerMemo.length : 0;
            if(customerMemoLength <= formFields.customerMemo.maxLength){
                let quickBookAppId = (form.customFormParams.quickBookApp && form.customFormParams.quickBookApp.quickBookAppId && form.customFormParams.quickBookApp.quickBookAppId > 0) ? form.customFormParams.quickBookApp.quickBookAppId : 0;
                let quickBookDefaultTaxCodeId = (form.customFormParams.quickBookApp && form.customFormParams.quickBookApp.quickBookDefaultTaxCodeId && form.customFormParams.quickBookApp.quickBookDefaultTaxCodeId > 0) ? form.customFormParams.quickBookApp.quickBookDefaultTaxCodeId : 0;
                let quickBookDefaultTermId = (form.customFormParams.quickBookApp && form.customFormParams.quickBookApp.quickBookDefaultTermId && form.customFormParams.quickBookApp.quickBookDefaultTermId > 0) ? form.customFormParams.quickBookApp.quickBookDefaultTermId : 0;
                let quickBookDefaultChartOfAccountId = (form && form.quickBookDefaultChartOfAccountId && form.quickBookDefaultChartOfAccountId > 0) ? form.quickBookDefaultChartOfAccountId : null;
                let quickBookDefaultPurchaseChartOfAccountId = (form && form.quickBookDefaultPurchaseChartOfAccountId && form.quickBookDefaultPurchaseChartOfAccountId > 0) ? form.quickBookDefaultPurchaseChartOfAccountId : null;
                
                let data = {
                    quickBookAppId: quickBookAppId,
                    quickBookDefaultTaxCodeId: quickBookDefaultTaxCodeId,
                    quickBookDefaultTermId: quickBookDefaultTermId,
                    customerMemo: form.customerMemo,
                    quickBookDefaultChartOfAccountId: quickBookDefaultChartOfAccountId,
                    quickBookDefaultPurchaseChartOfAccountId: quickBookDefaultPurchaseChartOfAccountId,
                };
                
                await this.props.dispatchApiCallPut(data, this.pageName + '-quickBook_update_customer_memo', 'quickbookapp', null, callback, () => {});
            } else {
                if(callback){
                    callback();
                }
            }

        } if(form && form.customFormParams && form.customFormParams.isConnectXero){
            let xeroAppId = (form.customFormParams.xeroApp && form.customFormParams.xeroApp.xeroAppId && form.customFormParams.xeroApp.xeroAppId != '') ? form.customFormParams.xeroApp.xeroAppId : 0;
            let xeroDefaultChartOfAccountId = (form && form.xeroDefaultChartOfAccountId && form.xeroDefaultChartOfAccountId != '') ? form.xeroDefaultChartOfAccountId : null;
            let xeroDefaultPurchaseChartOfAccountId = (form && form.xeroDefaultPurchaseChartOfAccountId && form.xeroDefaultPurchaseChartOfAccountId != '') ? form.xeroDefaultPurchaseChartOfAccountId : null;
            let xeroDefaultTaxType = (form && form.xeroDefaultTaxType && form.xeroDefaultTaxType != '') ? form.xeroDefaultTaxType : null;
            let xeroDefaultPurchaseTaxType = (form && form.xeroDefaultPurchaseTaxType && form.xeroDefaultPurchaseTaxType != '') ? form.xeroDefaultPurchaseTaxType : null;
            let xeroPaymentTerm = (form && form.xeroPaymentTerm && form.xeroPaymentTerm > 0) ? form.xeroPaymentTerm : null;
            let xeroPaymentTermType = (form && form.xeroPaymentTermType && form.xeroPaymentTermType > 0) ? form.xeroPaymentTermType : 0;
            
            let data = {
                xeroAppId: xeroAppId,
                xeroDefaultChartOfAccountId: xeroDefaultChartOfAccountId,
                xeroDefaultPurchaseChartOfAccountId: xeroDefaultPurchaseChartOfAccountId,
                xeroDefaultTaxType: xeroDefaultTaxType,
                xeroDefaultPurchaseTaxType: xeroDefaultPurchaseTaxType,
                xeroPaymentTerm: xeroPaymentTerm,
                xeroPaymentTermType: xeroPaymentTermType,
            };
            
            await this.props.dispatchApiCallPut(data, this.pageName + '-quickBook_update_customer_memo', 'xeroapp', null, callback, () => {});

        } else {
            if(callback){
                callback();
            }
        }
    }

    callUpdateEmailSettingsApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        this.props.dispatchApiCallPut(data, this.pageName + '-update_email_settings', 'AccountEmailTemplate', data, callback, null);
    }

    callUpdateAccountDocSettingApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        this.props.dispatchApiCallPut(data, this.pageName + '-update_account_doc_setting', 'accountdocsetting', data, callback, null);
    }

    callUpdateNotificationsApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        this.props.dispatchApiCallPut(data, this.pageName + '-update_notifications', 'message-template/batch-update', data, callback, null);
    }

    callDeleteApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', this.pageAPI, data, callback, null);
    }
	/* END API */
	
	
	/* SECTIONS */
	actionSection = () => {
		return <Row className={'align-items-center'}>
            
            {this.state.rowsInline.map((item, i) => {
                let isSelected = this.isSelectedProfile(item[this.pageIdField]);

                return <Col key={item[this.pageIdField]} xs={'auto'} className={'mb-2 mb-sm-0'}>
                    <Button
                        variant={isSelected ? 'success' : 'light'}
                        className={isSelected ? 'pt-1 pb-1 rounded-1' : 'pt-1 pb-1 rounded-1 bg-white'}
                        onClick={(e) => {
                            this.details(item[this.pageIdField], item);
                        }}
                    >{item[this.pageNameField]}</Button>
                </Col>
            })}
            
            {(this.state.rowsDropdown && this.state.rowsDropdown.length > 0) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
                <Dropdown
                    onSelect={(eventKey, e) => {
                        let item = this.getProfileById(this.state.rowsDropdown, this.pageIdField, eventKey);
                        this.details(eventKey, item);
                    }}
                >
                    <Dropdown.Toggle
                        variant={this.isSelectedAnyProfile() ? 'success' : 'light'}
                        className={'ps-3 pe-3 ' + (this.isSelectedAnyProfile() ? 'pt-1 pb-1 rounded-1' : 'pt-1 pb-1 rounded-1 bg-white')}
                    />
                    <Dropdown.Menu
                        variant={'success'}
                    >

                        {this.state.rowsDropdown.map((item, i) => {
                            let isSelected = this.isSelectedProfile(item[this.pageIdField]);

                            return <Dropdown.Item key={i} eventKey={item[this.pageIdField]} active={isSelected}>{item[this.pageNameField]}</Dropdown.Item>
                        })}

                    </Dropdown.Menu>
                </Dropdown>
            </Col>}

            <Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
                <CurrentDateTime />
            </Col>
        </Row>
	}

	toolbarSection = (formOptions) => {
        let {
            values,
        } = formOptions;
        
		return <Row className={'align-items-center'}>
            <Col xs={12} className={'ps-10 pe-10'}>
                <Row className={'align-items-center'}>
                    <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
                        <Button
                            variant={(this.state.selectedTab === 1) ? 'success' : 'light'}
                            className={(this.state.selectedTab === 1) ? 'pt-1 pb-1 rounded-1' : 'pt-1 pb-1 rounded-1 border bg-white'}
                            onClick={(e) => {
                                if(this.state.selectedTab !== 1){
                                    this.savePrevTab(values, 1, formOptions);
                                }
                            }}
                        >General</Button>
                    </Col>

                    {values.isEdit && <>
                        <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
                            <Button
                                variant={(this.state.selectedTab === 2) ? 'success' : 'light'}
                                className={(this.state.selectedTab === 2) ? 'pt-1 pb-1 rounded-1' : 'pt-1 pb-1 rounded-1 border bg-white'}
                                onClick={(e) => {
                                    if(this.state.selectedTab !== 2){
                                        this.savePrevTab(values, 2, formOptions);
                                    }
                                }}
                            >Delivery Order</Button>
                        </Col>
                        <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
                            <Button
                                variant={(this.state.selectedTab === 3) ? 'success' : 'light'}
                                className={(this.state.selectedTab === 3) ? 'pt-1 pb-1 rounded-1' : 'pt-1 pb-1 rounded-1 border bg-white'}
                                onClick={(e) => {
                                    if(this.state.selectedTab !== 3){
                                        this.savePrevTab(values, 3, formOptions);
                                    }
                                }}
                            >Email settings</Button>
                        </Col>
                        <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
                            <Button
                                variant={(this.state.selectedTab === 4) ? 'success' : 'light'}
                                className={(this.state.selectedTab === 4) ? 'pt-1 pb-1 rounded-1' : 'pt-1 pb-1 rounded-1 border bg-white'}
                                onClick={(e) => {
                                    if(this.state.selectedTab !== 4){
                                        this.savePrevTab(values, 4, formOptions);
                                    }
                                }}
                            >Printed receipt</Button>
                        </Col>
                        <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
                            <Button
                                variant={(this.state.selectedTab === 5) ? 'success' : 'light'}
                                className={(this.state.selectedTab === 5) ? 'pt-1 pb-1 rounded-1' : 'pt-1 pb-1 rounded-1 border bg-white'}
                                onClick={(e) => {
                                    if(this.state.selectedTab !== 5){
                                        this.savePrevTab(values, 5, formOptions);
                                    }
                                }}
                            >Weight chit</Button>
                        </Col>
                        {/* <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
                            <Button
                                variant={(this.state.selectedTab === 6) ? 'success' : 'light'}
                                className={(this.state.selectedTab === 6) ? 'pt-1 pb-1 rounded-1' : 'pt-1 pb-1 rounded-1 border bg-white'}
                                onClick={(e) => {
                                    if(this.state.selectedTab !== 6){
                                        this.savePrevTab(values, 6, formOptions);
                                    }
                                }}
                            >Notification</Button>
                        </Col> */}
                    </>}

                </Row>
            </Col>
            <Col xs={12}>
                <hr />
            </Col>
        </Row>
	}

	pageSection = (formOptions) => {
        if(this.state.selectedTab === 1){
            return <Row className={'ps-10 pe-10'}>
                <Col xs={12}>
                    <GeneralForm
                        auth={this.props.auth}
                        settingsType={this.props.settingsType}
                        fields={formFields}
                        initialValues={this.state.rowInitialValues}
                        formOptions={formOptions}
                        accountBillingProfileItem={this.state.selectedProfileItem}
                        onStartDisconnecting={() => {
                            this.setState({
                                isBlockingFormDrawer: true,
                            });
                        }}
                        onDisconnect={() => {
                            this.setState({
                                isBlockingFormDrawer: false,
                            }, () => {
                                this.details(this.state.selectedProfileItem[this.pageIdField], this.state.selectedProfileItem);
                            });
                        }}
                        onClickAutidTrail={() => {
                            this.setState({
                                isOpenAuditTrail: true,
                                objectId: this.id,
                            });
                        }}
                    />
                </Col>
            </Row>

        } else if(this.state.selectedTab === 2){
            return <Row className={'ps-10 pe-10'}>
                <Col xs={12}>
                    <DeliveryOrderForm
                        auth={this.props.auth}
                        fields={formFields}
                        initialValues={this.state.rowInitialValues}
                        formOptions={formOptions}
                        accountBillingProfileItem={this.state.selectedProfileItem}
                    />
                </Col>
            </Row>

        } else if(this.state.selectedTab === 3){
            return <Row className={'ps-10 pe-10'}>
                <Col xs={12}>
                    <EmailSettingsForm
                        auth={this.props.auth}
                        fields={formFields}
                        initialValues={this.state.rowInitialValues}
                        formOptions={formOptions}
                        accountBillingProfileItem={this.state.selectedProfileItem}
                    />
                </Col>
            </Row>

        } else if(this.state.selectedTab === 4){
            return <Row className={'ps-10 pe-10'}>
                <Col xs={12}>
                    <PrintedReceiptForm
                        auth={this.props.auth}
                        fields={formFields}
                        initialValues={this.state.rowInitialValues}
                        formOptions={formOptions}
                        accountBillingProfileItem={this.state.selectedProfileItem}
                    />
                </Col>
            </Row>
        } else if(this.state.selectedTab === 5){
            return <Row className={'ps-10 pe-10'}>
                <Col xs={12}>
                    <WeightChitForm
                        auth={this.props.auth}
                        fields={formFields}
                        initialValues={this.state.rowInitialValues}
                        formOptions={formOptions}
                        accountBillingProfileItem={this.state.selectedProfileItem}
                    />
                </Col>
            </Row>
        } else if(this.state.selectedTab === 6){
            return <Row className={'ps-10 pe-10'}>
                <Col xs={12}>
                    <NotificationForm
                        auth={this.props.auth}
                        fields={formFields}
                        initialValues={this.state.rowInitialValues}
                        formOptions={formOptions}
                        accountBillingProfileItem={this.state.selectedProfileItem}
                    />
                </Col>
            </Row>
        }
	}

	footerSection = () => {
		return <Row className={'align-items-center'}>
            <Col xs={'auto d-none d-md-flex'} md={true}></Col>
            <Col xs={'auto'} className={'text-end mb-2 mb-md-0'}>
                <Button
                    type={'submit'}
                    variant="primary"
                    disabled={this.props.isBlockingFormDrawer}
                >
                    SAVE
                    {this.state.isBlockingFormDrawer ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>

            {/* {this.state.selectedProfileId && <Col xs={'auto'} className={'text-end mb-2 mb-md-0'}>
                <Button 
                    type={'button'}
                    variant={'danger'}
                    disabled={this.props.isBlockingFormDrawer}
                    onClick={() => {
                        this.setState({
                            isRemoveDialog: true,
                            removeRow: this.state.selectedProfileId,
                        });
                    }}
                >
                    REMOVE
                    {this.state.isBlockingFormDrawer ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>} */}

            {this.state.selectedProfileId && <Col xs={'auto'}className={'text-end mb-2 mb-md-0'}>
                <Button
                    type={'button'}
                    variant="secondary"
                    disabled={this.props.isBlockingFormDrawer}
                    onClick={() => {
                        this.details();
                    }}
                >
                    CANCEL
                    {this.state.isBlockingFormDrawer ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>}

        </Row>
	}
	/* END SECTIONS */

  
    /* DIALOGS */
    setRemoveDialog = () => {
        return <RemoveDialog 
            show={this.state.isRemoveDialog}
            isLoading={this.state.isBlockingFormDrawer}
            onCancel={() => {
                this.setState({
                    isRemoveDialog: false,
                    removeRow: null,
                });
            }}
            onRemove={() => {
                let rowId = this.state.removeRow;

                let data = {
                    [this.pageIdField]: rowId,
                };

                this.callDeleteApi(data, (res) => {
                    this.callReadApi();
                    this.details();
                });
            }}
        />
    }
    /* END DIALOGS */


    /* AUDIT TRAIL */
    setAuditTrailDialog = () => {
        return (
            <AuditTrailDialog
                isOpen={this.state.isOpenAuditTrail}
                objectId={this.state.objectId}
                auditTrailObjectType={AuditTrailObjectType.AccountBillingProfile}
                title={"Company Audit Trail"}
                onClose={() => {
                    this.setState({
                        isOpenAuditTrail: false,
                        objectId: null,
                    });
                }}
                details={(obj, index, currentVersion, previousVersion) => {
                    return AuditTrailFunctions.getDetails(AuditTrailObjectType.AccountBillingProfile, obj, index, currentVersion, previousVersion);
                }}
                manageData={(currentVersion, previousVersion, callback) => {
                    return AuditTrailFunctions.getManageData(AuditTrailObjectType.AccountBillingProfile, currentVersion, previousVersion, callback);
                }}
            />
        );
    };
    /* END AUDIT TRAIL */


    render() {
        return (
            <>
                <Formik
                    innerRef={this.refFormik}
                    validationSchema={formSchema}
                    initialValues={this.state.rowValues}
                    validateOnMount={true}
                    enableReinitialize={true}
                    onSubmit={(form, e) => {
                        if(form.isEdit){
                            if(this.state.selectedTab === 6){
                                let data = this.prepareDataUpdateNotifications(form);
                                this.callUpdateNotificationsApi(data, (res) => {
                                    this.details(this.state.selectedProfileId, res, 6);
                                });

                            } else if(this.state.selectedTab === 5){
                                let data = this.prepareDataUpdateWeightChit(form);
                                this.callUpdateAccountDocSettingApi(data, (res) => {
                                    this.details(res[this.pageIdField], res, 5);
                                });

                            } else if(this.state.selectedTab === 4){
                                let data = this.prepareDataUpdatePrintedReceipt(form);
                                this.callUpdateAccountDocSettingApi(data, (res) => {
                                    this.details(res[this.pageIdField], res, 4);
                                });

                            } else if(this.state.selectedTab === 3){
                                let data = this.prepareDataUpdateEmailSettings(form);
                                this.callUpdateEmailSettingsApi(data, (res) => {
                                    this.details(res[this.pageIdField], res, 3);
                                });

                            } else if(this.state.selectedTab === 2){
                                let data = this.prepareDataUpdateDeliveryOrder(form);
                                this.callUpdateAccountDocSettingApi(data, (res) => {
                                    this.details(res[this.pageIdField], res, 2);
                                });

                            } else {
                                let data = this.prepareDataUpdate(form);
                                this.callUpdateApi(form, data, (res) => {
                                    this.callReadApi();
                                    this.details(res[this.pageIdField], res);
                                });
                            }
                        } else {
                            let data = this.prepareDataCreate(form);
                            this.callCreateApi(data, (res) => {
                                this.callReadApi();
                                this.details(res[this.pageIdField], res);
                            });
                        }
                    }}
                >
                    {(formOptions) => {
                        return <Form className='w-100' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                            // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            //     keyEvent.preventDefault();
                            // }
                        }}>
                            <ViewLayout
                                isBlocking={this.state.isBlockingFormDrawer}
                                title={'COMPANY'}
                                col={<Col xs={true} className={'text-end mb-md-0'}>
                                    {(Utils.hasPermission(this.permissions, PagesPermissions.MultipleBillingProfile.read)) && <Button
                                        variant="primary"
                                        disabled={!(this.state.selectedProfileId)}
                                        onClick={() => {
                                            this.details();
                                        }}
                                    >NEW</Button>}
                                </Col>}
                                cardClassName={'ps-0 pe-0'}
                                renderChildren={false}
                                actionSection={this.actionSection()}
                                toolbarSection={this.toolbarSection(formOptions)}
                                pageSection={this.pageSection(formOptions)}
                                footerSection={this.footerSection()}
                            />
                        </Form>
                    }}
                </Formik>

                {this.setRemoveDialog()}
                {this.setAuditTrailDialog()}
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallPost: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6, p7)),
    dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallDelete: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallDelete(p1, p2, p3, p4, p5, p6)),
    action: {
      notifications: {
        setSelectedMenu: (payload) => dispatch(notifications.setSelectedMenu(payload)),
        setSenderPhoneNumberValue: (payload) => dispatch(notifications.setSenderPhoneNumberValue(payload)),
      },
    }
  });
  
  
  export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CompanyView);